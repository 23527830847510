import React, { ReactElement, useEffect, useRef, useState } from 'react'

import {
  CloseOutlined,
  LeftCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Button, Col, Modal, Row, Select, Space, Table, TablePaginationConfig, Tag } from 'antd'
import { closeSVG } from 'assets/svg/close'
import { DropdownWrapper } from 'atom/user-edit'
import CoreBadge from 'components/badge/Badge'
import { AlertError } from 'components/error'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { hexToRgba } from 'helpers/color'
import { renderFormatMessage } from 'helpers/intl'
import { useDiscountSchemaTranslation } from 'hooks/translation/useDiscountSchemaTranslation'
import { useNotifications } from 'hooks/useNotification'
import { useSelector } from 'hooks/useSelector'
import { SwtchApiError } from 'models/error'
import { PricingSchema } from 'models/price'
import { UserWallet } from 'models/user'
import moment from 'moment'
import { AddUsersToDiscounts, RemoveUSersFromDiscounts } from 'services/data-provider/discount'
import styled from 'styled-components'
import { theme } from 'theme'
import { RemoveUserDiscountSchemaModal } from '../remove-user-discount-schema-modal'
import { DiscountPlanUser, DiscountSchema } from 'models/discount'
import { SelectionInfoContainer } from 'atom/box'
import { useAppState } from 'state'

interface props {
  visible: boolean
  onCancel: () => void
  loadDiscounts: () => void
  discountPlan: DiscountSchema
  pricingSchema: PricingSchema
}

const InfoContainer = styled(Col)`
  background-color: ${theme.colors.grayBackground};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 32px;
`

const TableInfoContainer = styled(Row)`
  margin-bottom: 16px;
  a > span {
    margin-right: 5px;
  }
`

const ConfirmButton = styled(Button)`
  margin-left: 6px;
  position: relative;
`

export const ManageUsersModal: React.FC<props> = ({
  visible,
  onCancel,
  loadDiscounts,
  discountPlan,
  pricingSchema,
}) => {
  const { isMockUpEnabled } = useAppState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchApiError>()

  const [showHistory, setShowHistory] = useState(false)

  const [emailsSelected, setEmailsSelected] = useState<string[]>([])

  const [usersToRemove, setUsersToRemove] = useState<string[]>([])

  const [currentPage, setCurrentPage] = useState(1)

  const [showRemoveModal, setShowRemoveModal] = useState(false)

  const [expanded, setExpanded] = useState<{ [dataKey: number]: boolean }>({})
  const [expandableRowKeys, setExpandableRowKeys] = useState<{ [dataKey: number]: boolean }>({})

  const colRefs = useRef<(HTMLDivElement | null)[]>([])

  const rowHeight = 20

  const { multiUsersDebounceFetcher } = useSelector()
  const { openSuccessNotification } = useNotifications()

  const {
    pricingPlanText,
    discountPlanText,
    listingText,
    emailTxt,
    nameTxt,
    timeAddedText,
    actionText,
    updatedByText,
    manageUsersText,
    allUsersSelectedText,
    usersSelectedText,
    selectAllText,
    removeText,
    cancel,
    currentUsersText,
    userAuthorizationHistoryText,
    //TODO: api for this not ready yet viewHistoryText,
    usersAddededText,
    usersRemovedText,
  } = useDiscountSchemaTranslation()
  const pageSize = 7
  const infoData = [
    {
      title: pricingPlanText,
      info: [pricingSchema.name],
    },
    {
      title: discountPlanText,
      info: [discountPlan.name],
    },
    {
      title: listingText,
      info: discountPlan.appliedListings?.map((listing) => listing.listingTitle),
    },
  ]

  const UserTableColumns = [
    {
      title: emailTxt,
      dataIndex: 'userEmail',
      sorter: (a: DiscountPlanUser, b: DiscountPlanUser) => {
        if (a.userEmail && b.userEmail) {
          return a.userEmail.localeCompare(b.userEmail)
        }
        return 0
      },
      width: '33%',
    },
    {
      title: nameTxt,
      dataIndex: 'userName',
      sorter: (a: DiscountPlanUser, b: DiscountPlanUser) => a.userName.localeCompare(b.userName),
      width: '33%',
    },
    {
      title: timeAddedText,
      dataIndex: 'timeAdded',
      render: (time: string) => moment(time).format('YYYY/MM/DD HH:mm'),
    },
  ]

  const HistoryTableColumns = [
    {
      title: emailTxt,
      dataIndex: 'userEmail',
      width: '33%',
    },
    {
      title: nameTxt,
      dataIndex: 'userName',
      width: '33%',
    },
    {
      title: actionText,
      render: () => 'Added',
    },
    {
      title: timeAddedText,
      render: () => moment().format('YYYY/MM/DD HH:mm'),
    },
    {
      title: updatedByText,
      render: () => 'Staff Demo',
    },
  ]

  const renderUserOption = ({ id, email }: UserWallet, selectedValue: any): ReactElement => {
    return (
      <Select.Option key={id} value={email} label={email}>
        <UserOutlined style={{ marginRight: 5 }} />
        <DropdownWrapper selected={email === selectedValue}>{email}</DropdownWrapper>
      </Select.Option>
    )
  }

  const handleRemoveUsers = ({ users }: DiscountSchema) => {
    const discountPlanUsersThatAreNotNull = users
      .filter((discountUser) => discountUser.userEmail !== null)
      .map((user) => user.userEmail)
    return discountPlanUsersThatAreNotNull
  }

  const handleHistory = () => setShowHistory(!showHistory)

  const clearSelection = () => setUsersToRemove([])
  const selectAllUsers = () => setUsersToRemove(handleRemoveUsers(discountPlan))

  const trackCurrentPage = (pagination: TablePaginationConfig) => setCurrentPage(pagination.current || 1)

  const everyUserSelectedInPage =
    usersToRemove.length === pageSize &&
    usersToRemove.every((selectedUser) =>
      discountPlan.users
        .slice(0 + pageSize * (currentPage - 1), pageSize * currentPage)
        .map((val) => val.userEmail)
        .includes(selectedUser),
    )

  const handleRemoveModal = () => setShowRemoveModal(!showRemoveModal)
  const removeUsers = () => {
    const localDiscounts = [{ id: discountPlan.id, name: discountPlan.name }]
    const users = usersToRemove.map((email) => ({
      email,
    }))
    setLoading(true)
    RemoveUSersFromDiscounts(localDiscounts, users)
      .then((res) => {
        loadDiscounts()
        openSuccessNotification(usersRemovedText)
        handleRemoveModal()
        setUsersToRemove([])
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => setLoading(false))
  }

  const addUsers = async () => {
    const localDiscounts = [{ id: discountPlan.id, name: discountPlan.name }]
    const users = emailsSelected.map((email) => ({
      email,
    }))
    setLoading(true)
    AddUsersToDiscounts(localDiscounts, users)
      .then((res) => {
        loadDiscounts()
        openSuccessNotification(usersAddededText)
        setEmailsSelected([])
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    let updatedExpandableRowKeys: { [dataKey: number]: boolean } = {}
    colRefs.current.forEach((colRef, index) => {
      if (colRef) {
        updatedExpandableRowKeys[index] = colRef.clientHeight > rowHeight
      }
    })
    setExpandableRowKeys(updatedExpandableRowKeys)
  }, [])

  const handleRowExpand = (currentIndex: number) => () => {
    setExpanded({ ...expanded, [currentIndex]: expanded ? !expanded[currentIndex] : true })
  }

  return (
    <Modal
      title={<h5 className="heading-05-regular">{manageUsersText}</h5>}
      visible={visible}
      confirmLoading={loading}
      onCancel={onCancel}
      width={'80%'}
      footer={null}
      className={`${error ? 'ant-modal-error' : ''}`}
      closeIcon={closeSVG}
    >
      <RemoveUserDiscountSchemaModal visible={showRemoveModal} onOk={removeUsers} onCancel={handleRemoveModal} />
      <AlertError error={error} />
      <InfoContainer dir="col">
        {infoData.map((data, index) => (
          <Row
            style={{
              marginTop: index > 0 ? '15px' : 0,
              height: expanded[index] ? 'unset' : rowHeight,
              overflow: 'hidden',
            }}
          >
            <Col span={3} className="paragraph-01-regular">
              {data.title}
            </Col>
            <Col flex="1" span={21}>
              <Row ref={(el) => (colRefs.current[index] = el)}>
                <Space style={{ flexWrap: 'wrap' }}>
                  {data.info?.map((info) => (
                    <CoreBadge title={info} />
                  ))}
                </Space>
              </Row>
            </Col>
            {expandableRowKeys[index] && (
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: expanded[index] ? 'unset' : rowHeight,
                }}
              >
                {expanded[index] ? (
                  <MinusCircleOutlined style={{ fontSize: '16px' }} onClick={handleRowExpand(index)} />
                ) : (
                  <PlusCircleOutlined style={{ fontSize: '16px' }} onClick={handleRowExpand(index)} />
                )}
              </Col>
            )}
          </Row>
        ))}
      </InfoContainer>
      <Row>
        <Col flex="1">
          <ReusableSelector
            showSearch
            isSingle
            showArrow={false}
            clearSearchAfterSelect
            maxTagCount={0}
            showMagnifySVG={false}
            isDebounceFetcher
            placeholder={'Email'}
            handleOptionChange={(value) => {
              if (value) {
                setEmailsSelected([...emailsSelected.filter((email) => email !== value), value])
              }
            }}
            debounceFetcher={multiUsersDebounceFetcher}
            renderOption={renderUserOption}
            isMockUpEnabled={isMockUpEnabled}
          />
        </Col>
        <ConfirmButton type="primary" disabled={emailsSelected.length === 0} onClick={addUsers}>
          {renderFormatMessage('dashboard.text.add', 'Add')}
        </ConfirmButton>
      </Row>
      <Row wrap gutter={8} style={{ marginBottom: 32 }}>
        {emailsSelected.map((email) => (
          <Tag
            style={{ marginTop: 16 }}
            color={hexToRgba(theme.colors.primary, 0.1)}
            closable
            closeIcon={<CloseOutlined style={{ color: theme.colors.softBlack }} />}
            onClose={() => {
              setEmailsSelected(emailsSelected.filter((val) => email !== val))
            }}
          >
            {email}
          </Tag>
        ))}
      </Row>
      {!!usersToRemove.length && (
        <SelectionInfoContainer align="middle" justify="space-between">
          <Space>
            <span className="paragraph-01-regular">
              {everyUserSelectedInPage
                ? allUsersSelectedText.replace('-1', `${usersToRemove.length}`)
                : `${usersToRemove.length} ${usersSelectedText}`}
            </span>
            {everyUserSelectedInPage && usersToRemove.length !== discountPlan.users.length && (
              <button className="paragraph-01-regular link" onClick={selectAllUsers}>
                {selectAllText.replace('-1', `${discountPlan.users.length}`)}
              </button>
            )}
          </Space>
          <Space>
            <Button type="primary" onClick={handleRemoveModal}>
              {removeText}
            </Button>
            <Button onClick={clearSelection} type="ghost">
              {cancel}
            </Button>
          </Space>
        </SelectionInfoContainer>
      )}
      <TableInfoContainer justify="space-between">
        {showHistory ? (
          <Row className="pointer" onClick={handleHistory} align="middle">
            <LeftCircleOutlined style={{ fontSize: 20, marginRight: 5 }} />
            <span className="heading-06-regular">{userAuthorizationHistoryText}</span>
          </Row>
        ) : (
          <>
            <span className="heading-06-regular">{currentUsersText.replace('-1', `${discountPlan.users.length}`)}</span>
            {/*//TODO: backend not ready yet
             <a onClick={handleHistory} className="regular-cap-2 pointer">
              <HistoryOutlined />
              {viewHistoryText}
            </a> */}
          </>
        )}
      </TableInfoContainer>
      <Table
        loading={loading}
        columns={showHistory ? HistoryTableColumns : UserTableColumns}
        dataSource={discountPlan.users}
        pagination={{
          hideOnSinglePage: true,
          position: ['bottomCenter'],
          pageSize: pageSize,
        }}
        className="shadow-table"
        rowKey="userEmail"
        onChange={trackCurrentPage}
        rowSelection={
          showHistory
            ? undefined
            : {
                selectedRowKeys: usersToRemove,
                onChange: (selectedRowKeys) => {
                  setUsersToRemove(selectedRowKeys as string[])
                },
              }
        }
      />
    </Modal>
  )
}
