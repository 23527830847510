import { mockTenantsRef } from 'services/data-provider/mock'
import { ChargingType, ServiceStatus } from '../models/charger'
import { connectorServiceStatus, connectorStatus } from '../models/connector'
import { PeakShavingProgramsResponse } from '../models/http'
import {
  Participants,
  PeakShavingDailyEventDayType,
  PeakShavingEventScheduleZone,
  PeakShavingEventState,
  PeakShavingProgram,
  PeakShavingProgramCategoryType,
  PeakShavingProgramState,
  PeakShavingScheduleFrequency,
  PeakShavingSourceType,
} from '../models/peak-shaving'
import { mockUserRef } from './user-mock'
import { PeakShavingProgramFilter } from 'models/filter'

export const mockPeakShavingPrograms = [
  {
    id: 1,
    name: 'Ontario’s Global Adjustment Program',
    aggregatorName: 'Leap',
    startDate: '2024-05-08T00:00:00.000Z',
    endDate: '2024-05-08T00:00:00.000Z',
    participationWindow: '4-hour window',
    eventNotification: 'Day Ahead',
    voluntary: false,
    category: 'peak shaving' as PeakShavingProgramCategoryType,
    initiative: 'Officia commodi.',
    state: 'published' as PeakShavingProgramState,
    hasShavingEvents: true,
    timezone: 'America/Toronto',
  },
  {
    id: 2,
    name: 'Test 2',
    aggregatorName: null,
    startDate: '2024-05-08T00:00:00.000Z',
    endDate: '2024-05-08T00:00:00.000Z',
    participationWindow: '4-hour window',
    eventNotification: 'Day Ahead',
    voluntary: false,
    category: 'demand response' as PeakShavingProgramCategoryType,
    initiative: 'Tenetur quo.',
    state: 'draft' as PeakShavingProgramState,
    hasShavingEvents: true,
    timezone: null,
  },
  {
    id: 3,
    name: 'Test 3',
    aggregatorName: 'a3',
    startDate: '2024-05-08T00:00:00.000Z',
    endDate: '2024-05-08T00:00:00.000Z',
    participationWindow: '4-hour window',
    eventNotification: 'Day Ahead',
    voluntary: false,
    category: 'peak shaving' as PeakShavingProgramCategoryType,
    initiative: '$17.50 / Month',
    state: 'published' as PeakShavingProgramState,
    hasShavingEvents: true,
    timezone: null,
  },
  {
    id: 4,
    name: 'Test 8',
    aggregatorName: 'a4',
    startDate: '2024-05-08T00:00:00.000Z',
    endDate: '2024-05-08T00:00:00.000Z',
    participationWindow: '4-hour window',
    eventNotification: 'Day Ahead',
    voluntary: false,
    category: 'demand response' as PeakShavingProgramCategoryType,
    initiative: '$17.50 / Month',
    state: 'published' as PeakShavingProgramState,
    hasShavingEvents: true,
    timezone: null,
  },
] as PeakShavingProgram[]

export const mockPeakShavingAllProgramsResp = (filter?: PeakShavingProgramFilter) => {
  let filterPeakShavingPrograms = [...mockPeakShavingPrograms]

  if (filter?.category) {
    filterPeakShavingPrograms = filterPeakShavingPrograms.filter((program) => program.category === filter.category)
  }
  if (filter?.state) {
    filterPeakShavingPrograms = filterPeakShavingPrograms.filter((program) => program.state === filter.state)
  }

  if (filter?.aggregatorName) {
    filterPeakShavingPrograms = filterPeakShavingPrograms.filter(
      (program) => program.aggregatorName === filter.aggregatorName,
    )
  }

  if (filter && filter.programs && filter.programs.length > 0) {
    filterPeakShavingPrograms = filterPeakShavingPrograms.filter((program) => {
      if (filter.programs) {
        return filter.programs.includes(program.id)
      }
      return true
    })
  }

  return {
    data: filterPeakShavingPrograms,
    pagination: { currentPage: filter?.page, totalEntries: 10, perPage: 10 },
  } as PeakShavingProgramsResponse
}

export const mockPeakShavingProgramByProgramId: { [programId: string]: PeakShavingProgram } = {
  '1': {
    id: 1,
    name: 'Ontario’s Global Adjustment Program',
    aggregatorName: 'Leap',
    startDate: '2024-05-08T00:00:00.000Z',
    endDate: '2024-05-08T00:00:00.000Z',
    participationWindow: '4-hour window',
    eventNotification: 'Day Ahead',
    voluntary: false,
    category: 'peak shaving' as PeakShavingProgramCategoryType,
    initiative: 'Officia commodi.',
    state: 'published' as PeakShavingProgramState,
    hasShavingEvents: true,
    timezone: 'America/Toronto',
  },
}

export const mockPeakShavingAggregator = (aggregatorName?: string) => {
  const mockPeakShavingAggregator = ['Leap', 'a2', 'a3', 'a4']

  let filterPeakShavingAggregator = [...mockPeakShavingAggregator]

  if (aggregatorName) {
    return filterPeakShavingAggregator
      .filter((aggregator) => aggregator === aggregatorName)
      .map((aggregator, index) => ({
        id: index,
        value: aggregator,
        label: aggregator,
      }))
  }

  return filterPeakShavingAggregator.map((aggregator, index) => ({
    id: index,
    value: aggregator,
    label: aggregator,
  }))
}

export const mockPeakShavingParticipants = {
  data: [
    {
      id: 1,
      tenant: {
        id: '1',
        name: '380-Waterloo-Ave-Guelph-ON-N1H7H8',
        displayName: '380-Waterloo-Ave-Guelph-ON-N1H7H8',
        country: 'CA',
        combineName: '',
      },
      allListings: true,
      listings: [
        {
          id: 1,
          title: 'L7SME',
          participantId: '123123',
          charger: {
            id: 3,
            chargePointSerialNumber: '1EC1-1-1834-00185',
            chargePointModel: 'foo',
            chargePointVendor: '',
            online: true,
            chargingType: 'Level 2' as ChargingType,
            maxWatt: 6600,
            serviceStatus: 'under_repair' as ServiceStatus,
          },
          connector: { id: 97, connectorId: 1, serviceStatus: connectorServiceStatus[5] },
        },
        {
          id: 2,
          title: 'L7SME2',
          participantId: '123123',
          charger: {
            id: 3,
            chargePointSerialNumber: '1EC1-1-1834-00185',
            chargePointModel: 'foo',
            chargePointVendor: 'LITE-ON',
            online: true,
            chargingType: 'Level 2' as ChargingType,
            maxWatt: 6600,
            serviceStatus: 'under_repair' as ServiceStatus,
          },
          connector: { id: 97, connectorId: 1, serviceStatus: connectorServiceStatus[5] },
        },
      ],
      participantRef: '123',
    },
    {
      id: 2,
      tenant: {
        id: '2',
        name: '380-Waterloo-Ave-Guelph-ON-N1H7H8',
        displayName: '380-Waterloo-Ave-Guelph-ON-N1H7H8',
        country: 'CA',
        combineName: '',
      },
      allListings: false,
      listings: [
        {
          id: 1,
          title: 'L7SME',
          participantId: '234234',
          charger: {
            id: 3,
            chargePointSerialNumber: '1EC1-1-1834-00185',
            chargePointModel: 'foo',
            chargePointVendor: 'LITE-ON',
            online: true,
            chargingType: 'Level 2' as ChargingType,
            maxWatt: 6600,
            serviceStatus: 'under_repair' as ServiceStatus,
          },
          connector: { id: 97, connectorId: 1, serviceStatus: connectorServiceStatus[5] },
        },
        {
          id: 2,
          title: 'L7SME2',
          participantId: '123123',
          charger: {
            id: 3,
            chargePointSerialNumber: '1EC1-1-1834-00185',
            chargePointModel: 'foo',
            chargePointVendor: 'LITE-ON',
            online: true,
            chargingType: 'Level 2' as ChargingType,
            maxWatt: 6600,
            serviceStatus: 'under_repair' as ServiceStatus,
          },
          connector: { id: 97, connectorId: 1, serviceStatus: connectorServiceStatus[5] },
        },
      ],
      participantRef: '222',
    },
  ],
  pagination: {
    currentPage: 1,
    perPage: 10,
    totalEntries: 1,
  },
}

export const mockPeakShavingProgramDelete = {
  response: 'Program has been deleted successfully',
}

export const mockListAllListingsForAProgram = {
  data: [
    {
      id: 6,
      title: 'L7SME',
      chargerSerialNumber: '372b428f-fee0-400f-b33b-d6f149198602',
      chargerVendor: 'LITE-ON',
      chargerModel: 'EX-1762-1EC0',
      tenantName: 'TestTenant',
      connector: {
        id: 8,
        connectorId: 1,
        serviceStatus: connectorServiceStatus[0],
        ocppStatus: connectorStatus[6],
      },
      charger: {
        id: 3,
        chargePointSerialNumber: '1EC1-1-1834-00185',
        chargePointModel: 'foo',
        chargePointVendor: 'LITE-ON',
        online: true,
        chargingType: 'Level 2' as ChargingType,
        maxWatt: 6600,
        serviceStatus: 'under_repair' as ServiceStatus,
      },
      tenant: {
        id: 1,
        name: '380-Waterloo-Ave-Guelph-ON-N1H7H8',
        displayName: '380-Waterloo-Ave-Guelph-ON-N1H7H8',
        country: 'CA',
      },

      shavingPrimaryZoneStrategy: 0,
      shavingSecondaryZoneStrategy: 40,
    },
    {
      id: 7,
      title: 'RB8S1',
      chargerSerialNumber: 'faa952a8-7a67-4e33-b890-e34be878e917',
      chargerVendor: 'LITE-ON',
      chargerModel: 'EX-1762-1EC0',
      tenantName: 'TestTenant2',
      connector: {
        id: 8,
        connectorId: 1,
        serviceStatus: connectorServiceStatus[0],
        ocppStatus: connectorStatus[6],
      },
      charger: {
        id: 3,
        chargePointSerialNumber: '1EC1-1-1834-00185',
        chargePointModel: 'foo',
        chargePointVendor: 'LITE-ON',
        online: true,
        chargingType: 'DCFC' as ChargingType,
        maxWatt: 25000,
        serviceStatus: 'under_repair' as ServiceStatus,
      },
      tenant: {
        id: 1,
        name: '380-Waterloo-Ave-Guelph-ON-N1H7H8',
        displayName: '380-Waterloo-Ave-Guelph-ON-N1H7H8',
        country: 'CA',
      },

      shavingPrimaryZoneStrategy: 60,
      shavingSecondaryZoneStrategy: 80,
    },
  ],
  pagination: { currentPage: 1, totalEntries: 2, perPage: 10 },
} as any

export const mockListingAddedToProgram = {
  response: 'Listing has been added to the program successfully',
}

export const mockProgramPublished = {
  response: 'Program has been published successfully',
}

export const mockProgramUnPublished = {
  response: 'Program has been converted to draft successfully',
}

export const mockListingRemovedFromProgram = {
  response: 'Listing has been removed from the program successfully',
}

export const mockParticipantRemovedFromProgram = {
  response: 'Participant has been removed from the program successfully',
}

export const mockTenantRemovedFromProgram = {
  response: 'Tenant has been removed from the program successfully',
}

export const mockPeakShavingEvents = {
  data: [
    {
      id: 1,
      state: 'in_progress' as PeakShavingEventState,
      date: '2023-05-18',
      allowOptOut: false,
      source: 'manual' as PeakShavingSourceType,
      notes: 'Iusto eos id quia.',
      frequency: 'daily' as PeakShavingScheduleFrequency,
      adminPersonId: 0,
      systemGenerated: false,
      programId: 18,
      modificationNumber: 0,
      modificationDateTime: '2023-07-25T15:20:39.000Z',
      participant: {
        participantId: 1,
        participantRef: '111',
      },
      program: {
        id: 19,
        name: 'Occaecati et accusamus consequuntur.',
        aggregatorName: 'Leap',
        startDate: '2024-05-08T00:00:00.000Z',
        endDate: '2024-05-08T00:00:00.000Z',
        participationWindow: '4-hour window',
        eventNotification: 'Day Ahead',
        voluntary: false,
        category: 'demand response' as PeakShavingProgramCategoryType,
        initiative: 'EnergyHub',
        state: 'draft' as PeakShavingProgramState,
        hasShavingEvents: true,
        timezone: null,
      },
      adminPerson: mockUserRef[0],
    },
    {
      id: 2,
      state: 'overridden' as PeakShavingEventState,
      date: '2023-05-18',
      allowOptOut: false,
      source: 'manual' as PeakShavingSourceType,
      notes: 'Iusto eos id quia.',
      frequency: 'manual' as PeakShavingScheduleFrequency,
      adminPersonId: 0,
      systemGenerated: false,
      programId: 18,
      modificationNumber: 0,
      modificationDateTime: '2023-07-25T15:20:39.000Z',
      participant: {
        participantId: 2,
        participantRef: '112',
      },
      program: {
        id: 18,
        name: 'Occaecati et accusamus consequuntur.',
        aggregatorName: 'Leap',
        startDate: '2024-05-08T00:00:00.000Z',
        endDate: '2024-05-08T00:00:00.000Z',
        participationWindow: '4-hour window',
        eventNotification: 'Day Ahead',
        voluntary: false,
        category: 'demand response' as PeakShavingProgramCategoryType,
        initiative: 'EnergyHub',
        state: 'draft' as PeakShavingProgramState,
        hasShavingEvents: true,
        timezone: null,
      },
      adminPerson: {
        id: 'YF7JyZM8ScRVlEq27OYTwg',
        name: 'Julien Cassis',
      },
    },
    {
      id: 3,
      state: 'draft' as PeakShavingEventState,
      date: '2023-05-18',
      allowOptOut: false,
      source: 'manual' as PeakShavingSourceType,
      notes: 'Iusto eos id quia.',
      frequency: 'daily' as PeakShavingScheduleFrequency,
      adminPersonId: 0,
      systemGenerated: false,
      programId: 18,
      modificationNumber: 0,
      modificationDateTime: '2023-07-25T15:20:39.000Z',
      participant: {
        participantId: 3,
        participantRef: '222',
      },
      program: {
        id: 18,
        name: 'Occaecati et accusamus consequuntur.',
        aggregatorName: 'Leap',
        startDate: '2024-05-08T00:00:00.000Z',
        endDate: '2024-05-08T00:00:00.000Z',
        participationWindow: '4-hour window',
        eventNotification: 'Day Ahead',
        voluntary: false,
        category: 'demand response' as PeakShavingProgramCategoryType,
        initiative: 'EnergyHub',
        state: 'draft' as PeakShavingProgramState,
        hasShavingEvents: true,
        timezone: null,
      },
      adminPerson: mockUserRef[1],
    },
    {
      id: 4,
      state: 'finished' as PeakShavingEventState,
      date: '2023-05-18',
      allowOptOut: false,
      source: 'manual' as PeakShavingSourceType,
      notes: 'Iusto eos id quia.',
      frequency: 'daily' as PeakShavingScheduleFrequency,
      adminPersonId: 0,
      systemGenerated: false,
      programId: 19,
      modificationNumber: 0,
      modificationDateTime: '2023-07-25T15:20:39.000Z',
      participant: {
        participantId: 3,
        participantRef: '222',
      },
      program: {
        id: 19,
        name: 'Occaecati et accusamus consequuntur.',
        aggregatorName: 'Leap',
        startDate: '2024-05-08T00:00:00.000Z',
        endDate: '2024-05-08T00:00:00.000Z',
        participationWindow: '4-hour window',
        eventNotification: 'Day Ahead',
        voluntary: false,
        category: 'demand response' as PeakShavingProgramCategoryType,
        initiative: 'EnergyHub',
        state: 'draft' as PeakShavingProgramState,
        hasShavingEvents: true,
        timezone: null,
      },
      adminPerson: mockUserRef[2],
    },
  ],
  pagination: { currentPage: 1, totalEntries: 2, perPage: 10 },
}

export const mockPeakShavingEventDeleted = {
  response: 'Shaving event has been deleted successfully',
}

export const mockPeakShavingEventPublished = {
  response: 'Shaving event has been published successfully',
}

export const mockPeakShavingEventUnPublished = {
  response: 'Shaving event has been unpublished successfully',
}

export const mockPeakShavingEventSchedule = {
  id: 1,
  from: 8,
  to: 9,
  zone: 'primary' as PeakShavingEventScheduleZone,
}

export const mockGetAllPeakShavingCurtailmentSchedule = [
  {
    shavingEventId: 1,
    from: 8,
    to: 9,
    curtailmentPercent: 0,
    dayType: 'weekday' as PeakShavingDailyEventDayType,
  },
  {
    shavingEventId: 2,
    from: 9,
    to: 10,
    curtailmentPercent: 40,
    dayType: 'weekend' as PeakShavingDailyEventDayType,
  },
  {
    shavingEventId: 3,
    from: 20,
    to: 21,
    curtailmentPercent: 60,
    dayType: 'any' as PeakShavingDailyEventDayType,
  },
  {
    shavingEventId: 4,
    from: 22,
    to: 23,
    curtailmentPercent: 80,
  },
]

export const mockGetAllPeakShavingEventSchedule = [
  {
    id: 1,
    from: 8,
    to: 9,
    zone: 'primary' as PeakShavingEventScheduleZone,
    dayType: 'weekday' as PeakShavingDailyEventDayType,
  },
  {
    id: 2,
    from: 9,
    to: 10,
    zone: 'secondary' as PeakShavingEventScheduleZone,
    dayType: 'weekend' as PeakShavingDailyEventDayType,
  },
  {
    id: 3,
    from: 20,
    to: 21,
    zone: 'secondary' as PeakShavingEventScheduleZone,
    dayType: 'any' as PeakShavingDailyEventDayType,
  },
  {
    id: 4,
    from: 22,
    to: 23,
    zone: 'secondary' as PeakShavingEventScheduleZone,
  },
]

export const mockPeakShavingEventScheduleRemoved = {
  response: 'Schedule has been removed successfully',
}

export const mockPeakShavingEventCancelled = {
  response: 'Shaving event has been cancelled successfully',
}

export const mockPeakShavingProgramArchived = {
  response: 'Program has been archived successfully',
}

export const mockPeakShavingParticipantRemoved = {
  response: 'Participant has been removed successfully',
}

export const mockParticipants: Participants[] = [
  {
    id: 9,
    tenantId: 52,
    participantRef: 'eba8b15b-a936-47e3-ab79-001be2266546',
    participantType: 'tenant',
    listingId: null,
    active: true,
    allListing: true,
    programs: [
      {
        id: 9,
        name: 'Ea ipsam hic qui.',
        category: 'peak shaving',
        initiative: 'EnergyHub',
        state: 'draft',
        timezone: null,
        aggregatorName: '123',
        startDate: '2024-05-08T00:00:00.000Z',
        endDate: '2024-05-08T00:00:00.000Z',
        participationWindow: '4-hour window',
        eventNotification: 'Day Ahead',
        voluntary: false,
        createdAt: '2024-08-19T19:49:44Z',
        updatedAt: '2024-08-19T19:49:44Z',
      },
    ],
    tenant: mockTenantsRef[0],
  },
]
