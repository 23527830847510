import { Checkbox, Form, Select, Spin } from 'antd'
import { ReactElement } from 'react'

import { Box } from 'atom/box'
import { InputWrapper } from 'atom/chargers'
import { layout } from 'atom/form/page-layout'

import { Tenant, TenantPayload } from 'models/tenant'

import { useTenantTabOverview } from 'hooks/useTenantTabOverview'

import { ReusableSelector } from 'components/reusableSelector/selector'
import TenantFooter from 'features/tenants/TenantTabOverview/TenantFooter'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { useSelector } from 'hooks/useSelector'
import { PartnerRef } from 'models/partner'
import { useAppState } from 'state'

interface tenantTabDetailProps {
  tenant: Tenant
  onSave: (tenant: TenantPayload) => void
}

export const TenantTabPartnerIntegration: React.FC<tenantTabDetailProps> = ({ tenant, onSave }) => {
  const { isMockUpEnabled } = useAppState()
  document.querySelector('body')?.classList.add('redesignActive')
  const { loading, initialValues, form, disabled, updateTenantOverview, setDisabled } = useTenantTabOverview(
    tenant,
    onSave,
  )

  const { partnerText } = useTenantTranslation()

  const {
    multiPartnerHandlePlaceholder,
    multiPartnerHandleValue,
    multiPartnerDebounceFetcher,
    multiPartnerHandleChange,
  } = useSelector()

  const renderPartnerOption = (
    partner: PartnerRef,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={partner.id} value={partner.id} label={partner.name}>
      <Checkbox
        onClick={handleCheckboxClick}
        checked={selectedOptions.findIndex((option) => option.id === partner.id) > -1}
      >
        {partner.name}
      </Checkbox>
    </Select.Option>
  )

  return (
    <Spin spinning={loading}>
      <Box padding>
        <Form
          {...layout}
          form={form}
          name={`tenant-${tenant?.id}`}
          onFinish={updateTenantOverview}
          initialValues={initialValues}
        >
          <InputWrapper>
            <Form.Item name="partnerIds" label={partnerText} className="align-center">
              <ReusableSelector
                showSearch
                disabled={disabled}
                onOptionsChange={(value) => {
                  form.setFieldsValue({ partnerIds: value.map((val: any) => val.id) })
                }}
                isSingle={false}
                defaultValues={
                  tenant.tenantPartners
                    ? tenant.tenantPartners.map((val) => ({ id: val.partnerId, name: val.partnerName }))
                    : undefined
                }
                dropDownList={
                  tenant.tenantPartners
                    ? tenant.tenantPartners.map((val) => ({ id: val.partnerId, name: val.partnerName }))
                    : []
                }
                showMagnifySVG={false}
                maxTagCount={0}
                isDebounceFetcher={true}
                handlePlaceholder={multiPartnerHandlePlaceholder}
                handleOptionChange={multiPartnerHandleChange}
                debounceFetcher={multiPartnerDebounceFetcher}
                renderOption={renderPartnerOption}
                handleValue={multiPartnerHandleValue}
                isMockUpEnabled={isMockUpEnabled}
              />
            </Form.Item>
            <TenantFooter form={form} disabled={disabled} setDisabled={setDisabled} />
          </InputWrapper>
        </Form>
      </Box>
    </Spin>
  )
}
