import { styled, theme } from '../theme'

interface props {
  dataRowHeight: number
  titleRowHeight: number
  howManyDataRows: number
}

interface ArchiveDiscountSchemaModalWrapperProps {
  onlyTitle?: boolean
}

export const CreatePricingModalWrapper = styled.div`
  font-family: ${theme.fontFamily.regularFontFamily};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;

  input,
  .ant-input,
  .ant-input-number {
    border-radius: 40px;
    height: 38px;
    width: 252px;
    font-size: 14px;
  }
  .ant-row {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }

  .combine-right.ant-select {
    min-width: 81px;
  }

  .combine-right.ant-select:hover {
    border-right-width: 1px;
  }

  .ant-select > .ant-select-selector {
    height: 38px;
  }

  .ant-select:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input):focus-within .ant-select-selector {
    border-color: ${theme.colors.primary};
    color: ${theme.colors.primary};
    border-right-width: 1px !important;
  }

  .ant-select:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input):focus-within
    .ant-select-selector:hover {
    border-color: ${theme.colors.primary};
    color: ${theme.colors.primary};
    border-right-width: 1px !important;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    border-color: ${theme.colors.lightGray};
  }

  .ant-select {
    width: 252px;
  }

  .combine-right.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    border-color: ${theme.colors.lightGray};
    border-right-color: transparent;
  }

  .combine-right.ant-select:not(.ant-select-customize-input):hover .ant-select-selector {
    border-color: ${theme.colors.primary};
  }

  .ant-form-item-label > label {
    width: 131px;
    font-size: 14px;
    font-weight: 400;
  }

  .combine-left > .ant-input-number-wrapper > .ant-input-number {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    margin-top: 0px;
  }

  .combine-left > .ant-input-number-wrapper > .ant-input-number-disabled:hover {
    border-color: #d9d9d9;
  }

  .ant-divider-inner-text {
    margin-right: 24px;
    margin-left: 24px;
  }

  .combine-right > .ant-select-selector .ant-select-selection-item {
    font-size: 14px;
    font-weight: 400;
    padding-top: 3px;
  }

  .ant-switch-checked {
    background-color: ${theme.colors.primary};
  }

  /* .ant-btn {
    margin-left: 115px;
  } */

  .ant-switch {
    margin-left: 0px;
    margin-right: 20px;
  }

  .ant-picker {
    width: 141px;
    border-radius: 40px;
    margin-left: 12px;
    margin-right: 12px;
    height: 38px;
  }

  button {
    margin-left: 110px;

    svg {
      margin-bottom: -0.5px;
      margin-right: 1px;
    }
  }

  .price-type,
  .loitering-type {
    margin-bottom: 0px;
  }

  .ant-input-number-group-addon {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    border: none;
  }

  .ant-input-number-group-addon:not(.ant-select-disabled):hover {
    border-color: ${theme.colors.primary};
    border-right-width: 1px;
  }

  .disabled > .ant-input-number-group .ant-input-number-group-addon:hover {
    border-color: #d9d9d9;
  }

  .flat-fee
    > .ant-form-item-control
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-item {
    padding-top: 3px;
  }

  .ant-input-number {
    margin-top: -3.5px;
  }

  .ant-input-number-input-wrap > input {
    padding-bottom: 2px;
    font-size: 14px;
    margin-left: 2px;
  }

  .footer-divider {
    margin-left: -24px;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
    box-shadow: none;
  }

  .ant-select-selector > .ant-select-selection-item {
    font-size: 14px;
  }

  .ant-select:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input):focus-within .ant-select-selector {
    color: black;
  }

  .ant-steps {
    width: 46%;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: black;
  }

  .add-rate-button {
    margin-top: -24px;
    margin-right: 25px;
  }

  .rate-title .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: 84px;
  }

  .name-title .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: 76px;
  }

  .promo-code-title
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: 35px;
  }

  .promo-code-title {
    margin-bottom: 4px;
  }

  .ant-select-disabled > .ant-select-selector {
    background-color: ${theme.colors.ternary25} !important;
  }

  .ant-input-number-disabled {
    background-color: ${theme.colors.ternary25} !important;
  }

  .ant-tag {
    padding: 4px 12px;
    align-content: center;
  }

  .ant-input::placeholder {
    font-size: 14px;
    position: absolute;
    left: 11px;
  }

  .ant-select > .ant-select-selector > .ant-select-selection-placeholder {
    font-size: 14px;
  }

  .ant-select-selection-overflow {
    margin-left: 20px;
  }

  .ant-select:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input):focus-within
    .ant-select-selector:hover {
    color: ${theme.colors.softBlack};
  }

  .combine-right.ant-select-disabled:not(.ant-select-customize-input):hover .ant-select-selector {
    border-color: ${theme.colors.lightGray};
    border-right-color: transparent;
  }

  .loitering-dollar-rate > .ant-select-selector > .ant-select-selection-item {
    padding-top: 3px;
  }

  .ant-select-selection-item-content {
    padding-top: 1px;
  }

  .listing > .ant-form-item-label > .ant-form-item-no-colon {
    margin-bottom: 23px;
  }

  .loitering-maximum > .ant-form-item-label > .ant-form-item-no-colon {
    margin-bottom: 26px;
  }

  .enable-pricing-curtailment > .ant-form-item-label > .ant-form-item-no-colon {
    margin-bottom: 11px;
  }

  .listing > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content > div {
    width: 252px;
  }
`

export const InfoTextWrapper = styled.div`
  display: flex;
  align-items: center;

  .danger-text {
    color: ${theme.colors.dangerPrimary};
    margin-bottom: 10px;
  }

  svg {
    margin-right: 5px;
  }

  .ant-select-item-option-content {
    font-size: 14px;
    font-weight: 400;
  }

  .ant-select-selector::placeholder {
    font-size: 14px !important;
  }
  .ant-select-selector .ant-select-selection-placeholder {
    font-size: 14px !important;
    padding-top: 1px;
  }
`

export const DiscountModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 32px;
  .red {
    background-color: ${theme.colors.dangerPrimary};
    border-color: ${theme.colors.dangerPrimary};
    color: ${theme.colors.white};

    :hover,
    :focus {
      background-color: ${theme.colors.dangerPrimary};
      border-color: ${theme.colors.dangerPrimary};
      color: ${theme.colors.white};
    }
  }

  .ant-space {
    flex: 1;
  }
  .ant-space-item {
    width: 100%;
  }
  .ant-btn {
    width: 100%;
  }
`

export const CustomFooterLayout = styled.div`
  display: flex;
  justify-content: flex-end;

  .ant-btn-default {
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
    border-color: ${theme.colors.lightGray};
    box-shadow: none;
    text-shadow: none;
    height: 38px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-align: center;
    font-family: ${theme.fontFamily.regularFontFamily};
    width: 175px;
    margin-left: 0px;
    margin-right: 10px;
    margin-top: 0px !important;
  }

  .ant-btn-default:hover {
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.lightGray};
  }

  .ant-btn-primary {
    box-shadow: none;
    text-shadow: none;
    height: 38px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: center;
    font-family: ${theme.fontFamily.regularFontFamily};
    width: 175px;
    margin-left: 0px;
  }

  .ant-btn-primary:hover {
    background-color: ${theme.colors.buttonHovering};
    border-color: ${theme.colors.buttonHovering};
  }

  .red {
    background-color: ${theme.colors.dangerPrimary};
    border-color: ${theme.colors.dangerPrimary};
    color: ${theme.colors.white};

    :hover,
    :focus {
      background-color: ${theme.colors.dangerPrimary};
      border-color: ${theme.colors.dangerPrimary};
      color: ${theme.colors.white};
    }
  }

  .ant-btn-ghost {
    width: 50%;
  }

  .ant-btn-default[disabled] {
    svg path {
      stroke: rgba(0, 0, 0, 0.25);
    }
  }
`

export const PricingSchemaTableWrapper = styled.div`
  .ant-table-thead > tr > th {
    text-align: left;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
  }

  .ant-table-tbody .ant-table-cell {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
  }

  .ant-table {
    padding-bottom: 75px;
    min-height: 62vh;
  }

  tr.ant-table-expanded-row > td {
    background: ${theme.colors.white};
    padding: 0;
    padding-left: 45px;
  }

  .discount-schema-info > .ant-table-tbody > tr > td:last-child {
    border-bottom: none;
  }

  .discount-schema-info > .ant-table-cell {
    border-bottom: none;
  }

  .discount-schema-table-style
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-thead
    > tr
    > .ant-table-cell:first-child {
    padding-left: 20px;
  }

  .discount-schema-table-style
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr
    > .ant-table-cell:first-child {
    padding-left: 20px;
  }
`

export const DiscountSchemaTableWrapper = styled.div<props>`
  .ant-table-cell {
    background: ${theme.colors.grayBackground};
  }

  .ant-table {
    padding-bottom: 0px;
    min-height: fit-content;
    margin-right: -10px;
  }

  &::after {
    content: '';
    position: absolute;
    left: 23.5px;
    top: 0%;
    height: ${(props) =>
      props.dataRowHeight ? `calc(100% - ${props.dataRowHeight / 2}px)` : `${props.titleRowHeight / 2}px`};
    border-left: 1px solid ${theme.colors.lightGray};
  }
`

export const DiscountSchemaMoreButtonWrapper = styled.div`
  font-family: ${(props) => props.theme.fontFamily.regularFontFamily};
  margin-left: -3px;

  .ant-btn-group > .ant-btn-icon-only {
    border-radius: 40px !important;
    background-color: ${theme.colors.grayBackground} !important;
    border-color: ${theme.colors.grayBackground} !important;
    padding: 0;
    height: 30px;
    width: 30px;
  }

  .ant-btn:focus {
    border-color: ${theme.colors.primary} !important;
    color: ${theme.colors.darkGray} !important;
  }

  .ant-btn > span {
    transform: rotate(0deg);
  }

  .ant-btn > span > svg {
    fill: ${theme.colors.darkGray};
    height: 22px;
    width: 22px;
    transform: rotate(90deg);
  }

  .ant-btn:focus > span > svg {
    fill: ${theme.colors.softBlack};
  }

  .ant-btn-group > .ant-btn:first-child,
  .ant-btn-group > span:first-child > .ant-btn {
    display: none;
  }
`

export const ArchiveDiscountSchemaModalWrapper = styled.div<ArchiveDiscountSchemaModalWrapperProps>`
  .title {
    font-family: ${theme.fontFamily.regularFontFamily};
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: ${(props) => (props.onlyTitle ? '0px' : '8px')};
  }

  .bullet-points {
    color: ${theme.colors.darkGray};
    margin-top: 4px;
    margin-bottom: 32px;

    ul {
      padding-left: 18px;
    }
  }

  .red {
    background-color: ${theme.colors.dangerPrimary};
    border-color: ${theme.colors.dangerPrimary};
    color: ${theme.colors.white};
    margin-left: 12px;

    :hover,
    :focus {
      background-color: ${theme.colors.dangerPrimary};
      border-color: ${theme.colors.dangerPrimary};
      color: ${theme.colors.white};
    }
  }
`

export const StepsWrapper = styled.div`
  .ant-steps {
    width: 36%;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: ${theme.colors.frenchGray};
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: ${theme.colors.frenchGray};
  }

  .ant-steps-item-title {
    font-size: 18px;
    font-weight: 400;
  }
`

export const ExtraInfoWrapper = styled.div`
  color: #242e2c99;
  margin-bottom: 16px;
  margin-left: 130px;
`
