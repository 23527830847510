import { ChargerInfo } from '../../models/chargerv2'

export type MockDemoTenantsChargerInfoType = {
  [key: string]: ChargerInfo
}

// export const mockChargerInfo: ChargerInfo = {
//   users: {
//     active: 50,
//     charging: 0,
//     idle: 0,
//     accountHolder: 0,
//     guest: 0,
//   },
//   connectors: {
//     pluggedIn: 4,
//     pluggedInLevel2: 0,
//     pluggedInDc: 1,

//     available: 3,
//     availableLevel2: 0,
//     availableDc: 1,

//     drProgram: 0,
//     drProgramLevel2: 0,
//     drProgramDc: 0,

//     charging: 1,
//     totalConnectors: 4,
//   },
//   chargers: {
//     maxAmp: 480,
//     currentAmp: 120,
//     online: 10,
//     offline: 0,
//     repair: 0,
//     troubleshooting: 0,
//     other: 0,
//     active: 0,
//   },
// }

export const mockChargerInfoForMultiFamily1WS = {
  users: {
    active: 10,
    charging: 9,
    idle: 1,
    accountHolder: 0,
    guest: 0,
  },
  connectors: {
    pluggedIn: 1,
    pluggedInLevel2: 1,
    pluggedInDc: 0,

    available: 5,
    availableLevel2: 5,
    availableDc: 0,

    drProgram: 0,
    drProgramLevel2: 0,
    drProgramDc: 0,

    charging: 3,
    totalConnectors: 6,
  },
  chargers: {
    maxAmp: 192,
    currentAmp: 48,
    online: 6,
    offline: 0,
    repair: 0,
    troubleshooting: 0,
    other: 0,
    active: 0,
  },
}
export const mockChargerInfoForMultiFamily2WS = {
  users: {
    active: 10,
    charging: 9,
    idle: 1,
    accountHolder: 0,
    guest: 0,
  },
  connectors: {
    pluggedIn: 2,
    pluggedInLevel2: 2,
    pluggedInDc: 0,

    available: 4,
    availableLevel2: 4,
    availableDc: 0,

    drProgram: 0,
    drProgramLevel2: 0,
    drProgramDc: 0,

    charging: 3,
    totalConnectors: 6,
  },
  chargers: {
    maxAmp: 192,
    currentAmp: 48,
    online: 6,
    offline: 0,
    repair: 0,
    troubleshooting: 0,
    other: 0,
    active: 0,
  },
}
export const mockChargerInfoForWorkplaceCommercialWS = {
  users: {
    active: 30,
    charging: 0,
    idle: 0,
    accountHolder: 0,
    guest: 0,
  },
  connectors: {
    pluggedIn: 2,
    pluggedInLevel2: 2,
    pluggedInDc: 0,

    available: 8,
    availableLevel2: 8,
    availableDc: 0,

    drProgram: 0,
    drProgramLevel2: 0,
    drProgramDc: 0,

    charging: 2,
    totalConnectors: 10,
  },
  chargers: {
    maxAmp: 320,
    currentAmp: 64,
    online: 10,
    offline: 0,
    repair: 0,
    troubleshooting: 0,
    other: 0,
    active: 0,
  },
}
export const mockChargerInfoForDCFC_WS = {
  users: {
    active: 50,
    charging: 0,
    idle: 0,
    accountHolder: 0,
    guest: 0,
  },
  connectors: {
    pluggedIn: 4,
    pluggedInLevel2: 0,
    pluggedInDc: 1,

    available: 3,
    availableLevel2: 0,
    availableDc: 1,

    drProgram: 0,
    drProgramLevel2: 0,
    drProgramDc: 0,

    charging: 1,
    totalConnectors: 4,
  },
  chargers: {
    maxAmp: 480,
    currentAmp: 120,
    online: 4,
    offline: 0,
    repair: 0,
    troubleshooting: 0,
    other: 0,
    active: 0,
  },
}

export const allMockChargerInfos = [
  mockChargerInfoForMultiFamily1WS,
  mockChargerInfoForMultiFamily2WS,
  mockChargerInfoForWorkplaceCommercialWS,
  mockChargerInfoForDCFC_WS,
]

export const mockDemoTenantsChargerInfo: MockDemoTenantsChargerInfoType = {
  'demoMFU-site1': mockChargerInfoForMultiFamily1WS,
  'demoMFU-site2': mockChargerInfoForMultiFamily2WS,
  'demoWorkplace-site1': mockChargerInfoForWorkplaceCommercialWS,
  'demoDCFC-site1': mockChargerInfoForDCFC_WS,
}

// Function to sum ChargerInfo
export const sumChargerInfo = (chargerInfos: ChargerInfo[]) => {
  const result: ChargerInfo = {
    users: { active: 0, charging: 0, idle: 0, accountHolder: 0, guest: 0 },
    connectors: {
      pluggedIn: 0,
      pluggedInLevel2: 0,
      pluggedInDc: 0,
      available: 0,
      availableLevel2: 0,
      availableDc: 0,
      drProgram: 0,
      drProgramLevel2: 0,
      drProgramDc: 0,
      charging: 0,
      totalConnectors: 0,
    },
    chargers: { maxAmp: 0, currentAmp: 0, online: 0, offline: 0, repair: 0, troubleshooting: 0, other: 0, active: 0 },
  }

  chargerInfos.forEach((info) => {
    result.users.active += info.users.active
    result.users.charging += info.users.charging
    result.users.idle += info.users.idle
    result.users.accountHolder += info.users.accountHolder
    result.users.guest += info.users.guest

    result.connectors.pluggedIn += info.connectors.pluggedIn
    result.connectors.pluggedInLevel2 += info.connectors.pluggedInLevel2
    result.connectors.pluggedInDc += info.connectors.pluggedInDc
    result.connectors.available += info.connectors.available
    result.connectors.availableLevel2 += info.connectors.availableLevel2
    result.connectors.availableDc += info.connectors.availableDc
    result.connectors.drProgram += info.connectors.drProgram
    result.connectors.drProgramLevel2 += info.connectors.drProgramLevel2
    result.connectors.drProgramDc += info.connectors.drProgramDc
    result.connectors.charging += info.connectors.charging
    result.connectors.totalConnectors += info.connectors.totalConnectors

    result.chargers.maxAmp += info.chargers.maxAmp
    result.chargers.currentAmp += info.chargers.currentAmp
    result.chargers.online += info.chargers.online
    result.chargers.offline += info.chargers.offline
    result.chargers.repair += info.chargers.repair
    result.chargers.troubleshooting += info.chargers.troubleshooting
    result.chargers.other += info.chargers.other
    result.chargers.active += info.chargers.active
  })

  return result
}
