import { CheckCircleTwoTone, CloseOutlined, EditFilled, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Badge,
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  PageHeader,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
  Tag,
  Tooltip,
} from 'antd'
import { useEffect, useState } from 'react'
import { Box } from '../../atom/box'
import { ResponsiveTable } from '../../atom/responsive-table'
import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'
import { capitalizeFirstLetter } from '../../helpers/users'

import { AlertError } from '../../components/error'
import { AuthorizeDrivers } from '../../components/layouts/authenticated/authorize-drivers'
import { MultiTenantSelector } from '../../components/selector/multiple-tenant-selector'
import { UsersListView } from '../../components/users/users-list-view'

import { SwtchError } from '../../models/error'
import { PaginationMeta } from '../../models/pagination'
import { TenantRef } from '../../models/tenant'
import {
  AugmentedUser,
  AugmentedUserTenantPermission,
  DashboardAccessType,
  ResourceAccess,
  toAugmentedUser,
  User,
  UserRef,
  UserRole,
  UserUpdate,
} from '../../models/user'
import { GetUsers, ToggleRole, UpdateUser } from '../../services/data-provider/users'
import { useAppState } from '../../state'
import { styled, theme } from '../../theme'
import { AddUserToMultipleTenantsForm } from './user-add-multipleTenants-form'
import { UserEditNameForm } from './user-edit-name-form'
import { UserInvitation } from './user-invitation'
import { Plans } from '../../components/plans/plans'
import { Subscriptions } from '../../components/subscriptions/subscriptions'
import { NestedTableWrapper, TableWrapper } from '../../atom/table-wrapper'
import { UserSelector } from '../../components/selector/user-selector'
import { useSelector } from 'hooks/useSelector'

const { Option } = Select
const { Search } = Input

const NoSearchIcon = styled.div`
  .ant-input-group-addon {
    display: none;
  }
  ${(props) => props.theme.breakpoints.down('lg')} {
    .ant-select {
      width: 100%;
    }
  }
`

const FullWidthDropDown = styled.div`
  ${(props) => props.theme.breakpoints.down('lg')} {
    .ant-select {
      width: 100%;
    }
  }
`

const AccessTagContainer = styled.div`
  span {
    white-space: break-spaces;
    margin-bottom: 5px;
  }
`

export const UsersBasePage: React.FC = () => {
  document.querySelector('body')?.classList.remove('redesignActive')
  const { currentUser, selectedTenant, IsLaptop, IsDesktop, IsTablet, IsMobile, isMockUpEnabled } = useAppState()
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [users, setUsers] = useState<AugmentedUser[]>([])
  const [newUser, setNewUser] = useState<User>()
  const [term, setTerm] = useState<string>()
  const [selectedUser, setSelectedUser] = useState<AugmentedUser>()
  const [searchUser, setSearchUser] = useState<UserRef>()
  const [userUpdate, setUserUpdate] = useState<UserUpdate>()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [currentPage, setCurrentPage] = useState(1)
  const [showInvitationForm, setShowInvitationForm] = useState(false)
  const [showAuthorizedForm, setShowAuthorizedForm] = useState(false)
  const [showEditUserNameForm, setEditUserNameForm] = useState(false)
  const [showAddToMultipleTenantsForm, setShowAddToMultipleTenantsForm] = useState(false)
  const [addUserToTenant, setAddUserToTenant] = useState<AugmentedUser>()
  const [userTenantPermissions, setUserTenantPermsissions] = useState<AugmentedUserTenantPermission[]>()
  const [tab, setTab] = useState('')
  const [createPlanForm, setShowCreatePlanForm] = useState(false)
  const [showInviteForm, setShowInviteForm] = useState(false)
  const [error, setError] = useState<SwtchError>()

  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isMobile = IsMobile()
  const isLaptop = IsLaptop()
  const [tenantDisplayName, setTenantDisplayName] = useState<string | undefined>()

  const { siteLabelCleaner } = useSelector()

  const usersPageHeading = useFormatMessage('dashboard.usersPage.heading', 'Users')
  const searchUserTxt = useFormatMessage('dashboard.usersPage.searchUser', 'Search user')
  const driverInfoTxt = useFormatMessage(
    'dashboard.usersPage.table.nestedTable.accessLevel.driver.info',
    'A user that has access to private listings in',
  )

  const accessInfoTxt = useFormatMessage(
    'dashboard.usersPage.table.nestedTable.accessLevel.driver.info',
    "User's dashboard access to",
  )

  const adminText = useFormatMessage('dashboard.usersPage.table.heading.role.admin', 'Admin')
  const supportText = useFormatMessage('dashboard.usersPage.table.heading.role.support', 'Support')
  const driverText = useFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel.driver', 'Driver')
  const managerText = useFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel.manager', 'Manager')
  const viewerText = useFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel.viewer', 'Viewer')
  const userText = useFormatMessage('dashboard.usersPage.table.heading.role.user', 'User')
  const inviteUsersTo = useFormatMessage('dashboard.usersPage.inviteUsersTo', 'Invite user to')
  const invitedUsersTo = useFormatMessage('dashboard.usersPage.invitedUsersTo', 'Invited User to')
  const tenantsText = useFormatMessage('dashboard.navigation.tenants', 'Sites')
  const userTenantWarningText = useFormatMessage(
    'dashboard.usersPage.user.tenant.warning.info',
    'Note: The user is not associated with all tenants selected',
  )
  const userTenantExpandedViewText = useFormatMessage(
    'dashboard.usersPage.user.tenant.warning.exapandedView',
    'The user is not associated with this tenant',
  )

  const renderAccessLevel = (access: string) => {
    let accessLevel = undefined
    switch (access) {
      case 'admin':
        accessLevel = adminText.toLowerCase()
        break
      case 'driver':
        accessLevel = driverText.toLowerCase()
        break
      case 'manager':
        accessLevel = managerText.toLowerCase()
        break
      case 'support':
        accessLevel = supportText.toLowerCase()
        break
      case 'viewer':
        accessLevel = viewerText.toLowerCase()
        break
    }
    return accessLevel
  }

  useEffect(() => {
    setLoading(true)
    setError(undefined)
    GetUsers(currentPage, isMockUpEnabled, term, selectedTenant, tenants)
      .then((resp) => {
        setUsers(resp.data)
        setPagination(resp.pagination)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
    getTenantDisplayName()
  }, [term, tenants, currentPage, selectedUser, userTenantPermissions, newUser])

  const tenantScope: TenantRef[] = selectedTenant
    ? [
        {
          id: selectedTenant.id,
          name: selectedTenant.name,
          displayName: selectedTenant.displayName,
          combineName: selectedTenant.combineName,
        },
      ]
    : tenants
  const hasTenantScope = tenantScope !== undefined

  useEffect(() => {
    if (selectedTenant) {
      setTenants([
        {
          id: selectedTenant.id,
          name: selectedTenant.name,
          displayName: selectedTenant.displayName,
          combineName: selectedTenant.combineName,
        },
      ])
    }
  }, [])

  const handleToggleRole = (userId: string, role: UserRole) => {
    setError(undefined)
    ToggleRole(userId, role)
      .then((user) => {
        addOrUpdateUser(user)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {})
  }

  const handleUserUpdate = () => {
    if (!tenantScope) {
      return
    }

    if (!selectedUser || !userUpdate) {
      return
    }
    setLoading(true)

    tenantScope &&
      Array.isArray(tenantScope) &&
      UpdateUser(tenantScope, selectedUser.id, userUpdate)
        .then((u: AugmentedUser) => {
          addOrUpdateUser(u)
          setSelectedUser(undefined)
          setUserUpdate(undefined)
          setEditUserNameForm(false)
        })
        .catch((err: SwtchError) => setError(err))
        .finally(() => setLoading(false))
  }

  const handleUserInvited = (newUser: User) => {
    setShowInvitationForm(false)
    setUsers([...users, toAugmentedUser(newUser)])
    message.success(renderInviteUserTitle(true))
    return setNewUser(newUser)
  }

  const onPaginationChange = (page: number, pageSize?: number) => {
    setCurrentPage(page)
  }

  const addOrUpdateUsers = (users: AugmentedUser[]) => {
    users.forEach((u) => addOrUpdateUser(u))
  }

  const addOrUpdateUser = (user: AugmentedUser) => {
    let updated = false
    const newUserList = users.map((u) => {
      if (u.id === user.id) {
        updated = true
        return user
      }
      return u
    })
    if (!updated) {
      newUserList.push(user)
    }
    setUsers(newUserList)
  }

  const handleClickCross = () => {
    setUserUpdate((prev) => (prev ? { ...prev, access: undefined } : { driver: false, access: undefined, name: '' }))
  }

  const handleClickPlus = () => {
    setUserUpdate((prev) => (prev ? { ...prev, access: 'manager' } : { driver: false, access: 'manager', name: '' }))
  }

  const handleUserSelect = (value: DashboardAccessType) => {
    const access = value
    setUserUpdate((prev) => (prev ? { ...prev, access: access } : { driver: false, access: access, name: '' }))
  }

  const handleDriverSelect = (checked: boolean) => {
    setUserUpdate((prev) => (prev ? { ...prev, driver: checked } : { driver: checked, access: undefined, name: '' }))
  }

  const handleUserForm = (firstName: string, lastName: string) => {
    const fullName = `${firstName.trim()} ${lastName.trim()}`
    const updatedName = capitalizeFirstLetter(fullName)
    userUpdate &&
      setUserUpdate(() => {
        userUpdate.name = updatedName
        return userUpdate
      })
    handleUserUpdate()
  }

  const handleUserTenantsUpdatedForm = (user: AugmentedUser) => {
    setAddUserToTenant(user)
    const { tenantPermission } = user

    setUserTenantPermsissions(Object.values(tenantPermission))
    setShowAddToMultipleTenantsForm(false)
  }

  const renderInviteUserTitle = (flag?: boolean) => {
    let temp = undefined
    if (tenants && tenants.length === 1) {
      temp = siteLabelCleaner(tenants[0].combineName)
    } else if (tenants && tenants.length > 1) {
      temp = `${tenants.length} ${tenantsText}`
    }

    if (flag) {
      return `${invitedUsersTo} ${temp}`
    }

    return `${inviteUsersTo} ${temp}`
  }

  const renderDriverInfoText = () => {
    let temp = undefined
    if (tenants && tenants.length === 1) {
      temp = siteLabelCleaner(tenants[0].combineName)
    } else if (tenants && tenants.length > 1) {
      temp = `${tenants.length} ${tenantsText}`
    }
    return `${driverInfoTxt} ${temp}`
  }

  const renderAccessInfoText = () => {
    let temp = undefined
    if (tenants && tenants.length === 1) {
      temp = siteLabelCleaner(tenants[0].combineName)
    } else if (tenants && tenants.length > 1) {
      temp = `${tenants.length} ${tenantsText}`
    }
    return `${accessInfoTxt} ${temp}`
  }

  const handleModifyBtn = (user: AugmentedUser) => {
    if (!tenantScope) {
      return
    }

    tenantScope &&
      Array.isArray(tenantScope) &&
      tenantScope.map((tenantScopeObj) => {
        const { id } = tenantScopeObj

        const driver = user.tenantPermission[id]?.driver
        const access = user.tenantPermission[id]?.access
        const name = user.name

        return setUserUpdate({
          driver: driver,
          access: access,
          name: name,
        })
      })
    setSelectedUser(user)
  }

  const getTenantDisplayName = () => {
    const tenantName = currentUser?.accesses.filter((obj) => {
      return obj.display === selectedTenant?.name
    })
    if (tenantName !== undefined && tenantName.length !== 0) {
      setTenantDisplayName(
        siteLabelCleaner(
          `${tenantName[0].displayName ? `${tenantName[0].displayName} ` : ''}(${tenantName[0].display || ''})`,
        ),
      )
    }
  }

  const handleAddUserToTenants = (user: AugmentedUser) => {
    setShowAddToMultipleTenantsForm(true)
    setAddUserToTenant(user)
  }

  const checkUserAccesses = (user: AugmentedUser, currentUser: AugmentedUser) => {
    const { tenantPermission } = user
    const { tenantPermission: currentUserTenantPermission } = currentUser
    const userAccesses = Object.values(tenantPermission)
      .map(({ access }) => ({ access }))
      .filter(({ access }) => access !== undefined)
    const managerAccesses = Object.values(currentUserTenantPermission)
      .map(({ access }) => ({ access }))
      .filter(({ access }) => access !== undefined)

    if (currentUser.isAdmin) return <Button onClick={() => handleAddUserToTenants(user)}>Add User</Button>

    return userAccesses.length === managerAccesses.length ? (
      <Tag color="orange">User has access to all associated tenants</Tag>
    ) : (
      <Button onClick={() => handleAddUserToTenants(user)}>Add User</Button>
    )
  }

  const renderDriverCheckbox = (user: AugmentedUser): boolean => {
    const { tenantPermission } = user

    let driver = false
    for (var key of Object.keys(tenantPermission)) {
      driver = tenantPermission[key]['driver']
      if (driver) break
    }

    return driver
  }

  const renderAccessTag = (user: AugmentedUser): JSX.Element => {
    const { tenantPermission } = user
    const permissions = Object.values(tenantPermission)

    const selectedUserTenants = permissions.map((permission) => permission.name)

    const selectedTenants = tenants.map((tenant) => tenant.name)
    const checkSelectedTenants = selectedTenants.every((v) => selectedUserTenants.includes(v))

    let access: DashboardAccessType | 'Multi' | undefined
    if (tenants.length === 1) {
      access = permissions
        .filter((permission) => permission.id === tenants[0].id)
        .map((permission) => permission.access)[0]
    } else if (tenants.length > 1) {
      const accesses = permissions.map((obj) => obj.access)
      if (accesses.every((access) => access === 'manager')) {
        access = 'manager'
      } else if (accesses.every((access) => access === 'viewer')) {
        access = 'viewer'
      } else if (accesses.some((access) => access === 'manager' || access === 'viewer')) {
        access = 'Multi'
      }
    }

    if (access && checkSelectedTenants) {
      return <Tag>{access}</Tag>
    } else if (!checkSelectedTenants) {
      return (
        <AccessTagContainer>
          <Tag>{access}</Tag>
          {term && <Tag color="red">{userTenantWarningText}</Tag>}
        </AccessTagContainer>
      )
    }
    return <Tag />
  }

  const columns: any = [
    {
      title: renderFormatMessage('dashboard.usersPage.table.heading.id', 'Id'),
      dataIndex: 'id',
    },
    {
      title: renderFormatMessage('dashboard.usersPage.table.heading.email', 'Email'),
      dataIndex: 'email',
    },
    {
      title: renderFormatMessage('dashboard.usersPage.table.heading.name', 'Name'),
      render: (user: User) => {
        if (selectedUser && selectedUser.id === user.id) {
          return (
            <Row align="middle" justify="space-between">
              <Space align="end">
                {user.name}
                <Tooltip title="Edit User's Name">
                  <EditFilled style={{ fontSize: theme.fontSizes[2] }} onClick={() => setEditUserNameForm(true)} />
                </Tooltip>
              </Space>
            </Row>
          )
        }
        return user.name
      },
    },
    {
      title: renderFormatMessage('dashboard.usersPage.selectTenants.tbl.heading', 'Add User to Tenants'),
      key: 'manageraddusertotenants',
      render: (user: AugmentedUser) =>
        !user.isAdmin && currentUser && checkUserAccesses(user, toAugmentedUser(currentUser)),
    },
  ]

  if (currentUser?.role !== 'user') {
    columns.push({
      title: renderFormatMessage('dashboard.usersPage.table.heading.role', 'Role'),
      key: 'admin',
      render: (user: AugmentedUser) => {
        if (user.isAdmin) {
          return <Tag color="green">{adminText.toLowerCase()}</Tag>
        } else if (user.isSupport) {
          return <Tag color="warning">{supportText.toLowerCase()}</Tag>
        } else {
          return <Tag color="blue">{userText.toLowerCase()}</Tag>
        }
      },
    })
  }

  if (hasTenantScope && tenants && tenants.length > 0) {
    columns.push(
      {
        title: (
          <Tooltip placement="topLeft" title={renderDriverInfoText()}>
            {driverText}
            <InfoCircleOutlined />
          </Tooltip>
        ),
        key: 'driver',
        render: (user: AugmentedUser) => {
          if (!tenantScope) {
            return <></>
          }
          const isDriver = renderDriverCheckbox(user)

          if (selectedUser && selectedUser.id === user.id) {
            return <Switch defaultChecked={isDriver} onChange={(checked) => handleDriverSelect(checked)} />
          }

          if (isDriver) {
            return <CheckCircleTwoTone twoToneColor={theme.colors.green} style={{ fontSize: '21px' }} />
          }

          return <></>
        },
      },
      {
        title: (
          <Tooltip placement="topLeft" title={renderAccessInfoText()}>
            {renderFormatMessage('dashboard.usersPage.table.heading.access', 'Access')} <InfoCircleOutlined />
          </Tooltip>
        ),
        key: 'tenantAccess',
        render: (user: AugmentedUser) => {
          if (user.role !== 'user') {
            return <></>
          }

          if (!tenantScope) {
            return <></>
          }

          if (selectedUser && selectedUser.id === user.id) {
            return (
              <div>
                {userUpdate?.access && (
                  <Input.Group compact>
                    <Select defaultValue={userUpdate?.access} onChange={(value) => handleUserSelect(value)}>
                      <Option key="manager" value="manager">
                        {managerText}
                      </Option>
                      <Option key="viewer" value="viewer">
                        {viewerText}
                      </Option>
                    </Select>
                    <Button type="ghost" danger onClick={() => handleClickCross()}>
                      <CloseOutlined style={{ fontSize: '20px' }} />
                    </Button>
                  </Input.Group>
                )}
                {!userUpdate?.access && (
                  <Button type="primary" onClick={() => handleClickPlus()}>
                    <PlusOutlined style={{ fontSize: '20px' }} />
                  </Button>
                )}
              </div>
            )
          }

          return renderAccessTag(user)
        },
      },
      {
        title: renderFormatMessage('dashboard.usersPage.table.heading.action', 'Actions'),
        key: 'actions',
        render: (user: AugmentedUser) => {
          if (!user.isUser) {
            return <></>
          }
          if (selectedUser && selectedUser.id === user.id) {
            return (
              <Space>
                <Button type="primary" onClick={() => handleUserUpdate()}>
                  {renderFormatMessage('dashboard.usersPage.table.heading.access.modify.saveBtn', 'Save')}
                </Button>
                <Button danger type="ghost" onClick={() => setSelectedUser(undefined)}>
                  {renderFormatMessage('dashboard.usersPage.table.heading.access.modify.cancelBtn', 'Cancel')}
                </Button>
              </Space>
            )
          }
          if (selectedUser) {
            return <></>
          }

          if (!tenantScope) {
            return <></>
          }

          if (user.id === currentUser?.id) {
            return <></>
          }

          return (
            <Space>
              <Button type="primary" onClick={() => handleModifyBtn(user)}>
                {renderFormatMessage('dashboard.usersPage.table.heading.access.modify', 'Modify')}
              </Button>
            </Space>
          )
        },
      },
    )
  } else {
    if (currentUser?.role === 'admin') {
      columns.push(
        {
          title: renderFormatMessage('dashboard.usersPage.table.heading.tenant', 'Site Access'),
          key: 'tenantAccess',
          render: (user: User) => (
            <Badge count={user.accesses.length} style={{ backgroundColor: theme.colors.green }} />
          ),
        },
        {
          title: renderFormatMessage('dashboard.usersPage.table.heading.action', 'Actions'),
          key: 'action',
          render: (user: User) => {
            if (user.id === currentUser?.id) {
              return <></>
            }
            return (
              <Dropdown
                overlay={
                  <Menu onClick={(e) => handleToggleRole(user.id, e.key as UserRole)}>
                    <Menu.Item key="admin" disabled={user.role === 'admin'}>
                      {adminText}
                    </Menu.Item>
                    <Menu.Item key="support" disabled={user.role === 'support'}>
                      {supportText}
                    </Menu.Item>
                    <Menu.Item key="user" disabled={user.role === 'user'}>
                      {userText}
                    </Menu.Item>
                  </Menu>
                }
                arrow
              >
                <Button>
                  {renderFormatMessage('dashboard.usersPage.table.heading.action.toggleRole', 'Toggle Role')}
                </Button>
              </Dropdown>
            )
          },
        },
      )
    }
  }

  const nestedColumns = [
    {
      title: '',
    },
    {
      title: renderFormatMessage('dashboard.usersPage.table.nestedTable.associatedTenants', 'Associated Tenants'),
      render: (resourceAccess: ResourceAccess) => {
        return siteLabelCleaner(
          `${resourceAccess.displayName ? `${resourceAccess.displayName} ` : ''}(${resourceAccess.display || ''})`,
        )
      },
    },
    {
      title: '',
    },
    {
      title: '',
    },
    {
      title: '',
    },
    {
      title: '',
      dataIndex: 'tenantNotSelected',
    },
    {
      title: '',
      dataIndex: 'nothingSelected',
    },
    {
      title: renderFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel', 'Access Level'),
      dataIndex: 'permissions',
      render: (permission: ResourceAccess['permissions']) =>
        permission && permission.length !== 0 ? (
          permission.map((p, index) => <Tag key={index}>{renderAccessLevel(p.split('.')[1])}</Tag>)
        ) : (
          <>{term && <Tag color="red">{userTenantExpandedViewText}</Tag>}</>
        ),
    },
    {
      title: '',
    },
    {
      title: '',
      dataIndex: 'tenantSelected',
    },
  ]

  const renderNestedColumns = () => {
    const tenant = tenants[0]
    if (!term && !tenant) return nestedColumns
    if (!term && tenant) {
      return nestedColumns
        .filter((col) => col.dataIndex !== 'tenantSelected')
        .filter((col) => col.dataIndex !== 'nothingSelected')
    }
    if (term && tenant) {
      return nestedColumns.filter((col) => col.dataIndex !== 'tenantSelected')
    }
    if (term && !tenant) {
      return nestedColumns
        .filter((col) => col.dataIndex !== 'tenantNotSelected')
        .filter((col) => col.dataIndex !== 'tenantSelected')
    }
  }

  const nestedUsersRow = (user: AugmentedUser) => {
    const { tenantPermission, accesses } = user
    const permissions = Object.values(tenantPermission)

    const tenantsUserHasAccessTo = permissions.map((permission) => {
      return {
        name: permission.name,
        id: permission.id,
      }
    })

    const selectedTenants = tenants.map((tenant) => {
      return {
        name: tenant.name,
        id: tenant.id,
        displayName: tenant.displayName,
      }
    })

    const tenantsUserHasNoAccess = selectedTenants.filter((el) => {
      return !tenantsUserHasAccessTo.some((f) => {
        return f.id === el.id && f.name === el.name
      })
    })

    term &&
      tenantsUserHasNoAccess.forEach((obj) => {
        accesses.push({
          display: obj.name,
          displayName: obj.displayName || obj.name,
          permissions: [],
          resourceId: obj.id,
          resourceType: 'Tenant',
        })
      })

    return (
      <NestedTableWrapper userIsAdmin={currentUser?.role === 'admin'}>
        <ResponsiveTable
          columns={renderNestedColumns()}
          dataSource={user.accesses}
          pagination={false}
          rowKey={(users: AugmentedUser): string => {
            return `${users.id}`
          }}
        />
      </NestedTableWrapper>
    )
  }

  const renderTitle = () => {
    if (selectedTenant) {
      return <></>
    }
    return (
      <Row gutter={[8, 8]}>
        <Space>
          {term && (
            <Tag closable={true} onClose={() => setTerm(undefined)}>
              {term}
            </Tag>
          )}
          {!term && (
            <NoSearchIcon>
              <Search placeholder={searchUserTxt} onSearch={(v) => setTerm(v)} />
            </NoSearchIcon>
          )}

          <>
            <MultiTenantSelector onOptionsChange={(tenants) => setTenants(tenants)} onClear={() => setTenants([])} />
          </>
        </Space>
      </Row>
    )
  }

  const renderColumns = (): any => {
    let currentAugmentedUser = currentUser && toAugmentedUser(currentUser)
    const tenantAccesses =
      currentAugmentedUser?.tenantPermission && Object.values(currentAugmentedUser?.tenantPermission)
    const isManager = tenantAccesses?.some((item) => item.access === 'manager')

    if ((currentAugmentedUser?.isAdmin || isManager) && !term) {
      return columns
        .filter((col: any) => col.key !== 'manageraddusertotenants')
        .filter((col: any) => col.key !== 'addusertotenants')
    }
    return term ? columns : columns.filter((col: any) => col.key !== 'addusertotenants')
  }

  const renderTitleMobile = () => {
    if (selectedTenant) {
      return <></>
    }
    return (
      <Row gutter={[8, 8]} style={{ marginBottom: '8px' }}>
        <Col xs={24} sm={24} md={12} lg={4} xl={3} xxl={2}>
          {term && (
            <Tag closable={true} onClose={() => setTerm(undefined)}>
              {term}
            </Tag>
          )}
          {!term && (
            <NoSearchIcon>
              <Search placeholder={searchUserTxt} onSearch={(v) => setTerm(v)} />
            </NoSearchIcon>
          )}
        </Col>
        <Col xs={24} sm={24} md={12} lg={4} xl={2} xxl={2}>
          <FullWidthDropDown>
            <MultiTenantSelector onOptionsChange={(tenants) => setTenants(tenants)} onClear={() => setTenants([])} />
          </FullWidthDropDown>
        </Col>
      </Row>
    )
  }

  const headerButtons = [
    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
      {tenants && Array.isArray(tenants) && tenants.length > 0 ? (
        <Space>
          <Button
            key="invite-btn"
            type="primary"
            onClick={() => setShowInvitationForm(true)}
            loading={loading}
            block={isMobile || isTablet ? true : false}
          >
            {renderInviteUserTitle()}
          </Button>
          <Button
            key="authorize-btn"
            type="primary"
            onClick={() => setShowAuthorizedForm(true)}
            loading={loading}
            block={isMobile || isTablet ? true : false}
          >
            {renderFormatMessage('dashboard.usersPage.authorizeDriver', ' Authorize Driver')}
          </Button>
        </Space>
      ) : null}
    </Col>,
  ]

  const handleTabOptions = () => {
    switch (tab) {
      case 'plans':
        return (
          <Button type="primary" onClick={() => setShowCreatePlanForm(true)}>
            {renderFormatMessage('dashboard.subscriptionsPage.button.title', 'Create Plan')}
          </Button>
        )
      case 'subscriptions':
        return (
          <Space>
            <UserSelector onUserSelected={(user) => setSearchUser(user)} onClear={() => setSearchUser(undefined)} />
            <Button type="primary" onClick={() => setShowInviteForm(true)}>
              {renderFormatMessage('dashboard.text.subscribeNewUser', 'Subscribe New User')}
            </Button>
          </Space>
        )
      default:
        return null
    }
  }

  const isExpandable = () =>
    currentUser?.role === 'admin'
      ? {
          expandedRowRender: (record: AugmentedUser) => nestedUsersRow(record),
          rowExpandable: (record: AugmentedUser) => record.accesses.length !== 0,
        }
      : undefined

  const usersTable = (
    <Row>
      <Col span={24}>
        <AlertError error={error} />
        <Col>
          {(isDesktop || isLaptop) && (
            <TableWrapper userIsAdmin={currentUser?.role === 'admin'}>
              <ResponsiveTable
                title={renderTitle}
                loading={loading}
                size="small"
                expandable={isExpandable()}
                pagination={{
                  position: ['bottomCenter'],
                  total: pagination?.totalEntries,
                  current: pagination?.currentPage,
                  pageSize: pagination?.perPage,
                  showSizeChanger: false,
                  onChange: onPaginationChange,
                }}
                dataSource={users}
                columns={renderColumns()}
                rowKey="id"
              />
            </TableWrapper>
          )}
          {(isMobile || isTablet) && (
            <>
              {renderTitleMobile()}
              <UsersListView
                users={users}
                onToggleRole={handleToggleRole}
                hasTenantScope={hasTenantScope}
                tenantScope={selectedTenant}
                selectedUser={selectedUser}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                loading={loading}
                onSelectedUser={(u) => setSelectedUser(u)}
                userUpdate={userUpdate}
                onClickSaveBtn={(firstName, lastName) => handleUserForm(firstName, lastName)}
                onClickCross={() => handleClickCross()}
                onClickPlus={() => handleClickPlus()}
                onUserSelect={(value) => handleUserSelect(value)}
                onDriverSelect={(checked) => handleDriverSelect(checked)}
                onClickModifyBtn={(user) => handleModifyBtn(user)}
                isMobile={isMobile}
                renderDriverCheckbox={(user) => renderDriverCheckbox(user)}
                renderAccessTag={(user) => renderAccessTag(user)}
                renderDriverInfoText={renderDriverInfoText}
                renderAccessInfoText={renderAccessInfoText}
              />
            </>
          )}
        </Col>
      </Col>
    </Row>
  )

  return (
    <>
      <PageHeader
        title={usersPageHeading}
        extra={
          <Row gutter={[8, 8]} justify="space-between">
            {headerButtons}
          </Row>
        }
      />
      <>
        <Box>
          {currentUser?.role === 'admin' ? (
            <Tabs defaultActiveKey="overview" onChange={(key) => setTab(key)} tabBarExtraContent={handleTabOptions()}>
              <Tabs.TabPane
                key="overview"
                tab={renderFormatMessage('dashboard.tenantPage.overviewTab.heading', 'Overview')}
              >
                {usersTable}
              </Tabs.TabPane>
              <Tabs.TabPane key="plans" tab={renderFormatMessage('dashboard.text.plans', 'Plans')}>
                <Plans createPlanForm={createPlanForm} onCancel={() => setShowCreatePlanForm(false)} />
              </Tabs.TabPane>
              <Tabs.TabPane
                key="subscriptions"
                tab={renderFormatMessage('dashboard.text.subscriptions', 'Subscriptions')}
              >
                <Subscriptions
                  onUserInvited={() => setShowInviteForm(false)}
                  showInviteForm={showInviteForm}
                  searchUser={searchUser}
                />
              </Tabs.TabPane>
            </Tabs>
          ) : (
            usersTable
          )}
        </Box>
      </>
      {showInvitationForm && !loading && tenants && tenants.length > 0 && (
        <UserInvitation
          selectedTenants={tenants}
          onCancel={(e: React.FormEvent<EventTarget>) => setShowInvitationForm(false)}
          onUserInvited={handleUserInvited}
          renderModalTitle={renderInviteUserTitle}
        />
      )}

      {selectedUser && showEditUserNameForm && !loading && tenants && tenants.length > 0 && (
        <UserEditNameForm
          onCancel={() => setEditUserNameForm(false)}
          onNameEdited={(firstName, lastName) => handleUserForm(firstName, lastName)}
          selectedUser={selectedUser}
        />
      )}
      {showAddToMultipleTenantsForm && !loading && addUserToTenant && currentUser && (
        <AddUserToMultipleTenantsForm
          onCancel={() => setShowAddToMultipleTenantsForm(false)}
          user={addUserToTenant}
          onUserUpdated={handleUserTenantsUpdatedForm}
        />
      )}

      {tenantScope && showAuthorizedForm && Array.isArray(tenantScope) && (
        <AuthorizeDrivers
          close={() => setShowAuthorizedForm(false)}
          tenants={tenantScope}
          onDrivesAuthorized={(drivers) => {
            message.success(`${drivers.map((d) => d.name).join(', ')} has been authorized!`)
            addOrUpdateUsers(drivers)
          }}
          tenantDisplayName={tenantDisplayName}
        />
      )}
    </>
  )
}
