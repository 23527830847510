import React, { ReactElement } from 'react'
import { Button, Checkbox, Form, Select, Switch, Tooltip } from 'antd'
import { SwtchError } from '../../../models/error'
import { TenantRef } from '../../../models/tenant'
import { UserPermissions } from '../../../models/user'
import { theme } from '../../../theme'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { addSVG } from '../../../assets/svg/add'
import { AlertError } from '../../../components/error'
import {
  AddMoreWrapper,
  AdminViewBody,
  AdminViewInputBody,
  LabelWrapper,
  PermissionWrapper,
  SvgSpacing,
  SwitchLabel,
  TenantInputBodyAdminView,
} from '../../../atom/user-invite'
import { useUserTranslation } from '../../../hooks/translation/useUserTranslation'
import { DropdownWrapper, SelectWrapper } from '../../../atom/user-edit'
import { HorizontalLine } from '../../../atom/horizontal-line'
import { DiscountSchema } from 'models/discount'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { useSelector } from 'hooks/useSelector'
import { useAppState } from 'state'

const { Option } = Select

interface props {
  userPermissions: UserPermissions[]
  error: SwtchError | undefined
  tenantDropdownData: {
    1: TenantRef[]
  }
  userPermissionInfo: ('none' | 'manager' | 'viewer' | 'charger_operator')[]
  roleTextTransform: {
    none: string
    manager: string
    charger_operator: string
    viewer: string
  }
  tenantInputs: number
  tenants: TenantRef[]
  currentDiscounts: {
    [key: number]: DiscountSchema[]
  }
  handleTenantChange: (value: string, index: number) => void
  handleRoleChange: (value: string, index: number) => void
  handleDriverChange: (index: number) => void
  setUserPermissions: (value: React.SetStateAction<UserPermissions[]>) => void
  setTenantInputs: (value: React.SetStateAction<number>) => void
  setTenantDropdownData: (
    value: React.SetStateAction<{
      1: TenantRef[]
    }>,
  ) => void
  handleDiscount: (selectedDiscounts: DiscountSchema[], index: number) => void
  selectTenant: (value: string, index: number) => void
}

export const UserInviteTenantsAdminView: React.FC<props> = ({
  userPermissions,
  error,
  tenantDropdownData,
  userPermissionInfo,
  roleTextTransform,
  tenantInputs,
  tenants,
  currentDiscounts,
  handleTenantChange,
  handleRoleChange,
  handleDriverChange,
  setUserPermissions,
  setTenantInputs,
  setTenantDropdownData,
  handleDiscount,
  selectTenant,
}) => {
  const { isMockUpEnabled } = useAppState()
  const [form] = Form.useForm()
  const {
    role2Text,
    tenantsInviteText,
    driverInviteText,
    tooltipInviteText,
    addPermissionsText,
    discountPlanText,
  } = useUserTranslation()
  const {
    siteLabelCleaner,
    multiDiscountDebounceFetcher,
    multiDiscountHandleChange,
    multiDiscountPlaceholder,
  } = useSelector()

  const renderOption = (tenant: TenantRef, index: any): ReactElement => {
    return (
      <Option style={{ marginTop: '10px' }} key={tenant.id} value={siteLabelCleaner(tenant.combineName)}>
        <DropdownWrapper selected={userPermissions[index].name === siteLabelCleaner(tenant.combineName)}>
          {siteLabelCleaner(tenant.combineName)}
        </DropdownWrapper>
      </Option>
    )
  }

  const renderDiscountOption = (
    { id, name }: DiscountSchema,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    return (
      <Select.Option key={id} value={name} label={name}>
        <Checkbox
          onClick={handleCheckboxClick}
          checked={selectedOptions.findIndex((selectedTenant) => selectedTenant.name === name) > -1}
        >
          {name}
        </Checkbox>
      </Select.Option>
    )
  }

  return (
    <>
      <AlertError error={error} />
      <Form form={form} labelAlign="right" layout="vertical">
        <HorizontalLine />
        <AdminViewInputBody>
          <TenantInputBodyAdminView>
            <PermissionWrapper>
              <Form.Item
                className="tenant-select"
                label={<LabelWrapper className="regular-cap">{tenantsInviteText}</LabelWrapper>}
              >
                <SelectWrapper>
                  <Select
                    dropdownMatchSelectWidth={false}
                    showSearch
                    onChange={(value) => selectTenant(value, 0)}
                    value={userPermissions[0].name.length ? userPermissions[0].name : undefined}
                    placeholder={tenantsInviteText}
                  >
                    {tenantDropdownData[1].map((v) => renderOption(v, 0))}
                  </Select>
                </SelectWrapper>
              </Form.Item>
              <AdminViewBody>
                <Form.Item
                  className="role-select"
                  key="role"
                  label={<LabelWrapper className="regular-cap">{role2Text}</LabelWrapper>}
                  name={0}
                >
                  <SelectWrapper>
                    <Select onChange={(value) => handleRoleChange(value, 0)} placeholder={role2Text}>
                      {userPermissionInfo.map((role) => (
                        <Select.Option key={role} value={role}>
                          <DropdownWrapper selected={userPermissions[0].access === role}>
                            {roleTextTransform[role]}
                          </DropdownWrapper>
                        </Select.Option>
                      ))}
                    </Select>
                  </SelectWrapper>
                </Form.Item>
                <Form.Item
                  className="switch"
                  key="driver"
                  label={
                    <>
                      <LabelWrapper className="regular-cap">{driverInviteText}</LabelWrapper>
                      <Tooltip overlayInnerStyle={{ borderRadius: '5px' }} placement="top" title={tooltipInviteText}>
                        <ExclamationCircleFilled
                          style={{ fontSize: theme.fontSizes[3], marginLeft: '5px', color: theme.colors.lightGray }}
                        />
                      </Tooltip>
                    </>
                  }
                  name="driver"
                >
                  <div>
                    <SwitchLabel>Off</SwitchLabel>
                    <Switch
                      style={{
                        marginLeft: '5px',
                        marginRight: '5px',
                        backgroundColor: userPermissions[0].driver ? theme.colors.neonGreen : '#bfbfbf',
                      }}
                      onChange={() => handleDriverChange(0)}
                    ></Switch>
                    <SwitchLabel>On</SwitchLabel>
                  </div>
                </Form.Item>
                <Form.Item
                  className="discount-schema-select"
                  label={<LabelWrapper className="regular-cap">{discountPlanText} </LabelWrapper>}
                >
                  <ReusableSelector
                    showSearch
                    onOptionsChange={(option) => handleDiscount(option, 0)}
                    isSingle={false}
                    placeholder={discountPlanText}
                    customHeight="32px"
                    customWidth="221px"
                    showMagnifySVG={false}
                    maxTagCount={0}
                    isDebounceFetcher={true}
                    dropDownList={currentDiscounts[0]}
                    handlePlaceholder={multiDiscountPlaceholder}
                    debounceFetcher={multiDiscountDebounceFetcher(parseFloat(userPermissions[0].id))}
                    handleOptionChange={multiDiscountHandleChange}
                    renderOption={renderDiscountOption}
                    isMockUpEnabled={isMockUpEnabled}
                  />
                </Form.Item>
              </AdminViewBody>
            </PermissionWrapper>

            {tenantInputs &&
              [...Array(tenantInputs)].map((tenantInput, tenantInputIndex) => {
                return (
                  <>
                    {tenantInputIndex > 0 && (
                      <PermissionWrapper>
                        <Form.Item
                          className="tenant-select"
                          label={<LabelWrapper className="regular-cap">{tenantsInviteText}</LabelWrapper>}
                        >
                          <Select
                            dropdownMatchSelectWidth={false}
                            showSearch
                            onChange={(value) => handleTenantChange(value, tenantInputIndex)}
                            value={
                              userPermissions[tenantInputIndex].name.length
                                ? userPermissions[tenantInputIndex].name
                                : undefined
                            }
                            placeholder={tenantsInviteText}
                          >
                            {tenantDropdownData[tenantInputIndex as keyof typeof tenantDropdownData]
                              .map((v) => renderOption(v, tenantInputIndex))
                              .sort(
                                (a: ReactElement, b: ReactElement) =>
                                  b.props.children.props.checked - a.props.children.props.checked,
                              )}
                          </Select>
                        </Form.Item>
                        <AdminViewBody key={tenantInputIndex}>
                          <Form.Item
                            className="role-select"
                            key="role"
                            label={<LabelWrapper className="regular-cap">{role2Text}</LabelWrapper>}
                          >
                            <SelectWrapper>
                              <Select
                                onChange={(value) => handleRoleChange(value, tenantInputIndex)}
                                placeholder={role2Text}
                              >
                                {userPermissionInfo.map((role) => (
                                  <Select.Option key={role} value={role}>
                                    <DropdownWrapper selected={userPermissions[tenantInputIndex].access === role}>
                                      {roleTextTransform[role]}
                                    </DropdownWrapper>
                                  </Select.Option>
                                ))}
                              </Select>
                            </SelectWrapper>
                          </Form.Item>
                          <Form.Item
                            className="switch"
                            key="driver"
                            name="driver"
                            label={
                              <>
                                <LabelWrapper className="regular-cap">{driverInviteText}</LabelWrapper>
                                <Tooltip
                                  overlayInnerStyle={{ borderRadius: '5px' }}
                                  placement="top"
                                  title={tooltipInviteText}
                                >
                                  <ExclamationCircleFilled
                                    style={{
                                      fontSize: theme.fontSizes[3],
                                      marginLeft: '5px',
                                      color: theme.colors.lightGray,
                                    }}
                                  />
                                </Tooltip>
                              </>
                            }
                          >
                            <SwitchLabel>Off</SwitchLabel>
                            <Switch
                              style={{
                                marginLeft: '5px',
                                marginRight: '5px',
                                backgroundColor: userPermissions[tenantInputIndex].driver
                                  ? theme.colors.neonGreen
                                  : '#bfbfbf',
                              }}
                              onChange={() => handleDriverChange(tenantInputIndex)}
                            ></Switch>
                            <SwitchLabel>On</SwitchLabel>
                          </Form.Item>
                          <Form.Item
                            className="discount-schema-select"
                            label={<LabelWrapper className="regular-cap">{discountPlanText} </LabelWrapper>}
                          >
                            <ReusableSelector
                              showSearch
                              onOptionsChange={(option) => handleDiscount(option, tenantInputIndex)}
                              isSingle={false}
                              customHeight="32px"
                              customWidth="221px"
                              placeholder={discountPlanText}
                              showMagnifySVG={false}
                              maxTagCount={0}
                              isDebounceFetcher={true}
                              defaultValues={currentDiscounts[tenantInputIndex]}
                              dropDownList={currentDiscounts[tenantInputIndex]}
                              handlePlaceholder={multiDiscountPlaceholder}
                              debounceFetcher={multiDiscountDebounceFetcher(
                                parseFloat(userPermissions[tenantInputIndex].id),
                              )}
                              handleOptionChange={multiDiscountHandleChange}
                              renderOption={renderDiscountOption}
                              isMockUpEnabled={isMockUpEnabled}
                            />
                          </Form.Item>
                        </AdminViewBody>
                      </PermissionWrapper>
                    )}
                  </>
                )
              })}
          </TenantInputBodyAdminView>
        </AdminViewInputBody>
        <AddMoreWrapper>
          <Form.Item className="add-more-button">
            <Button
              style={{
                color: theme.colors.neonGreen,
                padding: 0,
                height: 'fit-content',
                marginBottom: '0px',
                letterSpacing: '0.05em',
              }}
              type="link"
              onClick={() => {
                setUserPermissions([...userPermissions, { name: '', id: '', access: '', driver: false }])
                setTenantInputs((currentTenantInputs) => currentTenantInputs + 1)
                setTenantDropdownData({ ...tenantDropdownData, [tenantInputs + 1]: [...tenants] })
              }}
            >
              <SvgSpacing>{addSVG}</SvgSpacing>
              {addPermissionsText}
            </Button>
          </Form.Item>
        </AddMoreWrapper>
      </Form>
    </>
  )
}
