export const mockLocations = [
  {
    address: '109 ossington ave',
    city: 'Toronto',
    country: 'CA',
    latitude: 43.6373,
    listingTimezone: 'America/Toronto',
    longitude: -79.4242,
    postalCode: 'M6J 0G1',
    province: 'ON',
  },
]
