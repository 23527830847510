import { Button, Checkbox, Col, Input, Row, Select, Space, message } from 'antd'
import { Moment } from 'moment'
import { useAppState } from 'state'

import { CustomDateRangePicker } from 'components/redesign/datepicker'
import { ReusableSelector } from 'components/reusableSelector/selector'

import { useCalendarTransactions } from 'hooks/translation/useCalendarTranslation'
import { useSelector } from 'hooks/useSelector'

import { IncidencesFilter } from 'models/filter'

import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'

import { magnifyBlackSVG } from 'assets/svg/magnify'
import { ButtonIcon } from 'atom/button'
import { SingleSelectorWrapper } from 'atom/input'
import { LineBreak } from 'atom/line-break'
import { SwtchError } from 'models/error'
import { ListingRef, ServiceStatusDescription, ServiceStatusReasons } from 'models/listing'
import { TenantRef } from 'models/tenant'
import { ReactElement, useEffect, useState } from 'react'
import { FindListings } from 'services/data-provider/listing'
import { useIncidencesTranslation } from 'hooks/translation/useIncidencesTranslation'

interface props {
  loading: boolean
  startDate: Moment
  endDate: Moment
  tenant: TenantRef
  filter: IncidencesFilter
  dropdownClassName?: string
  onChangeDate: (dates: Moment[]) => void
  onFilter: (filters: IncidencesFilter) => void
  onFetch: () => void
  currentReasons: ServiceStatusReasons[]
  serviceDescriptions: ServiceStatusDescription[]
}

const IncidencesSelectors: React.FC<props> = ({
  loading,
  startDate,
  endDate,
  filter,
  dropdownClassName,
  tenant,
  onChangeDate,
  onFilter,
  onFetch,
  currentReasons,
  serviceDescriptions,
}) => {
  const { IsDesktop, IsLaptop, isMockUpEnabled } = useAppState()

  const [listings, setListings] = useState<ListingRef[]>([])

  useEffect(() => {
    FindListings(isMockUpEnabled, undefined, tenant)
      .then((listings) => setListings(listings))
      .catch((err: SwtchError) => {
        setListings([])
        message.error(err.description)
      })
  }, [])

  const { searchText } = useGeneralTranslation()
  const { listingTitlesText } = useListingBasicTrans()
  const { calendarRanges } = useCalendarTransactions()
  const { zendeskIdText, rootCauseText, rootCausesText, downtimeText, resolutionsText } = useIncidencesTranslation()

  const {
    multiListingTitleAndIdDebounceFetcher,
    multiListingTitleHandlePlaceholder,
    multiListingTitleAndIdHandleChange,

    multiListingTitleAndIdHandleValue,

    renderMultiOptions,
    multiHandlePlaceholder,
    multiHandleChange,

    renderSingleOption,
    handleSingleSelectChange,
  } = useSelector()

  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()

  const calendar = (
    <CustomDateRangePicker
      startDate={startDate}
      endDate={endDate}
      onChangeDate={onChangeDate}
      calendarRanges={calendarRanges}
      disabled={loading}
    />
  )

  const renderZendeskIdSelector = (
    <SingleSelectorWrapper isCombineLeft isDesktop={isDesktop} isLaptop={isLaptop}>
      <Input
        placeholder={zendeskIdText}
        onChange={(e) => onFilter({ ...filter, zendeskId: e.target.value })}
        prefix={<></>}
        style={{ minWidth: '213px', width: '100%', lineHeight: '26px' }}
        value={filter.zendeskId}
        allowClear
      />
    </SingleSelectorWrapper>
  )

  const renderMultiListingIdOption = (
    option: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={option.id} value={option.id} label={option.title}>
      <Checkbox
        onClick={handleCheckboxClick}
        checked={selectedOptions.findIndex((listing) => listing.title === option.title) > -1}
      >
        {option.title}
      </Checkbox>
    </Select.Option>
  )

  const renderMultiListingSelector = (
    <ReusableSelector
      showSearch
      showArrow
      onOptionsChange={(listingOption: ListingRef[]) => {
        const uniqueListings = listingOption.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.id === item.id && t.title === item.title && t.timeZone === item.timeZone),
        )

        onFilter({ ...filter, listings: uniqueListings })
      }}
      onClear={() => onFilter({ ...filter, listings: [] })}
      combineType="both"
      customWidth="200px"
      isSingle={false}
      loading={loading}
      placeholder={listingTitlesText}
      dropDownList={listings}
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={true}
      debounceFetcher={multiListingTitleAndIdDebounceFetcher}
      handlePlaceholder={multiListingTitleHandlePlaceholder}
      handleOptionChange={multiListingTitleAndIdHandleChange}
      renderOption={renderMultiListingIdOption}
      handleValue={multiListingTitleAndIdHandleValue}
      defaultTenants={[tenant]}
      isMockUpEnabled={isMockUpEnabled}
    />
  )

  const renderRootCausesSelector = (
    <ReusableSelector
      showSearch
      nativeSearch
      isSingle={false}
      clearSearchAfterSelect
      placeholder={rootCauseText}
      dropDownList={currentReasons}
      showMagnifySVG={false}
      defaultValues={filter?.rootCauseIds}
      fieldForValue="id"
      maxTagCount={0}
      isDebounceFetcher={false}
      handlePlaceholder={multiHandlePlaceholder('name', rootCausesText)}
      debounceFetcher={undefined}
      dropdownClassName={dropdownClassName}
      combineType="both"
      handleOptionChange={multiHandleChange('id')}
      renderOption={renderMultiOptions({
        val: 'id',
        label: 'name',
      })}
      onOptionsChange={(resolutions: ServiceStatusReasons[]) =>
        onFilter({ ...filter, rootCauseIds: resolutions.map(({ id }) => id) })
      }
      customWidth="200px"
      placeholderWidth="150px"
      isMockUpEnabled={isMockUpEnabled}
    />
  )

  const renderDownTimeSelector = (
    <ReusableSelector
      showSearch={true}
      onClear={() => onFilter({ ...filter, downtime: undefined })}
      isSingle={true}
      fieldForValue="label"
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={false}
      debounceFetcher={undefined}
      dropdownClassName={dropdownClassName}
      placeholder={downtimeText}
      dropDownList={[
        {
          value: 'include',
          label: 'Included',
        },
        {
          value: 'exclude',
          label: 'Excluded',
        },
      ]}
      combineType="both"
      onOptionsChange={(option) => {
        onFilter({ ...filter, downtime: option ? option.value : undefined })
      }}
      handleOptionChange={handleSingleSelectChange('label')}
      renderOption={renderSingleOption({
        val: 'label',
        label: 'label',
      })}
      customWidth="100px"
      stopClearSearchValue={true}
      isMockUpEnabled={isMockUpEnabled}
    />
  )

  const renderResolutionSelector = (
    <ReusableSelector
      showSearch
      nativeSearch
      isSingle={false}
      clearSearchAfterSelect
      placeholder={resolutionsText}
      dropDownList={serviceDescriptions}
      showMagnifySVG={false}
      defaultValues={filter?.resolutionIds}
      fieldForValue="id"
      maxTagCount={0}
      isDebounceFetcher={false}
      handlePlaceholder={multiHandlePlaceholder('name', resolutionsText)}
      debounceFetcher={undefined}
      dropdownClassName={dropdownClassName}
      combineType="left"
      handleOptionChange={multiHandleChange('id')}
      renderOption={renderMultiOptions({
        val: 'id',
        label: 'name',
      })}
      onOptionsChange={(serviceDescriptions: ServiceStatusDescription[]) =>
        onFilter({ ...filter, resolutionIds: serviceDescriptions.map(({ id }) => id) })
      }
      searchButtonMerged
      customWidth="200px"
      placeholderWidth="150px"
      isMockUpEnabled={isMockUpEnabled}
    />
  )

  return (
    <>
      {isDesktop || isLaptop ? (
        <Space wrap size={[0, 6]}>
          {calendar}
          <div style={{ marginRight: '1rem' }}></div>
          {renderZendeskIdSelector}
          <LineBreak />
          {renderMultiListingSelector}
          <LineBreak />
          {renderRootCausesSelector}
          <LineBreak />
          {renderDownTimeSelector}
          <LineBreak />
          {renderResolutionSelector}
          <ButtonIcon icon={magnifyBlackSVG} loading={loading} disabled={loading} fetchData={onFetch} withinSearchBar />
        </Space>
      ) : (
        <Row gutter={[8, 8]}>
          <Col xs={24} md={12}>
            {renderZendeskIdSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderMultiListingSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderRootCausesSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderDownTimeSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderResolutionSelector}
          </Col>
          <Col span={24}>
            <Button block type="default" disabled={loading} loading={loading} onClick={onFetch}>
              {searchText}
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default IncidencesSelectors
