import { Button, Col, Row, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Box } from '../../atom/box'

import { AlertError } from '../../components/error'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { Links } from '../../components/routes/paths'

import { SwtchError } from '../../models/error'

import _ from 'lodash'

import { plusSignSVG } from '../../assets/svg/plusSign'
import ProgramStatusBadge from '../../components/peakShaving/peak-shaving-status'
import { PeakShavingProgramSelector } from '../../components/selector/peak-shaving-program-selector'
import { usePeakShavingTranslation } from '../../hooks/translation/usePeakShavingTranslation'
import { PeakShavingProgramFilter } from '../../models/filter'
import { PaginationMeta } from '../../models/pagination'
import { PeakShavingEventState, PeakShavingProgram } from '../../models/peak-shaving'
import { GetAllPeakShavingPrograms } from '../../services/data-provider/peak-shaving'
import { CreateNewProgramModal } from './modal/create-new-program-modal'
import { styled } from '../../theme'
import { PeakShavingAggregatorNameSelector } from 'components/selector/peak-shaving-aggregator-name-selector'
import { useAppState } from 'state'

const PeakShavingEventsPageContainer = styled.div`
  .category {
    text-transform: capitalize;
  }

  .ant-table-thead > tr > th {
    font-size: 13px;
    line-height: 15.6px;
  }
  .ant-table-tbody > tr > td a {
    color: ${(props) => props.theme.colors.buttonPrimary};
  }
  .ant-table-tbody .ant-table-cell {
    line-height: 19px;
  }

  .ant-badge > sup {
    font-size: 11px;
  }
`

const PeakShavingProgramsBasePage: React.FC = () => {
  document.querySelector('body')?.classList.add('redesignActive', 'peak-shaving')
  const { isMockUpEnabled } = useAppState()

  const [peekShavingPrograms, setPeekShavingPrograms] = useState<PeakShavingProgram[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [createNewProgramModalVisible, setCreateNewProgramModalVisible] = useState(false)
  const [filter, setFilter] = useState<PeakShavingProgramFilter>({ page: 1, perPage: 10 })
  const [pagination, setPagination] = useState<PaginationMeta>()

  const [getAllPeakShavingProgramterm, setGetAllPeakShavingProgramTerm] = useState(_.uniqueId())

  const {
    idText,
    programNameText,
    aggregatorNameText,
    statusText,
    descriptionText,
    newProgramText,
  } = usePeakShavingTranslation()

  const columns = [
    {
      title: idText,
      dataIndex: 'id',
      width: '5%',
    },
    {
      title: programNameText,
      width: '20%',
      render: (program: PeakShavingProgram) => (
        <Link to={Links.peakShavingProgram({ programId: program.id })}>{program.name}</Link>
      ),
    },
    {
      title: aggregatorNameText,
      dataIndex: 'aggregatorName',
      width: '15%',
    },
    {
      title: descriptionText,
      dataIndex: 'initiative',
    },
    {
      title: statusText,
      dataIndex: 'state',
      render: (state: PeakShavingEventState) => <ProgramStatusBadge state={state} />,
      width: '15%',
    },
  ]

  useEffect(() => {
    setLoading(true)
    GetAllPeakShavingPrograms(isMockUpEnabled, filter)
      .then(({ data, pagination }) => {
        setPeekShavingPrograms(data)
        setPagination(pagination)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [getAllPeakShavingProgramterm, filter])

  const handleOnSubmit = () => {
    setGetAllPeakShavingProgramTerm(`${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`)
    setCreateNewProgramModalVisible(!createNewProgramModalVisible)
  }

  const onAggregatorSelect = (aggregatorName: string) => {
    setFilter({ ...filter, programId: undefined, page: 1, perPage: 10, aggregatorName })
  }

  const onAggregatorClear = () => {
    setFilter({ ...filter, page: 1, perPage: 10, aggregatorName: undefined })
  }

  const onPaginationChange = (page: number) => {
    setFilter({ ...filter, page })
  }

  const renderTitle = () => {
    return (
      <Row justify="space-between">
        <Col>
          <Space>
            <PeakShavingProgramSelector
              onProgramSelected={(program: PeakShavingProgram) => {
                const { id } = program
                setFilter({ ...filter, page: 1, perPage: 10, programId: id })
              }}
              onClear={() => {
                setFilter({ ...filter, page: 1, perPage: 10, programId: undefined })
              }}
            />
            <PeakShavingAggregatorNameSelector
              onSelect={onAggregatorSelect}
              onClear={onAggregatorClear}
              onEnter={onAggregatorClear}
              placeholder={aggregatorNameText}
              style={{ minWidth: '332px' }}
            />
          </Space>
        </Col>
        <Col>
          <Button
            key="new-program-btn"
            loading={loading}
            size="large"
            type="primary"
            disabled={loading}
            icon={plusSignSVG}
            onClick={() => setCreateNewProgramModalVisible(!createNewProgramModalVisible)}
            style={{ width: '157px' }}
          >
            <span className="regular-cap">{newProgramText}</span>
          </Button>
        </Col>
      </Row>
    )
  }

  return (
    <PeakShavingEventsPageContainer>
      {renderTitle()}
      <Box style={{ marginTop: '19px' }}>
        <Row>
          <Col span={24}>
            <AlertError error={error} />
            <Table
              columns={columns}
              dataSource={peekShavingPrograms}
              loading={loading}
              rowKey="id"
              pagination={{
                position: ['bottomCenter'],
                total: pagination?.totalEntries,
                current: pagination?.currentPage,
                pageSize: pagination?.perPage,
                showSizeChanger: false,
                onChange: onPaginationChange,
              }}
            />
          </Col>
        </Row>
      </Box>
      <CreateNewProgramModal
        visible={createNewProgramModalVisible}
        onCancel={() => setCreateNewProgramModalVisible(!createNewProgramModalVisible)}
        onSubmit={handleOnSubmit}
      />
    </PeakShavingEventsPageContainer>
  )
}

export const PeakShavingProgramsPage = withAuthenticatedLayout(PeakShavingProgramsBasePage)
