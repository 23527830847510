import { DiscountSchema } from 'models/discount'

export const mockDiscountSchema = [
  {
    id: 516,
    name: 'Local Loitering Discount 4#4 11047f',
    promoCode: 'lpc_4#4_62cf08',
    tenantId: 4,
    usageStatus: 'active',
    discountPeriodWeekday: [...Array(48).fill(1)],
    discountPeriodWeekend: [...Array(48).fill(1)],
    discountPercentageWeekday: [...Array(48).fill(0)],
    discountPercentageWeekend: [...Array(48).fill(0)],
    loiteringSuspensionPeriodWeekday: [...Array(48).fill(0)],
    loiteringSuspensionPeriodWeekend: [...Array(48).fill(0)],
    ignoreChargingFee: false,
    ignoreLoiteringFee: true,
    ignoreConstantFee: false,
    ignoreDemandResponseFee: false,
    ignoreGuestFee: false,
    type: null,
    users: [
      {
        userId: '_YUm-toI6aswbqZ5-gp4Lg',
        userName: 'Sam Test',
        userEmail: 'samtest@gmail.com',
        timeAdded: '2024-06-19T16:39:19.450-04:00',
      },
      {
        userId: '7_SfFp5X0-kKx620vmnk5A',
        userName: 'Weihang Tang',
        userEmail: 'xwei@swtchenergy.com',
        timeAdded: '2024-06-19T16:39:19.655-04:00',
      },
      {
        userId: 'kBOh0wFTIyqz0xRfrklPKA',
        userName: 'Reesha mart',
        userEmail: 'reesha.martis+sw12@swtchenergy.com',
        timeAdded: '2024-06-19T16:39:19.826-04:00',
      },
      {
        userId: 'kVFx2WDhJd_PeRGHGc9JRg',
        userName: 'reeshamartisus8',
        userEmail: 'reesha.martis+us8@swtchenergy.com',
        timeAdded: '2024-06-19T16:39:19.854-04:00',
      },
      {
        userId: 'USXmgzwxwLShWu9bYMfMaw',
        userName: 'Weihang Tang',
        userEmail: null,
        timeAdded: '2024-06-19T16:39:19.887-04:00',
      },
      {
        userId: 'sE2u2PVq1n2bUuHx--6QfA',
        userName: 'reesha test1',
        userEmail: 'reeshaswtch+t1@gmail.com',
        timeAdded: '2024-06-19T16:39:19.923-04:00',
      },
    ],
    listingGroupId: 649,
    appliedListings: [
      { listingId: 10, listingTitle: 'MP02' },
      { listingId: 45, listingTitle: 'MP06' },
      { listingId: 47, listingTitle: 'MP07' },
      { listingId: 59, listingTitle: 'MP09' },
      { listingId: 60, listingTitle: 'MP10' },
      { listingId: 61, listingTitle: 'FR02' },
      { listingId: 64, listingTitle: 'AC02' },
      { listingId: 134, listingTitle: 'TL05' },
      { listingId: 151, listingTitle: 'L6594' },
      { listingId: 156, listingTitle: 'testR202' },
      { listingId: 158, listingTitle: 'Test21Rb' },
      { listingId: 165, listingTitle: 'Test27d' },
      { listingId: 180, listingTitle: 'MIKE02' },
      { listingId: 201, listingTitle: 'FR05' },
      { listingId: 202, listingTitle: 'FR06' },
    ],
    creatorName: null,
    createdAt: '2024-06-19T16:39:19-04:00',
    updatedAt: '2024-06-28T10:51:16-04:00',
  },
  {
    id: 517,
    name: 'Local Loitering Discount 4#5 b4e849',
    promoCode: 'lpc_4#5_6af5a0',
    tenantId: 4,
    usageStatus: 'active',
    discountPeriodWeekday: [...Array(48).fill(1)],
    discountPeriodWeekend: [...Array(48).fill(1)],
    discountPercentageWeekday: [...Array(48).fill(0)],
    discountPercentageWeekend: [...Array(48).fill(0)],
    loiteringSuspensionPeriodWeekday: [...Array(48).fill(0)],
    loiteringSuspensionPeriodWeekend: [...Array(48).fill(0)],
    ignoreChargingFee: false,
    ignoreLoiteringFee: true,
    ignoreConstantFee: false,
    ignoreDemandResponseFee: false,
    ignoreGuestFee: false,
    type: null,
    users: [
      {
        userId: '_YUm-toI6aswbqZ5-gp4Lg',
        userName: 'Sam Test',
        userEmail: 'samtest@gmail.com',
        timeAdded: '2024-06-19T16:39:20.160-04:00',
      },
      {
        userId: '7_SfFp5X0-kKx620vmnk5A',
        userName: 'Weihang Tang',
        userEmail: 'xwei@swtchenergy.com',
        timeAdded: '2024-06-19T16:39:20.391-04:00',
      },
      {
        userId: 'kBOh0wFTIyqz0xRfrklPKA',
        userName: 'Reesha mart',
        userEmail: 'reesha.martis+sw12@swtchenergy.com',
        timeAdded: '2024-06-19T16:39:20.508-04:00',
      },
      {
        userId: 'kVFx2WDhJd_PeRGHGc9JRg',
        userName: 'reeshamartisus8',
        userEmail: 'reesha.martis+us8@swtchenergy.com',
        timeAdded: '2024-06-19T16:39:20.534-04:00',
      },
      {
        userId: 'USXmgzwxwLShWu9bYMfMaw',
        userName: 'Weihang Tang',
        userEmail: null,
        timeAdded: '2024-06-19T16:39:20.575-04:00',
      },
      {
        userId: 'sE2u2PVq1n2bUuHx--6QfA',
        userName: 'reesha test1',
        userEmail: 'reeshaswtch+t1@gmail.com',
        timeAdded: '2024-06-19T16:39:20.610-04:00',
      },
    ],
    listingGroupId: 550,
    appliedListings: [{ listingId: 11, listingTitle: 'MP03' }],
    creatorName: null,
    createdAt: '2024-06-19T16:39:19-04:00',
    updatedAt: '2024-06-19T16:39:19-04:00',
  },
] as DiscountSchema[]
