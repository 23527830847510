import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { magnifySVG } from '../../assets/svg/magnify'
import { usePeakShavingTranslation } from '../../hooks/translation/usePeakShavingTranslation'
import { SwtchError } from '../../models/error'
import { PeakShavingProgramFilter } from '../../models/filter'
import { PeakShavingProgram, PeakShavingProgramState } from '../../models/peak-shaving'
import { GetAllPeakShavingPrograms } from '../../services/data-provider/peak-shaving'
import { AlertError } from '../error'
import { SingleOptionContainer } from 'atom/select'
import { useAppState } from 'state'

interface props {
  onProgramSelected: (program: PeakShavingProgram) => void
  onClear: () => void
  defaultOption?: string
  disabled?: boolean
  state?: PeakShavingProgramState
}

const renderOption = (program: PeakShavingProgram): any => {
  return {
    value: program.id,
    program,
    label: program.name,
  }
}

const PeakShavingProgramSelectorContainer = styled.div`
  width: 100%;
  .ant-select-arrow svg {
    rotate: 180deg;
    transition: all 500ms ease;
  }
  .ant-select.ant-select-focused.ant-select-single.ant-select-allow-clear.ant-select-show-arrow.ant-select-show-search
    svg {
    rotate: 0deg;
  }

  .ant-tag {
    display: flex;
    background-color: ${(props) => props.theme.colors.cultured};
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    padding: 3px 10px;
  }
  .ant-tag-close-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-select .ant-select-selector .ant-select-selection-placeholder {
    font-size: 13px;
    line-height: 19px;
  }

  .ant-select-selector .ant-select-selection-item {
    left: 18px;
  }
`

export const PeakShavingProgramSelector: React.FC<props> = ({
  onProgramSelected,
  onClear,
  defaultOption,
  disabled,
  state,
}) => {
  const { isMockUpEnabled } = useAppState()
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)

  const [options, setOptions] = useState<SelectProps<object>['options']>([])
  const [filter, setFilter] = useState<PeakShavingProgramFilter>({ page: 1, perPage: 10, state: state })

  const { searchProgramsText } = usePeakShavingTranslation()

  useEffect(() => {
    setLoading(true)
    GetAllPeakShavingPrograms(isMockUpEnabled, filter)
      .then(({ data }) => {
        const opts = data.map((tenant) => {
          return renderOption(tenant)
        })
        setOptions(opts)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filter])

  const onSelect = (value: string) => {
    options?.forEach((opt) => {
      if (opt.value === value) {
        onProgramSelected(opt.program)
      }
    })
  }

  return (
    <SingleOptionContainer>
      <PeakShavingProgramSelectorContainer>
        <AlertError error={error} />
        <>
          {magnifySVG}
          <Select
            showSearch
            allowClear
            style={{ minWidth: '332px', width: '100%' }}
            placeholder={searchProgramsText}
            onClear={() => {
              setError(undefined)
              onClear()
            }}
            onSearch={debounce((value) => {
              setFilter({ ...filter, programName: value })
            }, 1000)}
            options={options}
            filterOption={(input, option) =>
              (option ? String(option.label) : '').toLowerCase().includes(input.toLowerCase())
            }
            dropdownMatchSelectWidth={false}
            onSelect={onSelect}
            loading={loading}
            defaultValue={defaultOption}
            disabled={disabled}
            suffixIcon={null}
          />
        </>
      </PeakShavingProgramSelectorContainer>
    </SingleOptionContainer>
  )
}
