import { Select, Spin } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { magnifyBlackSVG, magnifySVG } from 'assets/svg/magnify'
import { ButtonIcon } from 'atom/button'
import { ReusableSelectorContainer } from 'atom/select'
import { useSelectorTranslation } from 'hooks/translation/useSelectorTranslation'
import { DebouncedFunc } from 'lodash'
import { ActivityTrackingFilter, ChargersPerTenantFilter, TransactionFilter } from 'models/filter'
import { Dispatch, ReactElement, SetStateAction, useEffect, useRef, useState } from 'react'
import { useAppState } from 'state'

interface ReuseableSelectorProps {
  loading?: boolean
  disabled?: boolean
  showSearch: boolean
  isSingle?: boolean
  placeholder?: string
  dropDownList?: any[]
  showMagnifySVG: boolean
  combineType?: 'both' | 'right' | 'left'
  maxTagCount: number
  isDebounceFetcher: boolean
  isMockUpEnabled: boolean
  filter?: TransactionFilter | ActivityTrackingFilter
  dropdownClassName?: string
  noSearch?: boolean
  customWidth?: string
  customHeight?: string
  selectAllOptions?: boolean
  stopClearSearchValue?: boolean
  onOptionsChange?: (options: any[] | any) => void
  onClear?: () => void
  handlePlaceholder?: (selectedOptions: any[]) => void
  handleOptionChange: (
    value: any | any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedOptions: any[]) => void,
    options: any[],
  ) => void
  debounceFetcher?:
    | DebouncedFunc<
        (
          value: string,
          setValues: Dispatch<SetStateAction<any[]>>,
          setFetching: (fetching: boolean) => void,
          isMockUpEnabled: boolean,
          setValueOptions?: Dispatch<SetStateAction<any[]>>,
          defaultTenants?: any[],
        ) => void
      >
    | undefined
  reset?: boolean
  handleValue?: (filter: TransactionFilter | ChargersPerTenantFilter | any) => any | undefined
  renderOption: (
    option: any,
    selectedOptions: any | any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    filter?: TransactionFilter | ChargersPerTenantFilter | any,
  ) => ReactElement
  searchData?: () => void
  defaultValues?: any[]
  clearSearchAfterSelect?: boolean
  showArrow?: boolean
  fieldForValue?: string
  dropdownRender?: (
    menu: ReactElement<any, string | React.JSXElementConstructor<any>>,
  ) => ReactElement<any, string | React.JSXElementConstructor<any>>
  oneTimeUpdateDefaultValues?: boolean
  searchButtonMerged?: boolean
  placeholderWidth?: string
  defaultTenants?: any[]
  nativeSearch?: boolean
  allowClear?: boolean
  suffixIcon?: React.ReactNode
  preload?: boolean
}

export const ReusableSelector: React.FC<ReuseableSelectorProps> = ({
  loading,
  disabled,
  showSearch,
  isSingle = false,
  placeholder,
  dropDownList,
  showMagnifySVG,
  combineType,
  maxTagCount,
  isDebounceFetcher,
  debounceFetcher,
  filter,
  dropdownClassName,
  noSearch,
  customWidth,
  customHeight,
  reset,
  selectAllOptions,
  onClear = () => {},
  handlePlaceholder,
  onOptionsChange,
  handleOptionChange,
  handleValue,
  renderOption,
  searchData,
  defaultValues = [],
  clearSearchAfterSelect = false,
  showArrow = true,
  fieldForValue,
  dropdownRender,
  oneTimeUpdateDefaultValues = false,
  searchButtonMerged = false,
  placeholderWidth,
  defaultTenants = [],
  nativeSearch = false,
  stopClearSearchValue = false,
  allowClear = true,
  suffixIcon,
  preload = false,
  isMockUpEnabled = false,
}) => {
  const { IsDesktop, IsLaptop, IsMobile, IsTablet } = useAppState()
  const { startTypingText } = useSelectorTranslation()
  const [selectedOptions, setSelectedOptions] = useState<any[]>(defaultValues)
  const [values, setValues] = useState<any>(dropDownList ?? [])
  const [valueOptions, setValueOptions] = useState(dropDownList ?? [])
  const [fetching, setFetching] = useState(false)
  const [dirty, setDirty] = useState(false)
  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()
  const isMobile = IsMobile()
  const isTablet = IsTablet()

  //For single search
  const [searchValue, setSearchValue] = useState('')
  const [allowSearch, setAllowSearch] = useState(true)
  const [selectedValue, setSelectedValue] = useState('')

  const [defaultValueUpdated, setDefaultValueUpdated] = useState(oneTimeUpdateDefaultValues)

  //For input width when there is tag
  const containerRef = useRef(null)
  const [inputWidth, setInputWidth] = useState('100%')
  useEffect(() => {
    if (reset) {
      handleOnClear()
    }
  }, [reset])

  useEffect(() => {
    if (dropDownList?.length) {
      setValues(dropDownList)
      setValueOptions(dropDownList)
    }
  }, [dropDownList])

  useEffect(() => {
    if (!defaultValueUpdated) {
      if (defaultValues.length && selectAllOptions) {
        setSelectedOptions(defaultValues)
        if (onOptionsChange) onOptionsChange(defaultValues)
      } else if (defaultValues.length && isSingle) {
        setSelectedValue(defaultValues[0])
        setSearchValue(defaultValues[0])
      }
      if (oneTimeUpdateDefaultValues) {
        setDefaultValueUpdated(true)
      }
    }
  }, [defaultValues, defaultValueUpdated])

  useEffect(() => {
    if (preload && isDebounceFetcher && debounceFetcher && !disabled) {
      debounceFetcher('', setValues, setFetching, isMockUpEnabled, setValueOptions, defaultTenants)
    }
  }, [preload, disabled])

  const handleChange = (value: string | string[], option: any) => {
    setDirty(true)
    if (isSingle) {
      if (!noSearch) {
        setSearchValue(value as string)
        setSelectedValue(value as string)
      }
      if (clearSearchAfterSelect) {
        setSearchValue('')
      }
      setAllowSearch(false)
    }
    handleOptionChange(value, setDirty, setSelectedOptions, onOptionsChange ? onOptionsChange : () => {}, valueOptions)
  }

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isSingle) {
      setDirty(false)
      e.stopPropagation()
    }
  }

  const handleCheckboxClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (dirty) {
      e.stopPropagation()
    }
    setDirty(false)
  }

  const handleSearch = (value: any) => {
    if (allowSearch) {
      if (noSearch && value) {
        setSearchValue(value)
        setSelectedValue(value)
      } else if (!noSearch) {
        setSearchValue(value)
        setSelectedValue('')
        if (isSingle && !value && !stopClearSearchValue) {
          handleOptionChange(value, setDirty, setSelectedOptions, () => {}, [])
        }
      }
    }
    if (!noSearch) setAllowSearch(true)
    if (isDebounceFetcher && debounceFetcher) {
      debounceFetcher(value, setValues, setFetching, isMockUpEnabled, setValueOptions, defaultTenants)
    } else if (!nativeSearch) {
      const filteredOptions = dropDownList?.filter((option) => option.label.toLowerCase().includes(value.toLowerCase()))
      setValues(filteredOptions)
    }
  }

  const renderOptionsDropdown = (options: any[]) => {
    if (isSingle) {
      const value = searchValue
      if (isDebounceFetcher) {
        return options
          .map((v) => renderOption(v, selectedValue, handleCheckboxClick))
          .sort((a, b) => sortOptionsDropdown(a, b))
      } else if (showSearch) {
        return options?.map((v, index) => renderOption({ state: v, index }, value, handleCheckboxClick))
      }
      return dropDownList?.map((v, index) => renderOption({ state: v, index }, value, handleCheckboxClick, filter))
    } else {
      const optionsList = [
        ...selectedOptions,
        ...options.filter(
          (value) => !selectedOptions.some((option) => (option.id ? option.id === value.id : option === value)),
        ),
      ]
      return optionsList
        .map((v) => renderOption(v, selectedOptions, handleCheckboxClick))
        .sort((a, b) => sortOptionsDropdown(a, b))
    }
  }

  const sortOptionsDropdown = (a: ReactElement, b: ReactElement) => {
    if (isSingle) {
      return 1
    }
    return b.props.children.props.checked - a.props.children.props.checked
  }

  const handleOnClear = () => {
    setSelectedOptions([])
    setSearchValue('')
    setAllowSearch(false)
    setSelectedValue('')
    onClear()
  }

  //To keep the selected option visible
  const filterOption = (input: string, option: DefaultOptionType | undefined) => {
    if (selectedOptions.some((selectedOption) => `${selectedOption.id}` === option?.key)) {
      return true
    }
    return option?.label && typeof option?.label === 'number'
      ? true
      : (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  const canCombineBorder = (isDesktop || isLaptop) && combineType === 'both'
  const canCombineRightBorder = (isDesktop || isLaptop) && combineType === 'right'
  const canCombineLefttBorder = (isDesktop || isLaptop) && combineType === 'left'

  const renderValue = () => {
    if (handleValue && filter) {
      return handleValue(filter)
    } else if (handleValue && defaultValues) {
      return handleValue(selectedOptions)
    } else if (!isSingle && defaultValues) {
      //Use this if selectedOptions is not an array of string
      if (fieldForValue) {
        return selectedOptions.map((option) => option[fieldForValue])
      } else {
        return selectedOptions
      }
    } else if (isSingle && isDebounceFetcher) {
      return selectedValue?.length > 0 ? selectedValue : undefined
    } else {
      return undefined
    }
  }

  //Calculate input width when there is tag to prevent overflowing
  useEffect(() => {
    if (containerRef.current) {
      const container = (containerRef.current as any).querySelector('.ant-select-selection-overflow')
      const selectionItems = (containerRef.current as any).querySelectorAll('.ant-select-selection-overflow-item')[0]

      if (container && selectionItems) {
        const containerWidth = (container as any).offsetWidth
        const placeholderWidth = (selectionItems as any).offsetWidth
        const newInputWidth = containerWidth - placeholderWidth - 10
        setInputWidth(newInputWidth > 0 ? `${newInputWidth}px` : '100%')
      }
    }
  }, [selectedOptions, fetching, loading])

  return (
    <ReusableSelectorContainer
      ref={containerRef}
      isCombine={canCombineBorder}
      isCombineRight={canCombineRightBorder}
      isCombineLeft={canCombineLefttBorder}
      removeSVG={!showMagnifySVG}
      customWidth={customWidth}
      customHeight={customHeight}
      isMobileOrTablet={isMobile || isTablet}
      suffix={!!searchData || searchButtonMerged}
      inputWidth={inputWidth}
      placeholderWidth={placeholderWidth}
    >
      {showMagnifySVG && magnifySVG}
      <Select
        showSearch={showSearch}
        allowClear={allowClear}
        disabled={loading || disabled}
        loading={fetching}
        suffixIcon={
          suffixIcon ? (
            suffixIcon
          ) : searchData ? (
            <ButtonIcon
              icon={magnifyBlackSVG}
              loading={loading ? loading : false}
              disabled={isSingle ? !selectedValue : loading ? loading : false}
              fetchData={searchData}
              withInSuffix
              reusableSelectorSuffix
            />
          ) : noSearch || showArrow ? undefined : null
        }
        dropdownClassName={dropdownClassName}
        mode={isSingle ? undefined : 'multiple'}
        showArrow={showArrow}
        onClear={handleOnClear}
        onChange={handleChange}
        dropdownMatchSelectWidth={false}
        style={{ minWidth: 140, width: '100%' }}
        maxTagCount={maxTagCount}
        maxTagPlaceholder={() => handlePlaceholder && handlePlaceholder(selectedOptions)}
        placeholder={placeholder}
        onSearch={handleSearch}
        onMouseDown={(e) => handleMouseDown(e)}
        defaultValue={defaultValues}
        notFoundContent={fetching ? <Spin size="small" /> : showSearch ? startTypingText : undefined}
        value={renderValue()}
        searchValue={isSingle ? searchValue : undefined}
        filterOption={!isSingle && filterOption}
        dropdownRender={dropdownRender}
      >
        {renderOptionsDropdown(values)}
      </Select>
    </ReusableSelectorContainer>
  )
}
