import { useFormatMessage } from '../../helpers/intl'

export const useDashboardTranslation = () => {
  const dayText = useFormatMessage('dashboard.homePage.consumptionInsights.avgNumberOfTransactions.day', 'day')
  const stationOverviewText = useFormatMessage('dashboard.homePage.stationoverview.heading', 'Station Overview')
  const operationOverviewText = useFormatMessage('dashboard.homePage.operationoverview.heading', 'Operation Overview')
  const activeUsersText = useFormatMessage('dashboard.homePage.stationoverview.activeUsers', 'Active Users')
  const chargersPluggedInText = useFormatMessage(
    'dashboard.homePage.stationoverview.connectorsPluggedIn',
    'Connectors Plugged In',
  )
  const chargersEnrolledInDemandResponseText = useFormatMessage(
    'dashboard.homePage.stationoverview.chargersParcipatingDREvents.heading',
    'Chargers Enrolled in Demand Response',
  )
  const chargersAvailabilityText = useFormatMessage(
    'dashboard.homePage.stationoverview.connectorAvailability',
    'Connector Availability',
  )
  const utilizationInsightsText = useFormatMessage(
    'dashboard.homePage.utilizationInsights.heading',
    'Utilization Insights',
  )
  const numberOfConnectorsPluggedInText = useFormatMessage(
    'dashboard.homePage.utilizationInsights.numberOfConnectorsPluggedIn',
    '# of Chargers In Use',
  )
  const consumptionInsightsText = useFormatMessage(
    'dashboard.homePage.consumptionInsights.heading',
    'Consumption Insights',
  )
  const averageDailyLoadProfileText = useFormatMessage(
    'dashboard.homePage.consumptionInsights.chart.title',
    'Average Daily Load Profile',
  )
  const uniqueUsersText = useFormatMessage('dashboard.homePage.consumptionInsights.uniqueUsers.heading', 'Unique users')
  const averageNumberOfTransactionsText = useFormatMessage(
    'dashboard.homePage.consumptionInsights.avgNumberOfTransactions.heading',
    'Average # of transactions',
  )
  const stationActivationText = useFormatMessage('dashboard.homePage.stationActivation.heading', 'Station Activation')
  const transactionOverviewText = useFormatMessage(
    'dashboard.homePage.transactionOverview.heading',
    'Transaction Overview',
  )
  const seeAllTransactionsText = useFormatMessage(
    'dashboard.homePage.seeAllTransactions.heading',
    'See All Transactions',
  )
  const chargingText = useFormatMessage('dashboard.homePage.chargingText', 'Charging')
  const idleText = useFormatMessage('dashboard.homePage.stationoverview.activeUsers.idle', 'Idle')
  const transactionOngoingText = useFormatMessage(
    'dashboard.homePage.stationOverview.activeUsers.idle.info',
    'The transaction is ongoing, but the vehicle is not actively charging.',
  )
  const levelTwoConnectorsText = useFormatMessage(
    'dashboard.homePage.stationoverview.connectorsPluggedIn.level2connectors',
    'Level 2',
  )
  const dcFastConnectorsText = useFormatMessage(
    'dashboard.homePage.stationoverview.connectorsPluggedIn.dcfastconnectors',
    'DC Fast',
  )
  const stationsText = useFormatMessage('dashboard.homePage.stationoverview.stations.heading', 'Stations')
  const currentText = useFormatMessage('dashboard.homePage.stationoverview.current.heading', 'Current')
  const allText = useFormatMessage('dashboard.text.all', 'All')
  const weekdayText = useFormatMessage('dashboard.text.weekday', 'Weekday')
  const weekendText = useFormatMessage('dashboard.text.weekend', 'Weekend')
  const levelTwoText = useFormatMessage('dashboard.text.level2', 'Level 2')
  const averageEnergyConsumptionText = useFormatMessage(
    'dashboard.homePage.consumptionInsights.averageConsumption.heading',
    'Average consumption',
  )
  const averageTransactionAmountText = useFormatMessage(
    'dashboard.homePage.consumptionInsights.averageConsumption.averageUserSpend',
    'Average user spend',
  )
  const onlineChargers = useFormatMessage(
    'dashboard.homePage.stationoverview.stations.onlinechargers.text',
    'Online Connectors',
  )
  const offlineChargers = useFormatMessage(
    'dashboard.homePage.stationoverview.stations.offlinechargers.text',
    'Offline Connectors',
  )
  const underRepairChargers = useFormatMessage(
    'dashboard.homePage.stationoverview.stations.underrepairchargers.text',
    'Under Repair',
  )
  const onlineChargersDescription = useFormatMessage(
    'dashboard.homePage.stationoverview.stations.onlinechargers.description',
    'Functional and connection established allowing for remote monitoring and management',
  )
  const offlineChargersDescription = useFormatMessage(
    'dashboard.homePage.stationoverview.stations.offlinechargers.description',
    'Unable to establish connection and may require further troubleshooting or escalation',
  )
  const underRepairChargersDescription = useFormatMessage(
    'dashboard.homePage.stationoverview.stations.underrepairchargers.description',
    'Currently out of service due to maintenance to restore functionality',
  )
  const realTimeDataText = useFormatMessage('dashboard.heading.realTimeData', 'Real Time Data')
  const historicalDataText = useFormatMessage('dashboard.heading.historicalData', 'Historical Data')

  return {
    dayText,
    stationOverviewText,
    operationOverviewText,
    activeUsersText,
    chargersPluggedInText,
    chargersEnrolledInDemandResponseText,
    chargersAvailabilityText,
    utilizationInsightsText,
    numberOfConnectorsPluggedInText,
    consumptionInsightsText,
    averageDailyLoadProfileText,
    uniqueUsersText,
    averageNumberOfTransactionsText,
    stationActivationText,
    transactionOverviewText,
    seeAllTransactionsText,
    chargingText,
    idleText,
    transactionOngoingText,
    levelTwoConnectorsText,
    dcFastConnectorsText,
    stationsText,
    currentText,
    allText,
    weekdayText,
    weekendText,
    levelTwoText,
    averageEnergyConsumptionText,
    averageTransactionAmountText,
    onlineChargers,
    offlineChargers,
    underRepairChargers,
    onlineChargersDescription,
    offlineChargersDescription,
    underRepairChargersDescription,
    realTimeDataText,
    historicalDataText,
  }
}
