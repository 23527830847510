export const mockWeeklySchedules = [
  {
    dayOfWeek: 0,
    endHour: '23:45:00',
    startHour: '00:00:00',
  },
  {
    dayOfWeek: 1,
    endHour: '23:45:00',
    startHour: '00:00:00',
  },
  {
    dayOfWeek: 2,
    endHour: '23:45:00',
    startHour: '00:00:00',
  },
  {
    dayOfWeek: 2,
    endHour: '23:45:00',
    startHour: '00:00:00',
  },
  {
    dayOfWeek: 3,
    endHour: '23:45:00',
    startHour: '00:00:00',
  },
  {
    dayOfWeek: 4,
    endHour: '23:45:00',
    startHour: '00:00:00',
  },
  {
    dayOfWeek: 5,
    endHour: '23:45:00',
    startHour: '00:00:00',
  },
  {
    dayOfWeek: 6,
    endHour: '23:45:00',
    startHour: '00:00:00',
  },
]
