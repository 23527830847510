import { Typography } from 'antd'

import NoDataBadge from 'atom/Badge'
import { Transaction } from 'models/transaction'

import { DateTimeV2 } from 'components/date-time'
import { AlignedContentRight } from 'components/wrapper'
import UserName from 'components/title/TitleAndSubtitle'
import { TransactionStateBadge } from 'components/transactions/transaction-state-badge'

import { useTransactionSearchTranslation } from 'hooks/translation/useTransactionSearchTranslation'
import { useTransactionMetricTranslation } from 'hooks/translation/useTransactionMetricTranslation'
import { useUserTranslation } from 'hooks/translation/useUserTranslation'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'

import { formatEnergy, formatPrice } from 'helpers/number-format'
import { formatDurationWithTwoDigits } from 'helpers/duration'
import configProvider from 'config'
import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { useSelector } from 'hooks/useSelector'

export const TransactionsColumns = (showPanel: boolean) => {
  const { chargingDurationText, connectedText, disconnectedText, tenantText } = useGeneralTranslation()
  const { userText } = useUserTranslation()
  const { listingTitleText } = useListingBasicTrans()
  const { stateText } = useTransactionSearchTranslation()
  const { totalEnergyText } = useTransactionMetricTranslation()
  const { siteLabelCleaner } = useSelector()

  const columns = [
    { title: 'ID', dataIndex: 'id' },
    ...(configProvider.config.network === 'itsElectric'
      ? []
      : [
          {
            title: userText,
            key: 'user_name',
            render: (transaction: Transaction) => <UserName transaction={transaction} />,
          },
        ]),
    {
      title: tenantText,
      key: 'tenant',
      render: ({ tenant: { combineName } }: Transaction) => (
        <Typography.Text style={{ width: 250 }} ellipsis={{ tooltip: siteLabelCleaner(combineName) }}>
          {siteLabelCleaner(combineName)}
        </Typography.Text>
      ),
    },
    { title: listingTitleText, key: 'listing', render: ({ listing: { title } }: Transaction) => title },
    {
      title: connectedText,
      key: 'connected',
      align: 'right' as 'right',
      render: ({ startAt, timezone }: Transaction) => <DateTimeV2 date={startAt} timezone={timezone} />,
    },
    {
      title: disconnectedText,
      key: 'disconnected',
      align: 'right' as 'right',
      render: ({ endAt, timezone }: Transaction) => {
        if (!endAt) {
          return '-'
        }
        return endAt ? <DateTimeV2 date={endAt} timezone={timezone} /> : <NoDataBadge noBackground />
      },
    },
    {
      title: chargingDurationText,
      key: 'current_charging_duration',
      align: 'right' as 'right',
      render: (transaction: Transaction) => {
        if (transaction.currentChargingDuration === null || transaction.currentChargingDuration === undefined) {
          return '-'
        }
        return <AlignedContentRight children={formatDurationWithTwoDigits(transaction.currentChargingDuration)} />
      },
    },
    {
      title: totalEnergyText,
      key: 'energy',
      align: 'right' as 'right',
      render: (transaction: Transaction) => {
        if (transaction.energy === null || transaction.energy === undefined) {
          return '-'
        }
        return <AlignedContentRight children={`${formatEnergy(transaction.energy / 1000)} kWh`} />
      },
    },
  ]

  if (!showPanel) {
    columns.push({
      title: 'Total',
      key: 'total',
      align: 'right' as 'right',
      render: ({ financial: { total, currency } }: Transaction) => (
        <AlignedContentRight
          children={
            <>
              <span>{formatPrice(total / 100)}</span>
              <span className="opacity-06 paragraph-04-light">{currency.toUpperCase()}</span>
            </>
          }
        />
      ),
    })
  }
  columns.push({
    title: stateText,
    key: 'currentState',
    render: (trx: Transaction) => (
      <div className="flex-and-gap-below">
        <TransactionStateBadge transaction={trx} />
      </div>
    ),
  })

  return columns
}
