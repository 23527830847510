import { theme } from '../../theme'

export const checkmarkSVG = (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 5.5L7.04217 13.5L4.5 10.773"
      stroke={theme.colors.iconPrimary}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const checkmarkCircleSVG = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon/CheckmarkCircleFilled">
      <path
        id="Subtract"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM16.7091 9.70511C17.0985 9.31349 17.0967 8.68032 16.7051 8.2909C16.3135 7.90148 15.6803 7.90327 15.2909 8.29489L10.0337 13.5818L8.7091 12.2497C8.31968 11.858 7.68651 11.8562 7.29489 12.2457C6.90327 12.6351 6.90148 13.2683 7.2909 13.6599L9.32464 15.7051C9.51233 15.8939 9.76754 16 10.0337 16C10.2999 16 10.5551 15.8939 10.7428 15.7051L16.7091 9.70511Z"
        fill={theme.colors.primary}
      />
    </g>
  </svg>
)
