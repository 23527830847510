import { Table } from 'antd'

import { TenantsColumns } from './table/TenantsColumns'
import { PaginationMeta } from 'models/pagination'
import { TenantRef } from 'models/tenant'
import { useState } from 'react'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'

interface TenantsTableProps {
  loading: boolean
  tenants: TenantRef[]
  pagination?: PaginationMeta
  onPaginationChange: (page: number) => void
}

const TenantsTable: React.FC<TenantsTableProps> = ({ loading, tenants, pagination, onPaginationChange }) => {
  const [expandableRowKeys, setExpandableRowKeys] = useState<{ [dataKey: string]: boolean }>({})

  const onExpand = (expanded: boolean, record: TenantRef) => {
    setExpandableRowKeys({ ...expandableRowKeys, [record.id]: expanded })
  }

  const rowExpandable = (record: TenantRef) => {
    if (Object.keys(expandableRowKeys).includes(`${record.id}`)) return true
    const rowElement = document.querySelector(`[data-row-key="${record.id}"]`)
    const currentRowHeight = (rowElement as HTMLElement)?.offsetHeight - 16 * 2 //considered padding
    const childElements = rowElement?.querySelectorAll('.custom-row-collapse > td > *')

    let expandable = false

    childElements?.forEach((el: Element) => {
      expandable = expandable || el.scrollHeight > currentRowHeight
    })

    return expandable
  }

  return (
    <Table
      sticky
      loading={loading}
      dataSource={tenants}
      rowKey={(record) => record.id}
      columns={TenantsColumns()}
      className="tenants-table"
      expandable={{
        onExpand,
        expandedRowRender: () => <></>,
        rowExpandable,
        expandIcon: ({ expandable, expanded, record, onExpand }) => {
          return expandable ? (
            expanded ? (
              <MinusCircleOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <PlusCircleOutlined onClick={(e) => onExpand(record, e)} />
            )
          ) : undefined
        },
      }}
      pagination={{
        position: ['bottomCenter'],
        total: pagination?.totalEntries,
        current: pagination?.currentPage,
        pageSize: pagination?.perPage,
        showSizeChanger: false,
        onChange: onPaginationChange,
      }}
      rowClassName={(record) => (expandableRowKeys[record.id] ? '' : 'custom-row-collapse')}
    />
  )
}

export default TenantsTable
