import React from 'react'

import { Button, Col, DatePicker, Form, Row } from 'antd'
import { BinSVG } from 'assets/svg/bin'
import { datePickerCalendarSVG } from 'assets/svg/calendar'
import { PeakShavingAddIconSVG } from 'assets/svg/peak-shaving-add-icon'
import { PeakShavingSelectArrowDown } from 'assets/svg/select'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { useDemandManagementEventDetail } from 'hooks/useDemandManagementEventDetail'
import { useSelector } from 'hooks/useSelector'
import moment from 'moment'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { styled } from '../../../theme'
import { PeakShavingEvent } from 'models/peak-shaving'
import { useAppState } from 'state'

interface props {
  initialCurtailments: {
    curtailmentPercent: undefined
    from: undefined
    to: undefined
  }
  initialValues?: PeakShavingEvent
  formInitialValues: any
  updatedValues: any
  curtailmentsUpdate: (index: number, field: string) => ({ value }: { value: any }) => void
  validateTimeRange: (index: number) => (_: any, value: any) => Promise<void>
}

const PlusBtnContainer = styled.div`
  margin-top: 5px;
  margin-left: 5px;

  .ant-btn {
    width: 25.05px;
    height: 25.05px;
    padding: 0px;
    position: relative;
    border-radius: 50%;
  }
`

const DeleteBtnContainer = styled.div`
  svg {
    margin-top: 10px;
    margin-left: 5px;
    cursor: pointer;
  }
`

const CurtailmentsContainer = styled.div`
  .ant-col-xl-12 {
    max-width: 100%;
  }
  .ant-col-md-offset-2 {
    margin-left: 0;
  }
  .ant-form-item-control-input {
    min-width: unset;
  }

  .ant-select {
    min-width: unset !important;
  }
`

export const CurtailmentsDetailForm: React.FC<props> = ({
  initialCurtailments,
  formInitialValues,
  initialValues,
  updatedValues,
  curtailmentsUpdate,
  validateTimeRange,
}) => {
  const {
    eventDateText,
    eventScheduleText,
    startTimeText,
    endTimeText,
    isRquiredText,
    selectDateText,
    curtailmentText,
    toText,
  } = usePeakShavingTranslation()

  const { isMockUpEnabled } = useAppState()

  const { handleSingleSelectChange, renderSingleOption } = useSelector()

  const { eventSchedules, timeFormat, curtailmentPercent } = useDemandManagementEventDetail(
    () => {},
    () => {},
  )

  return (
    <Col span={12} offset={2}>
      <Row>
        <p
          className="paragraph-02-regular"
          style={{ marginTop: '7px', fontWeight: 700, fontSize: '14px', lineHeight: '16.8px' }}
        >
          {eventScheduleText}
        </p>
      </Row>
      <Form.Item
        label={<p className="paragraph-02-regular">{eventDateText}</p>}
        name="date"
        className="eventDate"
        rules={[{ required: true, message: isRquiredText.replace('-1', eventDateText) }]}
        valuePropName="date"
        labelAlign="left"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
      >
        <DatePicker
          placement="bottomLeft"
          placeholder={selectDateText}
          disabled={!!initialValues}
          defaultValue={
            formInitialValues
              ? moment(formInitialValues?.date) //event.date
              : moment()
          }
          //disabled={isDatePickerDisabled() || (event && !isPeakShavingFormEnabled(event))}
          suffixIcon={datePickerCalendarSVG}
          disabledDate={(current) => current.isBefore(moment().subtract(1, 'day'))}
        />
      </Form.Item>
      <Row style={{ marginTop: '30px', marginBottom: '10px' }}>
        <Col span={8}>
          <p className="paragraph-02-regular" style={{ fontWeight: 700, fontSize: '12px' }}>
            {curtailmentText}
          </p>
        </Col>
        <Col span={6} offset={2}>
          <p className="paragraph-02-regular" style={{ fontWeight: 700, fontSize: '12px' }}>
            {startTimeText}
          </p>
        </Col>
        <Col span={6} offset={2}>
          <p className="paragraph-02-regular" style={{ fontWeight: 700, fontSize: '12px' }}>
            {endTimeText}
          </p>
        </Col>
      </Row>
      <CurtailmentsContainer>
        <Form.List name="curtailments">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row key={field.key}>
                  <Col span={8}>
                    <Form.Item
                      name={[field.name, 'curtailmentPercent']}
                      rules={[{ required: true, message: isRquiredText.replace('-1', curtailmentText) }]}
                    >
                      <ReusableSelector
                        onOptionsChange={curtailmentsUpdate(index, 'curtailmentPercent')}
                        showSearch={false}
                        noSearch
                        showArrow
                        allowClear={false}
                        defaultValues={
                          updatedValues?.curtailments?.[index]?.curtailmentPercent
                            ? [updatedValues?.curtailments?.[index].curtailmentPercent]
                            : undefined
                        }
                        oneTimeUpdateDefaultValues
                        dropDownList={curtailmentPercent}
                        isSingle
                        suffixIcon={<PeakShavingSelectArrowDown />}
                        placeholder="Curtailment"
                        showMagnifySVG={false}
                        isDebounceFetcher={false}
                        handleOptionChange={handleSingleSelectChange('value')}
                        maxTagCount={0}
                        renderOption={renderSingleOption({ val: 'value', label: 'label' })}
                        isMockUpEnabled={isMockUpEnabled}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} offset={2}>
                    <Row>
                      <Form.Item
                        style={{ maxWidth: '75%' }}
                        name={[field.name, 'from']}
                        rules={[
                          {
                            required: true,
                            message: isRquiredText.replace('-1', startTimeText),
                          },
                          {
                            validator: validateTimeRange(index),
                          },
                        ]}
                        validateTrigger={['onChange', 'onSelect']}
                      >
                        <ReusableSelector
                          onOptionsChange={curtailmentsUpdate(index, 'from')}
                          showSearch={false}
                          noSearch
                          showArrow
                          allowClear={false}
                          customWidth={'110px'}
                          defaultValues={
                            updatedValues?.curtailments?.[index]?.from
                              ? [updatedValues?.curtailments?.[index].from]
                              : undefined
                          }
                          dropDownList={eventSchedules.map((time) => ({
                            value: time,
                            label: timeFormat(time),
                          }))}
                          oneTimeUpdateDefaultValues
                          isSingle
                          suffixIcon={<PeakShavingSelectArrowDown />}
                          placeholder={'From'}
                          showMagnifySVG={false}
                          isDebounceFetcher={false}
                          handleOptionChange={handleSingleSelectChange('value')}
                          maxTagCount={0}
                          renderOption={renderSingleOption({ val: 'value', label: 'label' })}
                          isMockUpEnabled={isMockUpEnabled}
                        />
                      </Form.Item>

                      <p className="paragraph-04-light" style={{ marginTop: '11px', marginLeft: '10px' }}>
                        {toText}
                      </p>
                    </Row>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      name={[field.name, 'to']}
                      rules={[
                        {
                          required: true,
                          message: isRquiredText.replace('-1', endTimeText),
                        },
                        {
                          validator: validateTimeRange(index),
                        },
                      ]}
                      validateTrigger={['onChange', 'onSelect']}
                    >
                      <ReusableSelector
                        onOptionsChange={curtailmentsUpdate(index, 'to')}
                        showSearch={false}
                        noSearch
                        showArrow
                        allowClear={false}
                        customWidth={'110px'}
                        defaultValues={
                          updatedValues?.curtailments?.[index]?.to
                            ? [updatedValues?.curtailments?.[index].to]
                            : undefined
                        }
                        dropDownList={eventSchedules.map((time) => ({
                          value: time + 1,
                          label: timeFormat(time + 1),
                          disabled: updatedValues?.curtailments?.[index]?.from
                            ? time < updatedValues?.curtailments?.[index]?.from
                            : false,
                        }))}
                        oneTimeUpdateDefaultValues
                        isSingle
                        suffixIcon={<PeakShavingSelectArrowDown />}
                        placeholder={toText}
                        showMagnifySVG={false}
                        isDebounceFetcher={false}
                        handleOptionChange={handleSingleSelectChange('value')}
                        maxTagCount={0}
                        renderOption={renderSingleOption({ val: 'value', label: 'label' })}
                        isMockUpEnabled={isMockUpEnabled}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1} offset={1}>
                    {fields.length > 1 ? (
                      <DeleteBtnContainer>
                        <BinSVG
                          onClick={() => {
                            remove(field.name)
                          }}
                        />
                      </DeleteBtnContainer>
                    ) : null}
                  </Col>
                </Row>
              ))}
              <PlusBtnContainer>
                <Button
                  type="primary"
                  onClick={() => {
                    add(initialCurtailments)
                  }}
                  className="add-btn"
                  icon={<PeakShavingAddIconSVG style={{ position: 'absolute' }} />}
                />
              </PlusBtnContainer>
            </>
          )}
        </Form.List>
      </CurtailmentsContainer>
    </Col>
  )
}
