import { UserFilter } from '../../models/filter'

export const usersQuery = (f: UserFilter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {}
  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.tenant && f.tenant.length > 0) {
    queryParams['tenant_id'] = `${f.tenant.map((t) => t.id)}`
  }

  if (f.defaultTenant) {
    queryParams['default_tenant_id'] = `${f.defaultTenant.id}`
  }

  if (f.term) {
    queryParams['term'] = f.term
  }

  return queryParams
}
