import { ConfigProvider, Table } from 'antd'
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'

import { Transactionv2 } from '../../../../models/transaction'
import { EmptyData } from '../../../../components/redesign/emptyData'
import { renderFormatMessage } from '../../../../helpers/intl'
import { styled } from '../../../../theme'
import TransactionOverviewCollapse from './transaction-overview-collapse'
import configProvider from 'config'

const TransactionTableUserNameWrapper = styled.div`
  span {
    display: block;
    text-transform: capitalize;
  }
  span:first-of-type {
    margin-bottom: 4px;
  }

  span:nth-of-type(2) {
    opacity: 0.6;
  }
`

const renderState = (state: string) => {
  let stateClass = undefined
  switch (state.toLowerCase()) {
    case 'completed':
      stateClass = 'completed'
      break
    case 'free':
      stateClass = 'free'
      break
    case 'confirmed':
      stateClass = 'confirmed'
      break
    case 'no-reservation':
      stateClass = 'no-reservation'
      break
    case 'cancelled':
      stateClass = 'cancelled'
      break
    case 'errored':
      stateClass = 'errored'
      break
    case 'paid':
      stateClass = 'paid'
      break
    case 'preauthorized':
      stateClass = 'preauthorized'
      break
    case 'pending_payment':
      stateClass = 'pending_payment'
      break
    case 'checked_in':
      stateClass = 'checked_in'
      break
  }

  return stateClass
}

const renderStateText = (state: string) => {
  let text = undefined

  switch (state.toLowerCase()) {
    case 'completed':
      text = renderFormatMessage('dashboard.transaction.completed', 'Completed')
      break
    case 'confirmed':
      text = renderFormatMessage('dashboard.transaction.confirmed', 'Confirmed')
      break

    case 'free':
      text = renderFormatMessage('dashboard.transaction.free', 'Free')
      break

    case 'pending_payment':
      text = renderFormatMessage('dashboard.transaction.pending_payment', 'Pending Payment')
      break

    case 'cancelled':
      text = renderFormatMessage('dashboard.transaction.canceled', 'Canceled')
      break

    case 'errored':
      text = renderFormatMessage('dashboard.transaction.errored', 'Errored')
      break

    case 'paid':
      text = renderFormatMessage('dashboard.transaction.paid', 'Paid')
      break

    case 'pre-authorized':
      text = renderFormatMessage('dashboard.transaction.preauthorized', 'Pre-authorized')
      break

    case 'no-reservation':
      text = renderFormatMessage('dashboard.transaction.noReservation', 'No Reservation')
      break
    case 'checked_in':
      text = 'Checked In'
      break
  }

  return text
}

export const secondsToTime = (secs: number) => {
  var hours = Math.floor(secs / (60 * 60))

  var divisor_for_minutes = secs % (60 * 60)
  var minutes = Math.floor(divisor_for_minutes / 60)

  var divisor_for_seconds = divisor_for_minutes % 60
  var seconds = Math.ceil(divisor_for_seconds)

  var obj = {
    h: hours,
    m: minutes,
    s: seconds,
  }
  return obj
}

const calculateDifference = (start: string, end: string) => {
  const connectedTime = moment(start, 'YYYY-MM-DD HH:mm:ss')
  const disconnectedTime = moment(end, 'YYYY-MM-DD HH:mm:ss')

  return disconnectedTime.diff(connectedTime)
}

const calculateDuration = (start: string, end: string) => {
  const difference = calculateDifference(start, end)
  const duration = moment.duration(difference)

  const seconds = duration.seconds()
  const minutes = duration.minutes()
  const hours = duration.hours()

  // Format the time parts to ensure they are always displayed with 2 digits
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`
}

let columns: any = [
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.transactionId', 'ID'),
    key: 'txnId',
    sorter: (a: Transactionv2, b: Transactionv2) => a.txnId - b.txnId,
    render: (t: Transactionv2) => `#${t.txnId}`,
    align: 'left' as 'left',
  },
  ...(configProvider.config.network === 'itsElectric'
    ? []
    : [
        {
          title: renderFormatMessage('dashboard.transactionsPage.table.userName', 'User Name'),
          sorter: (a: Transactionv2, b: Transactionv2) => a.username.localeCompare(b.username),
          render: (t: Transactionv2) => {
            return (
              <TransactionTableUserNameWrapper>
                <span className="heading-06-regular-table">{t.username ?? '-'}</span>
                {t.startMethod == null ? (
                  <p className="paragraph-04-regular isUndefined">...</p>
                ) : (
                  <span className="paragraph-01-light">{t.startMethod}</span>
                )}
              </TransactionTableUserNameWrapper>
            )
          },
          align: 'left' as 'left',
        },
      ]),
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.duration', 'Duration'),
    sorter: (a: Transactionv2, b: Transactionv2) =>
      calculateDifference(a.connected, a.disconnected) - calculateDifference(b.connected, b.disconnected),
    render: (t: Transactionv2) => {
      const formattedDuration = calculateDuration(t.connected, t.disconnected)

      return t.connected === null || t.disconnected === null ? '-' : formattedDuration
    },
    align: 'left' as 'left',
  },
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.totalEnergy', 'Total Energy'),
    sorter: (a: Transactionv2, b: Transactionv2) => {
      return a.energy - b.energy
    },
    render: (t: Transactionv2) => (t.state == null ? `-` : `${(t.energy / 1000).toFixed(2)}  kWh`),
    align: 'left' as 'left',
  },
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.total', 'Total'),
    sorter: (a: Transactionv2, b: Transactionv2) => {
      return a.total - b.total
    },
    render: (t: Transactionv2) => `$${(t.total / 100).toFixed(2)}`,
    align: 'left' as 'left',
  },
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.state', 'State'),
    sorter: (a: Transactionv2, b: Transactionv2) => {
      return a.state.localeCompare(b.state)
    },
    render: (t: Transactionv2) => {
      return t.state === 'no-reservation' || t.state === 'checked_in' ? (
        <p className="heading-06-regular-table isUndefined">...</p>
      ) : (
        <span className={`state ${renderState(t.state)} paragraph-01-regular`}>{renderStateText(t.state)}</span>
      )
    },
    align: 'left' as 'left',
  },
]

export const HomePageTransactionOverviewContainer = styled.div`
  .website {
    background-color: #c8f9e8;
  }

  .mobile {
    background-color: #54dec7;
  }

  .rfid {
    background-color: #026e78;
    color: ${(props) => props.theme.colors.white} !important;
  }

  .cancelled {
    background-color: ${(props) => props.theme.colors.darkGray};
  }

  .roaming-partner {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.grey10};
  }

  .state {
    text-transform: capitalize;
    border-radius: 17px;
    padding: 4px 12px;
  }

  .state.confirmed {
    padding: 4px 12px;
  }

  .free,
  .confirmed,
  .paid,
  .completed,
  .checked_in {
    color: ${(props) => props.theme.colors.neonGreen};
  }

  .errored,
  .cancelled {
    color: ${(props) => props.theme.colors.dangerPrimary};
  }

  .pre-authorized,
  .pending_payment,
  .no-reservation {
    color: ${(props) => props.theme.colors.orangePeel};
  }

  .state.pre-authorized,
  .state.pending_payment,
  .state.no-reservation {
    background-color: ${(props) => props.theme.colors.orangePeel15};
  }

  .state.free,
  .state.confirmed,
  .state.paid,
  .state.completed,
  .state.checked_in {
    background-color: ${(props) => props.theme.colors.skyBlue15};
  }

  .state.errored,
  .state.cancelled {
    background-color: ${(props) => props.theme.colors.indianRed10};
  }

  .no-reservation {
    color: ${(props) => props.theme.colors.warningPrimary};
  }

  ${(props) => props.theme.breakpoints.down('xxl')} {
    .state.non-reservation {
      padding: 2px 6px;
    }

    .state.non-reservation.smallScreen {
      padding: 2px;
      font-size: 1rem;
    }
  }

  .isUndefined {
    background: rgba(255, 153, 0, 0.15);
    border-radius: 18px;
    width: 30px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: ${(props) => props.theme.colors.orangePeel};
  }
`

interface props {
  transactions: Transactionv2[]
}

export const HomePageTransactionOverview: React.FC<props> = ({ transactions }) => {
  const checkIfSmallScreen = useMediaQuery({ minWidth: 1200, maxWidth: 1250 })
  const makeResponsive = useMediaQuery({ minWidth: 0, maxWidth: 1144 })

  if (checkIfSmallScreen) {
    document.querySelector('.non-reservation')?.classList.add('smallScreen')
  } else {
    document.querySelector('.non-reservation')?.classList.remove('smallScreen')
  }

  return !makeResponsive ? (
    <HomePageTransactionOverviewContainer>
      <ConfigProvider renderEmpty={() => <EmptyData />}>
        <Table
          className="transaction-overview"
          columns={columns}
          dataSource={transactions}
          pagination={false}
          rowKey="txnId"
        />
      </ConfigProvider>
    </HomePageTransactionOverviewContainer>
  ) : (
    <TransactionOverviewCollapse transactions={transactions} />
  )
}
