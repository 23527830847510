import {
  Button,
  Card,
  Cascader,
  Checkbox,
  Col,
  Divider,
  Form,
  message,
  Row,
  Space,
  Statistic,
  Switch,
  TimePicker,
} from 'antd'
import { LatLng } from 'leaflet'
import { useEffect, useState } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { layout, tailLayout } from '../../atom/form/page-layout'
import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'
import { locations } from '../../helpers/location'
import {
  isTwentyFourSeven,
  twentyFourSevenWeeklyScheduleValues,
  weekdays,
  weeklySchedulesFromFormValues,
  weeklySchedulesToFormValues,
} from '../../helpers/weekdays'
import { Measurements } from '../../models/analytics'
import { SwtchError } from '../../models/error'
import { Tenant, TenantPayload } from '../../models/tenant'
import { GetMeasurements } from '../../services/data-provider/analytics'
import { geocode, SwtchAddress } from '../../services/data-provider/geocoding'
import TimeZone from '../../services/data-provider/timezone'
import { theme } from '../../theme'
import { AlertError } from '../error'
import { LocationView } from '../map/location-view'
import { Box } from '../../atom/box'
import { getChangedValues } from 'helpers/form'
import { useSelector } from 'hooks/useSelector'

const CardGridLayout: React.CSSProperties = {
  width: '100%',
  textAlign: 'center',
} as const

const GEOCODE_DEBOUNCE_TIMEOUT = 300

interface tenantTabDetailProps {
  tenant: Tenant
  onSave: (tenant: TenantPayload) => void
}

export const TenantTabLocation: React.FC<tenantTabDetailProps> = ({ tenant, onSave }) => {
  const hasProvince: boolean = !!tenant.location && !!tenant.location?.province
  const hasCountry: boolean = !!tenant.location && !!tenant.location?.country
  const provinceFullySet = hasProvince && hasCountry
  const defaultLocation: LatLng | undefined =
    tenant?.location?.latitude && tenant?.location?.longitude
      ? new LatLng(tenant?.location?.latitude, tenant?.location?.longitude)
      : undefined

  const [measurements, setMeasurements] = useState<Measurements>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<SwtchError>()
  const [addressForGeocoding, setAddressForGeocoding] = useState<SwtchAddress>()
  const [geocodedLocation, setGeocodedLocation] = useState<LatLng | undefined>(defaultLocation)
  const [geocodingError, setGeocodingError] = useState<SwtchError>()
  const [twentyFourSeven, setTwentyFourSeven] = useState<boolean>()
  const [isUniformUpdate, setIsUniformUpdate] = useState(tenant.allowUniformLocation)

  const { siteLabelCleaner } = useSelector()
  const [form] = Form.useForm()

  useEffect(() => {
    getMeasurements()
    getAddressForGeocoding()
    isTwentyFourSevenOption()
  }, [tenant])

  useEffect(() => {
    if (addressForGeocoding) geocodeAddress(addressForGeocoding)
  }, [addressForGeocoding])

  const getMeasurements = () => {
    if (tenant.eyedroIpAddress) {
      setLoading(true)
      GetMeasurements(tenant)
        .then((measurements) => setMeasurements(measurements[0]))
        .catch((err) => setError(err))
        .finally(() => setLoading(false))
    }
  }

  const getAddressForGeocoding = () => {
    setAddressForGeocoding({
      numberAndStreet: initialValues['address'],
      city: initialValues['city'],
      province: tenant?.location?.province,
      postalCode: initialValues['postalCode'],
      country: tenant?.location?.country,
    })
  }

  const isTwentyFourSevenOption = () => {
    const tenantIsEffectivelyTwentyFourSeven = tenant?.weeklySchedules && isTwentyFourSeven(tenant.weeklySchedules)
    setTwentyFourSeven(tenantIsEffectivelyTwentyFourSeven)
  }

  const defaultMapCenter: LatLng = new LatLng(geocodedLocation?.lat || 43.75, geocodedLocation?.lng || -79.4)

  const addressText = useFormatMessage('dashboard.tenantPage.detailTab.address.heading', 'Address')
  const cityText = useFormatMessage('dashboard.tenantPage.detailTab.city.heading', 'City')
  const postalCodeText = useFormatMessage('dashboard.tenantPage.detailTab.postalCode.heading', 'Postal Code')
  const countryAndProvinceText = useFormatMessage(
    'dashboard.tenantPage.detailTab.countryAndProvince.heading',
    'Country and Province',
  )
  const timeZoneText = useFormatMessage('dashboard.tenantPage.detailTab.timezone', 'Time Zone')
  const locationText = useFormatMessage('dashboard.tenantPage.detailTab.location', 'Location')
  const open247Text = useFormatMessage('dashboard.tenantPage.detailTab.open247', 'Open 24/7')
  const ocpiSharingText = useFormatMessage('dashboard.tenantPage.detailTab.ocpiSharing', 'OCPI Sharing')
  const saveText = useFormatMessage('dashboard.tenantPage.detailTab.saveBtn', 'Save')
  const resetText = useFormatMessage('dashboard.tenantPage.detailTab.resetbtn', 'Reset')
  const eyedroIpAddressHeading = useFormatMessage(
    'dashboard.tenantPage.eyedroIpAddress.heading',
    'Building Service - EV Panel Load',
  )
  const eyedroIpAddressPowerFactor = useFormatMessage(
    'dashboard.tenantPage.eyedroIpAddress.powerfactor',
    'Power Factor',
  )
  const eyedroIpAddressVoltage = useFormatMessage('dashboard.tenantPage.eyedroIpAddress.voltage', 'Voltage')
  const eyedroIpAddressAmperage = useFormatMessage('dashboard.tenantPage.eyedroIpAddress.amperage', 'Amperage')
  const eyedroIpAddressWattage = useFormatMessage('dashboard.tenantPage.eyedroIpAddress.wattage', 'Wattage')
  const eyedroIpAddress = useFormatMessage('dashboard.tenantPage.eyedroIpAddress.ipaddress', 'IP Address')
  const geoCodingError = useFormatMessage(
    'dashboard.tenantPage.error.geocodingError',
    'Geocoding result is outside province',
  )
  const geoCodingCoordinateError = useFormatMessage(
    'dashboard.tenantPage.error.geocodingError',
    'Geocoding result is outside province',
  )
  const countryandProvinceError = useFormatMessage(
    'dashboard.tenantPage.error.countryAndProvinceError',
    'Please pick a country and province for this tenant',
  )
  const googlelatlongError = useFormatMessage(
    'dashboard.tenantPage.error.googleLatLongError',
    'Google did not give us a latitude and longitude for that address',
  )
  const tenantUpdated = useFormatMessage('dashboard.tenantPage.chargersTab.tenantupdated', 'has been updated!')

  const postalCodeWarning = useFormatMessage(
    'dashboard.tenantPage.detailTab.postalCode.warning',
    "That's a bit long isn't it? Please limit yourself to 10 characters here.",
  )

  const tagText = useFormatMessage(
    'dashboard.tenantPage.postalCode.19103.info',
    'Tax rate of 8% will apply to chargers in this zip code',
  )

  const geocodeAddress = (address: SwtchAddress) => {
    setGeocodingError(undefined)

    // const geocodingResultAdmissible = (result: GeocodingSuccess): boolean => {
    //   if (address.province && address.country) {
    //     return (
    //       !!result.province &&
    //       !!result.country &&
    //       result.province === address.province &&
    //       result.country === address.country
    //     )
    //   } else if (address.province === 'PR' && result.country === 'PR') {
    //     return true
    //   } else return false
    // }

    geocode(address).then((result) => {
      if (result.found) {
        if (result.country && result.province) {
          setGeocodedLocation(new LatLng(result.lat, result.lng))
        } else {
          setGeocodedLocation(undefined)
          setGeocodingError(new SwtchError(`${geoCodingError} ${address.province}`))
        }
      } else {
        setGeocodedLocation(undefined)
        setGeocodingError(new SwtchError(geoCodingCoordinateError, [`${result.code}: ${result.message}`]))
      }
    })
  }

  const initialWeeklyScheduleValues =
    tenant && tenant.weeklySchedules
      ? weeklySchedulesToFormValues(tenant.weeklySchedules)
      : twentyFourSevenWeeklyScheduleValues

  const initialValues = {
    displayName: tenant?.displayName,
    accessCode: tenant?.accessCode,
    address: tenant?.location?.address,
    city: tenant?.location?.city,
    postalCode: tenant?.location?.postalCode,
    province: [tenant?.location?.country, tenant?.location?.province],
    publishToOcpi: tenant.publishToOcpi,
    allowUniformLocation: tenant.allowUniformLocation,
    displayListingsOnMap: tenant.displayListingsOnMap,
    ...initialWeeklyScheduleValues,
  }

  const onReset = () => form.resetFields()

  const onFinish = async (values: any) => {
    const weeklySchedules = twentyFourSeven
      ? twentyFourSevenWeeklyScheduleValues
      : weeklySchedulesFromFormValues(values)

    const timeZone = geocodedLocation ? await TimeZone(geocodedLocation.lat, geocodedLocation.lng) : undefined
    // unfortunately AntD doesn't seem to be able to enforce the 'required'
    // attribute on its Cascader control so we have to manually require it here.
    // We communicate the form validation failure using message.error because
    // it's the easiest thing to do. Yes, it's inconsistent with the other
    // fields which use validation messages next to the field. On the upside, it
    // means we don't have to add yet more state to this already heavy component.
    if (!values['province'] || !values['province'][0] || !values['province'][1]) {
      message.error(countryandProvinceError)
    } else if (geocodedLocation === undefined) {
      message.error(googlelatlongError)
    } else {
      const changedValues: any = { ...getChangedValues(initialValues, values as any), id: tenant.id }

      if (weeklySchedules) {
        changedValues.weeklySchedules = weeklySchedules
      }

      if (
        changedValues.address ||
        changedValues.postalCode ||
        changedValues.city ||
        changedValues.province ||
        geocodedLocation ||
        (timeZone && timeZone.found)
      ) {
        changedValues.location = {
          ...tenant.location,
          address: values['address'] || tenant.location?.address,
          postalCode: values['postalCode'] || tenant.location?.postalCode,
          city: values['city'] || tenant.location?.city,
          province: values['province'][1] || tenant.location?.province,
          country: values['province'][0] || tenant.location?.country,
          latitude: geocodedLocation?.lat || tenant.location?.latitude,
          longitude: geocodedLocation?.lng || tenant.location?.longitude,
          listingTimezone: timeZone && timeZone.found ? timeZone.timeZoneId : tenant.location?.listingTimezone,
        }
        delete changedValues.address
        delete changedValues.postalCode
        delete changedValues.city
        delete changedValues.province
      }
      onSave(changedValues)
      message.success(`${siteLabelCleaner(tenant.combineName || tenant.displayName || tenant.name)} ${tenantUpdated}`)
    }
  }

  return (
    <Box padding>
      <Form {...layout} form={form} name={`tenant-${tenant?.id}`} onFinish={onFinish} initialValues={initialValues}>
        <Form.Item name="address" label={addressText} rules={[{ required: true }]}>
          <DebounceInput
            debounceTimeout={GEOCODE_DEBOUNCE_TIMEOUT}
            onChange={(evt) => setAddressForGeocoding({ ...addressForGeocoding, numberAndStreet: evt.target.value })}
          />
        </Form.Item>

        <Form.Item name="city" label={cityText} rules={[{ required: true }]}>
          <DebounceInput
            debounceTimeout={GEOCODE_DEBOUNCE_TIMEOUT}
            onChange={(evt) => setAddressForGeocoding({ ...addressForGeocoding, city: evt.target.value })}
          />
        </Form.Item>

        <Form.Item
          name="postalCode"
          label={postalCodeText}
          rules={[{ max: 10, message: postalCodeWarning }, { required: true }]}
          help={
            addressForGeocoding &&
            addressForGeocoding.postalCode === '19103' && <span style={{ color: theme.colors.red100 }}>{tagText}</span>
          }
        >
          <DebounceInput
            debounceTimeout={GEOCODE_DEBOUNCE_TIMEOUT}
            onChange={(evt) => setAddressForGeocoding({ ...addressForGeocoding, postalCode: evt.target.value })}
          />
        </Form.Item>

        <Form.Item name="province" label={countryAndProvinceText} rules={[{ required: true }]}>
          <Cascader options={locations} disabled={!provinceFullySet} allowClear={false} />
        </Form.Item>

        <Form.Item label={timeZoneText}>
          <span className="ant-form-text">{tenant?.location?.listingTimezone}</span>
        </Form.Item>

        <Divider />

        <Form.Item label={locationText}>
          {geocodingError ? (
            <AlertError error={geocodingError} />
          ) : (
            <LocationView
              value={geocodedLocation}
              defaultCenter={defaultMapCenter}
              defaultZoom={15}
              style={{ height: '50vh' }}
            />
          )}
        </Form.Item>

        <Divider />

        {tenant.eyedroIpAddress ? (
          <>
            <Form.Item label={eyedroIpAddressHeading}>
              <AlertError error={error} />
              <Row gutter={[8, 8]}>
                <Col xs={12} sm={24} md={12} lg={12} xl={6} xxl={6}>
                  <Card loading={loading}>
                    <Card.Grid hoverable={false} style={CardGridLayout}>
                      <Statistic
                        title={eyedroIpAddressPowerFactor}
                        value={measurements?.power_factor || 0}
                        precision={3}
                        valueStyle={{ fontSize: theme.fontSizes[4] }}
                      />
                    </Card.Grid>
                  </Card>
                </Col>
                <Col xs={12} sm={24} md={12} lg={12} xl={6} xxl={6}>
                  <Card loading={loading}>
                    <Card.Grid hoverable={false} style={CardGridLayout}>
                      <Statistic
                        title={eyedroIpAddressVoltage}
                        value={measurements?.voltage || 0}
                        precision={2}
                        valueStyle={{ fontSize: theme.fontSizes[4] }}
                      />
                    </Card.Grid>
                  </Card>
                </Col>
                <Col xs={12} sm={24} md={12} lg={12} xl={6} xxl={6}>
                  <Card loading={loading}>
                    <Card.Grid hoverable={false} style={CardGridLayout}>
                      <Statistic
                        title={eyedroIpAddressAmperage}
                        value={measurements?.amperage || 0}
                        precision={2}
                        valueStyle={{ fontSize: theme.fontSizes[4] }}
                      />
                    </Card.Grid>
                  </Card>
                </Col>
                <Col xs={12} sm={24} md={12} lg={12} xl={6} xxl={6}>
                  <Card loading={loading}>
                    <Card.Grid hoverable={false} style={CardGridLayout}>
                      <Statistic
                        title={eyedroIpAddressWattage}
                        value={measurements?.wattage || 0}
                        precision={3}
                        valueStyle={{ fontSize: theme.fontSizes[4] }}
                      />
                    </Card.Grid>
                  </Card>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item label={eyedroIpAddress}>
              <span className="ant-form-text">{tenant.eyedroIpAddress}</span>
            </Form.Item>

            <Divider />
          </>
        ) : null}

        <Form.Item label={open247Text} name="twentyFourSeven">
          <Switch checked={twentyFourSeven} onChange={setTwentyFourSeven}></Switch>
        </Form.Item>

        {twentyFourSeven ||
          weekdays.map(([_, dayName]) => (
            <Form.Item key={dayName} label={dayName} name={dayName.toLowerCase()}>
              <TimePicker.RangePicker format="HH:mm"></TimePicker.RangePicker>
            </Form.Item>
          ))}

        <Divider />

        <Form.Item label={ocpiSharingText} name="publishToOcpi" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Divider />

        <Form.Item
          name="allowUniformLocation"
          label={renderFormatMessage('dashboard.tenantPage.mapSettingsuniformUpdateText', 'Uniform Map Settings')}
        >
          <Switch checked={isUniformUpdate} onChange={setIsUniformUpdate} />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.allowUniformLocation !== currentValues.allowUniformLocation
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('allowUniformLocation') === true ? (
              <Form.Item
                name="displayListingsOnMap"
                label={renderFormatMessage(
                  'dashboard.tenantPage.locationTab.hideTenantFromListingsText',
                  'Listings Hidden on Map',
                )}
                rules={[{ required: false }]}
                validateStatus="warning"
                help={renderFormatMessage(
                  'dashboard.tenantPage.locationTab.uniformupdate.help',
                  'IMPORTANT: Checking this checkbox will hide all listings associated with this site from the map',
                )}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <Divider />

        <Form.Item {...tailLayout}>
          <Space>
            <Button htmlType="submit" type="primary">
              {saveText}
            </Button>

            <Button htmlType="button" onClick={onReset}>
              {resetText}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Box>
  )
}
