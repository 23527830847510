import { TaggingFilter } from 'models/filter'
import { PaginationMeta } from 'models/pagination'
import { ApplyTableName, Tagging, TaggingTableName } from 'models/tagging'
import { ReactElement, useRef, useState } from 'react'
import { ApplyTags, CreateTagging, GetAllTagging, GetAllTaggingWithoutPagination } from 'services/data-provider/tagging'
import { SwtchApiError, SwtchError } from '../models/error'
import { CustomSuccessMessage } from 'components/peakShaving/message'
import { Button, Checkbox, Divider, Input, InputRef, Select, Space } from 'antd'
import { PlusOutlined, TagOutlined } from '@ant-design/icons'
import { renderFormatMessage } from 'helpers/intl'
import { useTaggingTranslation } from 'hooks/translation/useTaggingTranslation'
import { useAppState } from 'state'

export const useTagging = () => {
  const { isMockUpEnabled } = useAppState()
  const [taggings, setTaggings] = useState<Tagging[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [filter, setFilter] = useState<TaggingFilter>({ page: 1, perPage: 10 })
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [selectedTag, setSelectedTag] = useState<Tagging | undefined>()

  const messageKey = 'applyTag'
  const createNewTagMessageKey = 'createNewTagMessageKey'

  const { createSuccessText, addNewTagText } = useTaggingTranslation()

  const getTaggings = async (page = 1) => {
    setLoading(true)
    setError(undefined)
    GetAllTagging({ ...filter, page })
      .then(({ data, pagination }) => {
        setTaggings(data)
        setPagination(pagination)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getTaggingsWithoutPagination = async (tableName: TaggingTableName, tagName?: string) => {
    setLoading(true)
    setError(undefined)
    GetAllTaggingWithoutPagination(isMockUpEnabled, { tableName, tagName })
      .then(setTaggings)
      .catch(setError)
      .finally(() => setLoading(false))
  }

  const checkCurrentObjectsTagged = (taggedObjects: number[], currentObjects: { id: number }[]) => {
    let currentObjectIncluded = 0
    currentObjects.forEach(({ id: objectId }) => {
      if (taggedObjects.includes(objectId)) currentObjectIncluded++
    })
    return currentObjectIncluded === currentObjects.length
  }

  const applyTagToObject = async (currentObjects: { id: number }[], tableName: ApplyTableName) => {
    setLoading(true)
    setError(undefined)
    ApplyTags(
      taggings
        .filter((tag) => checkCurrentObjectsTagged(tag.taggedObjects, currentObjects))
        .map((val) => ({ id: val.id })),
      currentObjects,
      tableName,
    )
      .then((res) => {
        CustomSuccessMessage({ key: messageKey, response: res.message })
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onPaginationChange = (page: number) => {
    setFilter({ ...filter, page })
    getTaggings(page)
  }

  const renderTagOption = (option: any, searchValue: string): ReactElement => {
    return (
      option.state.tagName.toLowerCase().includes(searchValue?.toLowerCase()) && (
        <Select.Option key={option.state.id} value={option.state.id} label={option.state.tagName}>
          <TagOutlined style={{ marginRight: 5 }} />
          {option.state.tagName}
        </Select.Option>
      )
    )
  }
  const renderMultiTagOption = (
    tag: Tagging,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    return (
      <Select.Option key={tag.id} value={tag.id} label={tag.tagName}>
        <Checkbox
          onClick={handleCheckboxClick}
          checked={selectedOptions.findIndex((option) => option.id === tag.id) > -1}
        >
          {tag.tagName}
        </Checkbox>
      </Select.Option>
    )
  }

  const inputRef = useRef<InputRef>(null)
  const [newTagName, setNewTagName] = useState('')
  const onNewTagNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTagName(event.target.value)
  }

  const checkDuplicate = () => {
    const filterName = taggings.filter((tag) => tag.tagName?.toLowerCase() === newTagName.toLowerCase())

    if (newTagName.length === 0) {
      return true
    } else if (filterName.length !== 0) {
      return true
    } else {
      return false
    }
  }

  const addNewTag = (tableName: TaggingTableName) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setLoading(true)
    CreateTagging({
      tagName: newTagName,
      status: 'active',
      tableName,
      tagType: 'admin',
    })
      .then((resp) => {
        setNewTagName('')
        const responseMessage = createSuccessText.replace('-1', resp.tagName)
        CustomSuccessMessage({ key: createNewTagMessageKey, response: responseMessage })
        getTaggingsWithoutPagination(tableName)
      })
      .catch((err: SwtchApiError) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })

    setTimeout(() => {
      inputRef.current?.focus()
    }, 500)
  }

  const dropdownRender = (tableName: TaggingTableName, hideAdd: boolean = false) => (
    menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
  ) => (
    <>
      {menu}
      {!hideAdd && (
        <>
          <Divider style={{ margin: '8px 0' }} />
          <Space wrap style={{ padding: '0 8px 4px' }}>
            <Input placeholder={addNewTagText} ref={inputRef} value={newTagName} onChange={onNewTagNameChange} />
            <Button type="primary" disabled={checkDuplicate()} icon={<PlusOutlined />} onClick={addNewTag(tableName)}>
              {renderFormatMessage('dashboard.text.add', 'Add')}
            </Button>
          </Space>
        </>
      )}
    </>
  )

  return {
    taggings,
    setTaggings,
    loading,
    error,
    pagination,
    onPaginationChange,
    selectedTag,
    setSelectedTag,
    getTaggings,
    getTaggingsWithoutPagination,
    applyTagToObject,
    checkCurrentObjectsTagged,
    renderTagOption,
    dropdownRender,
    renderMultiTagOption,
  }
}
