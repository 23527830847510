import { Card, Space, Tooltip } from 'antd'
import EVChargerSVG from 'assets/svg/ev_charging'
import { theme } from 'theme'
import { questionMarkSVG } from 'assets/svg/questionMark'
import { useAccessPlanTranslation } from 'hooks/translation/useAccessPlanTranslation'
import CoreBadge from 'components/badge/Badge'
import { checkmarkCircleSVG } from 'assets/svg/checkmark'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { ListingRef } from 'models/listing'

interface tenantTabDetailProps {
  chargersNotAssigned: ListingRef[]
  chargerIdsExpanded: boolean
  tagsRef: React.RefObject<HTMLDivElement>
  isMultiLine: boolean
  setChargerIdsExpanded: (chargerIdsExpanded: boolean) => void
}

export const ChargersNotLinkedHeader: React.FC<tenantTabDetailProps> = ({
  chargersNotAssigned,
  chargerIdsExpanded,
  tagsRef,
  isMultiLine,
  setChargerIdsExpanded,
}) => {
  const { chargersNotLinkedText, allAssignedText, chargersToolTipText } = useAccessPlanTranslation()

  return (
    <Card bodyStyle={{ boxShadow: '0px 4px 17px 0px #00000012', marginBottom: '16px' }} style={{ border: 0 }}>
      <Space style={{ marginBottom: '15px' }}>
        <span className="heading-06-bold">{chargersNotLinkedText}</span>
        <Tooltip title={chargersToolTipText} className="question-svg">
          <span>{questionMarkSVG}</span>
        </Tooltip>
      </Space>
      {chargersNotAssigned.length > 0 ? (
        <div
          style={{ display: 'flex', alignItems: chargerIdsExpanded ? 'flex-start' : 'center' }}
          className="chargers-linked"
        >
          <span className="ev-charger-svg">
            <EVChargerSVG color={theme.colors.primary} />
          </span>
          <span
            style={{
              fontSize: '32px',
              fontWeight: 900,
              marginLeft: '8px',
              marginRight: '24px',
              marginTop: chargerIdsExpanded ? '-7px' : '0px',
            }}
          >
            {chargersNotAssigned.length}
          </span>
          <Space wrap>
            <div ref={tagsRef} className={`tags ${chargerIdsExpanded ? 'expanded' : ''}`}>
              {chargersNotAssigned.map((charger, index) => (
                <CoreBadge title={charger.title} />
              ))}
            </div>
          </Space>
          {isMultiLine && (
            <div style={{ padding: '5px' }}>
              {chargerIdsExpanded ? (
                <MinusCircleOutlined onClick={() => setChargerIdsExpanded(!chargerIdsExpanded)} />
              ) : (
                <PlusCircleOutlined onClick={() => setChargerIdsExpanded(!chargerIdsExpanded)} />
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <span className="ev-charger-svg">{checkmarkCircleSVG}</span>
          <span
            style={{
              fontSize: '32px',
              fontWeight: 900,
              marginLeft: '8px',
              marginRight: '24px',
            }}
          >
            {allAssignedText}
          </span>
        </div>
      )}
    </Card>
  )
}
