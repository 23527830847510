import { Form, Select, Tag } from 'antd'
import { useEffect, useState } from 'react'

import { EmailValidationStatus, ValidationStatus } from '../../../models/validation'
import { UserInviteInfo } from '../../../models/user'
import {
  EmailWrapper,
  ErrorMessageWrapper,
  SelectStyling,
  SvgSpacing,
  UsersTagsWrapper,
} from '../../../atom/user-invite'
import { useUserTranslation } from '../../../hooks/translation/useUserTranslation'
import { SearchForExistingEmail } from '../../../services/data-provider/users'
import { dangerSVG } from '../../../assets/svg/danger'
import { ButtonIconCircle } from '../../../atom/button'
import { sendSVG } from '../../../assets/svg/send'
import { useAppState } from 'state'

interface props {
  selectedUsers: UserInviteInfo[]
  validateStatus: string
  loading: boolean
  setValidateStatus: (validateStatus: string) => void
  setSelectedUsers: (selectedUser: UserInviteInfo[]) => void
  setLoading: (loading: boolean) => void
}

const UserEmail: React.FC<props> = ({
  validateStatus,
  loading,
  selectedUsers,
  setValidateStatus,
  setSelectedUsers,
  setLoading,
}) => {
  const { isMockUpEnabled } = useAppState()
  const [searchValue, setSearchValue] = useState('')
  const [enterPressed, setEnterPressed] = useState(false)
  const [disableEmailButton, setDisableEmailButton] = useState(true)
  const [outOfFocus, setOutOfFocus] = useState(false)
  const { emailText, emailError } = useUserTranslation()

  useEffect(() => {
    if (enterPressed) {
      setLoading(true)
      SearchForExistingEmail(isMockUpEnabled, searchValue).then((resp) => {
        if (resp.email_exist) {
          setValidateStatus(EmailValidationStatus.success)
          if (resp.person_id && resp.person_name) {
            let userExists = false
            selectedUsers.map((selectedUser: UserInviteInfo) => {
              if (selectedUser.email === searchValue) {
                userExists = true
              }
              return userExists
            })
            if (!userExists) {
              setSelectedUsers([...selectedUsers, { email: searchValue, id: resp.person_id, name: resp.person_name }])
            }
          }
        } else {
          setValidateStatus(EmailValidationStatus.error)
        }
        setSearchValue('')
        setLoading(false)
        setEnterPressed(false)
      })
    }
  }, [enterPressed])

  useEffect(() => {
    if (searchValue === '' || searchValue === undefined) {
      setDisableEmailButton(true)
    } else {
      setDisableEmailButton(false)
    }
  }, [searchValue])

  const removeUser = (email: string) => {
    // remove user from selectedUsers
    const tempUsersArr = selectedUsers.filter((selectedUser: UserInviteInfo) => selectedUser.email !== email)
    setSelectedUsers(tempUsersArr)
  }

  return (
    <EmailWrapper>
      <Form.Item
        key="email"
        label={emailText}
        name="email"
        className="email"
        requiredMark={true}
        validateStatus={validateStatus as ValidationStatus}
        validateTrigger={['onChange', 'onBlur']}
        rules={[
          { required: true, message: '' },
          () => ({
            validator(_, value: string) {
              if (!outOfFocus) {
                setSearchValue(value)
              }
              if (outOfFocus) {
                setOutOfFocus(false)
              }
            },
          }),
        ]}
      >
        <SelectStyling disabled={loading || searchValue === ''}>
          <Select
            showSearch
            autoClearSearchValue={true}
            value={searchValue || null}
            placeholder="Email"
            style={{ marginLeft: '0px' }}
            defaultActiveFirstOption={true}
            showArrow={false}
            onFocus={(e) => {
              if (validateStatus === 'success') setSearchValue('')
            }}
            onKeyDown={(e) => {
              if (e.code === 'Enter' || e.code === 'Tab') {
                setEnterPressed(true)
              }
            }}
            onBlur={(e) => setOutOfFocus(true)}
            optionLabelProp="children"
            dropdownStyle={{ display: 'none' }}
          />
          <ButtonIconCircle
            icon={sendSVG}
            disabled={disableEmailButton}
            loading={disableEmailButton}
            fetchData={() => setEnterPressed(true)}
          />
          {validateStatus === 'error' && (
            <ErrorMessageWrapper>
              <SvgSpacing>{dangerSVG}</SvgSpacing> {emailError}
            </ErrorMessageWrapper>
          )}
          <UsersTagsWrapper>
            {selectedUsers.length > 0 &&
              selectedUsers.map((user) => (
                <Tag closable={true} onClose={(value) => removeUser(user.email)}>
                  {user.email}
                </Tag>
              ))}
          </UsersTagsWrapper>
        </SelectStyling>
      </Form.Item>
    </EmailWrapper>
  )
}

export default UserEmail
