import { CloseOutlined } from '@ant-design/icons'
import { Divider, Form, Row, Tag } from 'antd'
import { TagSelectorWrapper } from 'atom/tagging'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { hexToRgba } from 'helpers/color'
import { useListingCommissionFeeTranslation } from 'hooks/translation/useListingCommissionFeeTrans'
import { useTagging } from 'hooks/useTagging'
import { Tagging, TaggingTableName } from 'models/tagging'
import { useAppState } from 'state'
import { theme } from 'theme'

interface props {
  taggings: Tagging[]
  setTaggings: React.Dispatch<React.SetStateAction<Tagging[]>>
  objectIds: { id: number }[]
  hideDivider?: boolean
  disabled?: boolean
  tableName: TaggingTableName
  dropdownRender: (
    tableName: TaggingTableName,
    hideAdd?: boolean,
  ) => (menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => JSX.Element
}

export const ObjectTagging: React.FC<props> = ({
  taggings,
  setTaggings,
  objectIds,
  hideDivider = false,
  disabled = false,
  tableName,
  dropdownRender,
}) => {
  const { isMockUpEnabled } = useAppState()
  const { taggingText } = useListingCommissionFeeTranslation()

  const { checkCurrentObjectsTagged, renderTagOption } = useTagging()

  const selectTag = (method: 'add' | 'remove') => (tagId: number) => {
    const updatedTaggings = [...taggings]
    taggings.forEach((tag, index) => {
      if (tagId === tag.id) {
        if (method === 'add') {
          updatedTaggings[index] = {
            ...updatedTaggings[index],
            taggedObjects: objectIds.map(({ id }) => id),
          }
        } else {
          updatedTaggings[index] = {
            ...updatedTaggings[index],
            taggedObjects: [],
          }
        }
      }
    })
    setTaggings(updatedTaggings)
  }

  return (
    <TagSelectorWrapper>
      {!hideDivider && <Divider>{taggingText}</Divider>}
      <Form.Item name="taggings" label={taggingText} className="align-center">
        <ReusableSelector
          showSearch
          isSingle
          nativeSearch
          disabled={disabled}
          clearSearchAfterSelect
          placeholder={taggingText}
          dropDownList={taggings}
          showMagnifySVG={false}
          maxTagCount={0}
          isDebounceFetcher={false}
          handleOptionChange={selectTag('add')}
          debounceFetcher={undefined}
          renderOption={renderTagOption}
          dropdownRender={dropdownRender(tableName)}
          isMockUpEnabled={isMockUpEnabled}
        />
        <Row wrap gutter={8} style={{ marginBottom: 32 }}>
          {taggings
            .filter((tag) => checkCurrentObjectsTagged(tag.taggedObjects, objectIds))
            .map((tag) => (
              <Tag
                style={{ marginTop: 16 }}
                color={hexToRgba(theme.colors.primary, 0.1)}
                closable={!disabled}
                closeIcon={<CloseOutlined style={{ color: theme.colors.softBlack }} />}
                onClose={() => selectTag('remove')(tag.id)}
              >
                {tag.tagName}
              </Tag>
            ))}
        </Row>
      </Form.Item>
    </TagSelectorWrapper>
  )
}
