import { Alert, Button, Col, Row, Select, Space, Spin } from 'antd'
import { useEffect, useState } from 'react'

import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'
import { Charger } from '../../models/charger'
import {
  ConnectorResponse,
  RemoteMessageNotConnectedResp,
  RemoteMultiChargerTriggerMessageParams,
} from '../../models/charger-remote'
import { Connector } from '../../models/connector'
import { SwtchError } from '../../models/error'
import { RemoteMultiChargerTriggerMessage } from '../../services/data-provider/charger-remote'
import { AlertError } from '../error'
import { useAppState } from 'state'

const { Option } = Select

interface props {
  charger: Charger
  connector: Connector
}

type TriggerMessageType = {
  label: string
  value: string
}

const initialMessage = {
  label: 'Message',
  value: '',
}

export const RemoteTriggerMessageController: React.FC<props> = ({ charger, connector }) => {
  const { isMockUpEnabled } = useAppState()
  const [message, setMessage] = useState(initialMessage)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [connectionMessage, setConnectionMessage] = useState<RemoteMessageNotConnectedResp['error']>('')
  const [error, setError] = useState<SwtchError>()

  const chargerObj: RemoteMultiChargerTriggerMessageParams = {
    charger_id: charger.id,
    connector_id: connector.connectorId,
  }
  const [errorMessage, setErrorMessasge] = useState<ConnectorResponse[]>([])
  const [connectorResponse, setConnectorResponse] = useState<ConnectorResponse[]>([])
  const [passed, setPassed] = useState(0)
  const [failed, setFailed] = useState(0)

  const tiggerMessageTitle = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.text',
    'Select a message below for 1234 - connector -1 to initiate a trigger message',
  )

  const alertSuccessText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.success',
    '1234 has been sent to 0000 - connector -1 for charger 1111',
  )

  const alertErrorText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error',
    '1234 has failed to send to connector -1 for charger 1111',
  )

  const messageTypePlaceHolder = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.select.placeholder',
    'Select a message type',
  )

  const TriggerText = useFormatMessage('dashboard.chargerPage.remoteControlTab.triggerMessage.trigger', 'Trigger')

  const errorMssg = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.errormessage',
    'Select a message type to send',
  )

  const sendingMessageText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.message',
    'Sending message 0000 to connector -1 for charger 1234!',
  )

  const rejectedResponseText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error.message.rejected',
    'Rejected',
  )

  const acceptedResponseText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error.message.accepted',
    'Accepted',
  )

  const resetView = (loading: boolean) => {
    setLoading(loading)
    setSuccess(false)
    //setRespMessage([])
    setError(undefined)
  }

  useEffect(() => {
    resetView(false)
  }, [])

  const availableMessages: { label: string; value: string }[] = [
    {
      label: useFormatMessage(
        'dashboard.chargerPage.remoteControlTab.triggerMessage.select.bootNotification',
        'Boot Notification',
      ),
      value: 'BootNotification',
    },
    {
      label: useFormatMessage(
        'dashboard.chargerPage.remoteControlTab.triggerMessage.select.diagnosticsStatusNotification',
        'Diagnostics Status Notification',
      ),
      value: 'DiagnosticsStatusNotification',
    },
    {
      label: useFormatMessage(
        'dashboard.chargerPage.remoteControlTab.triggerMessage.select.firmwareStatusNotification',
        'Firmware Status Notification',
      ),
      value: 'FirmwareStatusNotification',
    },
    {
      label: useFormatMessage('dashboard.chargerPage.remoteControlTab.triggerMessage.select.heartbeat', 'Heart Beat'),
      value: 'Heartbeat',
    },
    {
      label: useFormatMessage('dashboard.chargerPage.remoteControlTab.triggerMessage.select.meterValue', 'Meter Value'),
      value: 'MeterValues',
    },
    {
      label: useFormatMessage(
        'dashboard.chargerPage.remoteControlTab.triggerMessage.select.statusNotification',
        'Status Notification',
      ),
      value: 'StatusNotification',
    },
  ]

  const handleTriggerMessage = () => {
    if (message.label === '') {
      setError(new SwtchError(errorMssg))
      return
    }
    resetView(true)
    RemoteMultiChargerTriggerMessage(isMockUpEnabled, { charger_params: [chargerObj], message: message.value })
      .then((resp) => {
        setPassed(resp.passed)
        setFailed(resp.failed)
        let p: ConnectorResponse[] = []
        let f: ConnectorResponse[] = []
        resp.payload.forEach((r: ConnectorResponse) => {
          r.success === true ? p.push(r) : f.push(r)
        })

        setConnectorResponse(p)
        setErrorMessasge(f)
      })
      .catch((err: SwtchError) => {
        setError(err)
        setSuccess(false)
        setConnectionMessage('')
      })
      .finally(() => setLoading(false))
  }

  const handleSelectTriggerMessage = ({ label, value }: TriggerMessageType) => {
    setSuccess(false)
    setMessage({ label, value })
  }

  const renderMessages = () => {
    return (
      <>
        {error && <AlertError error={error} />}
        {passed >= 1 &&
          connectorResponse.map(
            (res, index) =>
              Array.isArray(res.message) && (
                <Alert
                  key={index}
                  message={`${alertSuccessText
                    .replace('1234', message.label)
                    .replace('0000', `${connector.listing?.title}`)
                    .replace('-1', `${connector.connectorId}`)
                    .replace('1111', `${charger.chargePointSerialNumber}`)}: ${acceptedResponseText}`}
                  description={res.message[2].status}
                  type="info"
                  style={{ marginBottom: '10px' }}
                />
              ),
          )}
        {failed >= 1 &&
          errorMessage.map((err, index) => {
            const errMssg: any = err.message
            return (
              <>
                {
                  <Alert
                    key={index}
                    message={`${alertErrorText
                      .replace('1234', message.label)
                      .replace('-1', `${connector.connectorId}`)
                      .replace('1111', `${charger.chargePointSerialNumber}`)}: ${
                      !err.success && `${rejectedResponseText}`
                    }`}
                    description={
                      errMssg['error'] ? errMssg['error'] : Array.isArray(errMssg) ? errMssg[2].status : err.message
                    }
                    type="error"
                  />
                }
              </>
            )
          })}
        {!success && connectionMessage && <Alert message={connectionMessage} type={'error'} />}
      </>
    )
  }

  return (
    <Row>
      <Col span={24}>
        <p>
          {tiggerMessageTitle.replace('1234', `${connector.listing?.title}`).replace('-1', `${connector.connectorId}`)}
        </p>
        <Space>
          <Select
            showSearch
            labelInValue
            dropdownMatchSelectWidth={false}
            placeholder={messageTypePlaceHolder}
            optionFilterProp="children"
            onChange={handleSelectTriggerMessage}
          >
            {availableMessages.map((opt) => {
              return (
                <Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Option>
              )
            })}
          </Select>
          <Button type="primary" onClick={() => handleTriggerMessage()} loading={loading}>
            {message
              ? `${TriggerText}`
              : `${renderFormatMessage(
                  'dashboard.chargerPage.remoteControlTab.triggerMessage.btn.sendTriggerMessage',
                  'Send Trigger Message',
                )}`}
          </Button>
        </Space>
      </Col>
      <Col span={12} style={{ paddingTop: '10px' }}>
        {loading && (
          <Alert
            message={
              <div>
                <Spin style={{ marginRight: '10px' }} />
                {sendingMessageText
                  .replace('0000', message.label)
                  .replace('-1', `${connector.connectorId}`)
                  .replace('1234', `${charger.chargePointSerialNumber}`)}
              </div>
            }
          />
        )}
        {!loading && renderMessages()}
      </Col>
    </Row>
  )
}
