import {
  Connector,
  connectorServiceStatus,
  ConnectorServiceStatus,
  connectorStatus,
  ConnectorStatus,
  ConnectorV2,
} from '../models/connector'
import { ChargerRef } from '../models/charger'
import { mockCharger } from './charger-mock'
import { mockListing } from './listing-mock'
import { ConnectorLogsResponse, ConnectorsResponse } from '../models/http'
import { TenantRef } from '../models/tenant'
import { generatedMoment } from 'helpers/date/datetime'

export const mockConnectorById: { [key: string]: ConnectorV2 } = {
  71: {
    id: 71,
    connectorId: 1,
    connectorType: 'J1772',
  },
}

export const mockConnectors = [
  {
    id: 1,
    listing: mockListing['DP01'],
    charger: mockCharger['1EC1-1-1824-00035'],
    connectorId: 1,
    state: 'SuspendedEV',
    serviceStatus: 'Finishing',
  },
  {
    id: 2,
    listing: mockListing['YK01'],
    charger: mockCharger['1EC1-1-1834-00188'],
    connectorId: 1,
    state: 'Available',
    serviceStatus: 'troubleshooting',
  },
  {
    id: 3,
    listing: mockListing['NA01'],
    charger: mockCharger['1EC1-1-1834-00185'],
    connectorId: 1,
    state: 'Preparing',
    serviceStatus: 'on_hold',
  },
  {
    id: 4,
    listing: mockListing['BN01'],
    charger: mockCharger['1EC0-1-1823-00061'],
    connectorId: 1,
    state: 'Finishing',
    serviceStatus: 'driver_activating',
  },
  {
    id: 5,
    listing: mockListing['BY01'],
    charger: mockCharger['1920511'],
    connectorId: 1,
    state: 'Offline',
    serviceStatus: 'awaiting_commissioning',
  },
  {
    id: 6,
    listing: mockListing['DN01'],
    charger: mockCharger['1EC1-1-1834-00059'],
    connectorId: 1,
    state: 'Charging',
    serviceStatus: 'service_mode',
  },
  { id: 7, charger: mockCharger['1EC1-1-1834-00059'], connectorId: 2, state: 'Faulted', serviceStatus: 'under_repair' },
  {
    id: 8,
    // listing: mockListing['DC01'],
    charger: mockCharger['1EC1-1-1834-00060'],
    connectorId: 1,
    state: 'Unavailable',
    serviceStatus: 'commissioned',
  },
] as Connector[]

export const mockConnectorByListing: { [listingTitle: string]: ConnectorV2 } = {
  L6652: {
    id: 105,
    connectorId: 1,
    serviceStatus: connectorServiceStatus[0],
    ocppStatus: connectorStatus[0],
    connectorType: 'J1772',
    lastStatusChangedAt: generatedMoment.startOf('hour').format(),
  },
}

export const mockConnectorsResp = (
  tenant?: TenantRef,
  ocppStatuses?: ConnectorStatus[],
  serviceStatuses?: ConnectorServiceStatus[],
  charger?: ChargerRef,
  vendors?: string[],
  page?: number,
) => {
  let filterMockConnectors = mockConnectors
  if (ocppStatuses && ocppStatuses.length > 0) {
    filterMockConnectors = mockConnectors.filter((connector) => ocppStatuses.includes(connector.state))
  }

  if (serviceStatuses && serviceStatuses.length > 0) {
    filterMockConnectors = filterMockConnectors.filter(
      (connector) => connector.charger && serviceStatuses.includes(connector.charger.serviceStatus),
    )
  }

  return {
    data: filterMockConnectors,
    pagination: {
      currentPage: page || 0,
      perPage: 20,
      totalEntries: filterMockConnectors.length,
    },
    filters: {
      vendors: Object.values(mockCharger)
        .map((c) => c.chargePointVendor)
        .filter((value, index, self) => {
          return self.indexOf(value) === index
        }),
    },
  } as ConnectorsResponse
}

export const mockConnectorLogsResp = {
  data: [
    {
      message: "Connector transitioned from 'Available' to 'Preparing'",
      success: true,
      createdAt: '2021-07-14T20:47:04-04:00',
      errorCode: '23',
      vendorErrorCode: 'V125',
    },
    {
      message: "Connector transitioned from 'Preparing' to 'Charging'",
      success: true,
      createdAt: '2021-07-14T20:47:04-04:00',
      errorCode: '23',
      vendorErrorCode: 'V125',
    },
    {
      message: "Charger 'OCPIT2' connected",
      success: true,
      createdAt: '2021-07-14T20:45:04-04:00',
      errorCode: '23',
      vendorErrorCode: 'V125',
    },
    {
      message: "Connector transitioned from 'Finishing' to 'Available'",
      success: true,
      createdAt: '2021-07-14T20:45:03-04:00',
      errorCode: '23',
      vendorErrorCode: 'V125',
    },
    {
      message: "Charger 'OCPIT2' disconnected",
      success: true,
      createdAt: '2021-07-14T20:45:03-04:00',
      errorCode: '23',
      vendorErrorCode: 'V125',
    },
    {
      message: "Connector transitioned from 'Charging' to 'Finishing'",
      success: true,
      createdAt: '2021-07-14T20:45:02-04:00',
      errorCode: '23',
      vendorErrorCode: 'V125',
    },
    {
      message: "Connector transitioned from 'Available' to 'Preparing'",
      success: true,
      createdAt: '2021-07-14T20:27:02-04:00',
      errorCode: '23',
      vendorErrorCode: 'V125',
    },
    {
      message: "Connector transitioned from 'Preparing' to 'Charging'",
      success: true,
      createdAt: '2021-07-14T20:27:02-04:00',
      errorCode: '23',
      vendorErrorCode: 'V125',
    },
    {
      message: "Charger 'OCPIT2' connected",
      success: true,
      createdAt: '2021-07-14T20:27:01-04:00',
      errorCode: '23',
      vendorErrorCode: 'V125',
    },
    {
      message: "Connector transitioned from 'Charging' to 'Finishing'",
      success: true,
      createdAt: '2021-07-14T20:27:00-04:00',
      errorCode: '23',
      vendorErrorCode: 'V125',
    },
    {
      message: "Connector transitioned from 'Finishing' to 'Available'",
      success: true,
      createdAt: '2021-07-14T20:27:00-04:00',
      errorCode: '23',
      vendorErrorCode: 'V125',
    },
    {
      message: "Charger 'OCPIT2' disconnected",
      success: true,
      createdAt: '2021-07-14T20:27:00-04:00',
      errorCode: '23',
      vendorErrorCode: 'V125',
    },
    {
      message: "Connector transitioned from 'Preparing' to 'Charging'",
      success: true,
      createdAt: '2021-07-14T20:22:00-04:00',
      errorCode: '23',
      vendorErrorCode: 'V125',
    },
    {
      message: "Connector transitioned from 'Available' to 'Preparing'",
      success: true,
      createdAt: '2021-07-14T20:21:59-04:00',
      errorCode: '23',
      vendorErrorCode: 'V125',
    },
    {
      message: "Charger 'OCPIT2' connected",
      success: true,
      errorCode: null,
      createdAt: '2021-07-14T20:17:59-04:00',
      vendorErrorCode: 'V125',
    },
    {
      message: "Connector transitioned from 'Finishing' to 'Available'",
      success: true,
      createdAt: '2021-07-14T20:17:58-04:00',
      errorCode: '23',
      vendorErrorCode: 'V125',
    },
    {
      message: "Charger 'OCPIT2' disconnected",
      success: false,
      createdAt: '2021-07-14T20:17:58-04:00',
      errorCode: '23',
      vendorErrorCode: 'V125',
    },
    {
      message: "Connector transitioned from 'Charging' to 'Finishing'",
      success: true,
      createdAt: '2021-07-14T20:17:57-04:00',
      errorCode: '23',
      vendorErrorCode: 'V125',
    },
  ],
  pagination: { currentPage: 1, totalEntries: 18, perPage: 50 },
} as ConnectorLogsResponse
