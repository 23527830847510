import { TransactionStateAlias } from 'models/transaction'
import { TransactionFilter } from 'services/data-provider/transaction'

export const transactionQuery = (
  f: TransactionFilter,
  startDate: moment.Moment,
  endDate: moment.Moment,
): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {
    start: `${startDate.format('DD-MM-YYYY')}`,
    end: `${endDate.format('DD-MM-YYYY')}`,
  }
  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.defaultTenant) {
    queryParams['tenant_id'] = `${f.defaultTenant.id}`
  }

  if (f.tenants && f.tenants.length > 0) {
    queryParams['tenant_id'] = `${f.tenants.map((t) => t.id)}`
  }
  if (f.charger) {
    queryParams['charger_id'] = `${f.charger.id}`
  }
  if (f.connector) {
    queryParams['connector_id'] = `${f.connector.id}`
  }
  if (f.startMethod) {
    queryParams['start_method'] = `${f.startMethod}`
  }
  switch (f.state) {
    case 'completed':
      queryParams['state'] = `${TransactionStateAlias['completed']}`
      break
    case 'ongoing':
      queryParams['state'] = `${TransactionStateAlias['ongoing']}`
      break
    case undefined:
      break
    default:
      queryParams['state'] = `${f.state}`
  }
  if (f.transactionId) {
    queryParams['transaction_id'] = `${f.transactionId}`
  }
  if (f.listing) {
    queryParams['listing_id'] = `${f.listing.id}`
  }
  if (f.userName) {
    queryParams['user_name'] = `${f.userName}`
  }
  if (f.userEmail || f.email) {
    queryParams['user_email'] = `${f.userEmail || f.email}`
  }
  if (f.ocppTransactionId) {
    queryParams['ocpp_transaction_id'] = `${f.ocppTransactionId}`
  }
  if (f.country) {
    queryParams['country'] = `${f.country}`
  }
  if (f.province) {
    queryParams['province'] = `${f.province}`
  }
  if (f.listingTitle && f.listingTitle.length > 0) {
    queryParams['listing_title'] = `${f.listingTitle.map((listingTitle) => listingTitle)}`
  }
  if (f.serialNumber && f.serialNumber.length > 0) {
    queryParams['serial_number'] = `${f.serialNumber.map((serial) => serial)}`
  }
  if (f.chargers && f.chargers.length > 0) {
    queryParams['serial_number'] = `${f.chargers.map((serialNumber) => serialNumber)}`
  }
  if (f.masterAccount) {
    queryParams['master_account_ids'] = f.masterAccount.id.toString()
  }

  return queryParams
}
