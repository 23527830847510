import { TrxSummary } from '../../../../models/transaction'
import { useHomeTranslation } from '../../../../hooks/translation/useHomeTranslation'
import { CoreInfoCard } from 'components/cards'
import { formatEnergy } from 'helpers/number-format'

interface props {
  loading: boolean
  trxProfileSummary?: TrxSummary
}

const EnergyMetrics: React.FC<props> = ({ loading, trxProfileSummary }) => {
  const { chargingPeakHours, energyTxt } = useHomeTranslation()
  const labelFormat = {
    label: (value: string | number) => <b>{value}</b>,
    subLabel: (value: string | number) => <span className="default-label">{value}</span>,
  }
  const infoDetails = [
    {
      label: `${
        trxProfileSummary && trxProfileSummary.energyConsumeInPeakHrs && trxProfileSummary.energyConsumeTotal
          ? Math.floor((trxProfileSummary.energyConsumeInPeakHrs / trxProfileSummary.energyConsumeTotal) * 100)
          : '0'
      } %`,
      subLabel: chargingPeakHours,
      labelFormat,
    },
  ]

  return (
    <CoreInfoCard
      title={energyTxt}
      wsLoading={loading}
      infoText={`${
        trxProfileSummary && trxProfileSummary.energyConsumeTotal
          ? formatEnergy(trxProfileSummary.energyConsumeTotal / 1000).toLocaleString()
          : '0'
      } kWh`}
      infoDetails={infoDetails}
    />
  )
}

export default EnergyMetrics
