import React, { useEffect, useState } from 'react'
import HighchartsReact from 'highcharts-react-official'
import * as Highcharts from 'highcharts'
import { ChargerStationInfo } from '../../../../models/chargerv2'
import { useAppState } from '../../../../state'
import { styled } from '../../../../theme'
import { ChartOptionsState } from '../../../../helpers/chart/initial-setup'
import { useDashboardTranslation } from '../../../../hooks/translation/useDashboardTranslation'
import { LegendsContainer } from './legends-container'

interface props {
  chargerStationInfo: ChargerStationInfo
  chartData: {
    label: string
    data?: number
    color: string
  }[]
}

const StationsPieChartWrapper = styled.div`
  .highcharts-tooltip span {
    height: auto;
    width: 240px;
    background-color: ${(props) => props.theme.colors.softBlack};
    color: white;
    overflow: auto;
    white-space: normal !important;
  }

  .highcharts-tooltip div.sub-text {
    opacity: 0.5;
    padding: '15px auto';
  }

  .highcharts-legend {
    pointer-events: none;
  }

  .highcharts-tooltip-box {
    fill-opacity: 1;
  }
`

export const HomePageStationPieChart: React.FC<props> = ({ chargerStationInfo, chartData }) => {
  const { language } = useAppState()
  const {
    onlineChargers,
    offlineChargers,
    underRepairChargers,
    onlineChargersDescription,
    offlineChargersDescription,
    underRepairChargersDescription,
  } = useDashboardTranslation()

  const [chartOptions, setChartOptions] = useState(ChartOptionsState)

  const [hoveredSlice, setHoveredSlice] = useState(-1)

  useEffect(() => {
    setChartOptions({
      chart: {
        plotShadow: false,
        height: 312,
        styledMode: true,
        animation: {
          duration: 1000,
        },
      },
      title: {
        text: '',
      },
      legend: {
        floating: true,
        align: 'left',
        x: 20,
        y: 10,
        symbolRadius: 3,
        layout: 'vertical',
        itemDistance: 70,
        padding: 1,
        itemMarginTop: 0,
        itemMarginBottom: 11,
        itemStyle: {
          lineHeight: '140%',
        },
        labelFormatter: function (): any {
          //@ts-ignore
          var output = `<span class='paragraph-03-regular seriesName'> ${
            //@ts-ignore
            this.name
            //@ts-ignore
          } </span> <span class='paragraph-03-regular'><b> (${this.y})</b></span>`
          return output
        },
      },
      tooltip: {
        shared: false,
        followPointer: false,
        useHTML: true,
        hideDelay: 0,
        formatter: function () {
          //@ts-ignore
          switch (this.key) {
            case onlineChargers:
              //@ts-ignore
              return (
                //@ts-ignore
                `<span class="paragraph-04-regular"> ${onlineChargers} : ${this.y}</span>` +
                `</br>` +
                `<div class="sub-text paragraph-04-regular">${onlineChargersDescription}</div>`
              )
            case offlineChargers:
              //@ts-ignore
              return (
                //@ts-ignore
                `<span class="paragraph-04-regular">${offlineChargers} : ${this.y}</span>` +
                `</br>` +
                `<div class="sub-text paragraph-04-regular">${offlineChargersDescription}</div>`
              )
            case underRepairChargers:
              //@ts-ignore
              return (
                //@ts-ignore
                `<span class="paragraph-04-regular"> ${underRepairChargers} : ${this.y}</span>` +
                `</br>` +
                `<div class="sub-text paragraph-04-regular">${underRepairChargersDescription}</div>`
              )
          }

          //return 'The value for <b>' + this.key + '</b> is <b>' + this.y + '</b>'
        },
        style: {
          maxWidth: '350px',
        },
      },
      plotOptions: {
        pie: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
          startAngle: -115,
          endAngle: 115,
          showInLegend: false,
          slicedOffset: 0,
        },
        series: {
          borderRadius: 12,
          animation: false,
          states: {
            hover: {
              halo: {
                size: 1,
                opacity: 0.4,
                attributes: {
                  fill: '#54DEC7',
                  strokeWidth: 25,
                  stroke: '#54DEC7',
                },
              },
            },
            inactive: {
              enabled: false,
            },
            lineWidth: 5,
          },
          point: {
            events: {
              mouseOver: function () {
                setHoveredSlice((this as any).index)
              },
              mouseOut: function () {
                setHoveredSlice(-1)
              },
            },
          },
        },
      },
      series: [
        {
          type: 'pie',
          innerSize: '60%',
          data: chartData.map((info) => ({
            name: info.label,
            y: info.data || 0,
            sliced: true,
          })),
        },
      ],
    })
  }, [chargerStationInfo, language])

  return (
    chartOptions && (
      <>
        <StationsPieChartWrapper>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </StationsPieChartWrapper>
        <LegendsContainer legends={chartData} hoveredIndex={hoveredSlice} />
      </>
    )
  )
}
