import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { useAppState } from 'state'
import { PermissionType, ResourceAccess, User } from 'models/user'

export const useGoogleAnalytics = (authenticatedRoutes: {
  [path: string]: {
    component: React.ComponentType<any>
    accessibility: {
      admin: boolean
      manager: boolean
      user: boolean
    }
  }
}) => {
  const location = useLocation()
  const { currentUser } = useAppState()

  const allowedDomains = ['staging.admin.swtchenergy.com', 'preprod.admin.swtchenergy.com', 'admin.swtchenergy.com']

  const isAllowedDomain = allowedDomains.includes(window.location.hostname)

  const internalEmail = 'swtchenergy.com'

  const checkIsAuthenticatedRoute = (path: string) => {
    const currentPath = path.split('/')[1]
    for (const route in authenticatedRoutes) {
      const routeHead = route.split('/')[1]
      if (currentPath === routeHead) {
        return true
      }
    }
    return false
  }

  const isInternalUser = (email: string): boolean => email.endsWith(`@${internalEmail}`)

  const getHighestRole = (accesses: ResourceAccess[]) => {
    const rolesPriority = ['Operator', 'Manager', 'Viewer', 'Driver', 'None']
    const rolesPermissions: { [key: string]: PermissionType } = {
      Operator: 'tenant.charger_operator',
      Manager: 'tenant.manager',
      Viewer: 'tenant.viewer',
      Driver: 'tenant.driver',
    }

    return rolesPriority.reduce((highestRole, role) => {
      if (accesses.some((access) => access.permissions.includes(rolesPermissions[role]))) {
        const roleIndex = rolesPriority.indexOf(role)
        const highestRoleIndex = rolesPriority.indexOf(highestRole)

        // Return the role with the higher priority
        return roleIndex < highestRoleIndex ? role : highestRole
      }
      return highestRole
    }, 'None')
  }

  const differentiateRole = (user: User): string => {
    switch (user.role) {
      case 'admin':
        return 'Admin'
      case 'support':
        return 'Support'
      case 'user':
        return getHighestRole(user.accesses)
      default:
        return 'Unknown'
    }
  }

  const sendPageView = (path: string) => {
    if (!currentUser) {
      ReactGA.send({ hitType: 'pageview', page: path })
      return
    }

    const userType = isInternalUser(currentUser.email) ? 'Internal' : 'External'
    const userRole = differentiateRole(currentUser)

    const additionalUserData = {
      user_type: userType,
      user_role: userRole,
    }

    ReactGA.send({ hitType: 'pageview', page: path, ...additionalUserData })
  }

  useEffect(() => {
    if (isAllowedDomain) {
      if (!checkIsAuthenticatedRoute(location.pathname) || currentUser) {
        sendPageView(location.pathname)
      }
    }
  }, [location, currentUser, isAllowedDomain])
}
