import { Select } from 'antd'
import { EmailDomainSelectorWrapper } from 'atom/chargers'
import { useAccessPlanTranslation } from 'hooks/translation/useAccessPlanTranslation'

interface props {
  disabled: boolean
  emailDomains: string[]
  setEmailDomains: (emailDomains: string[]) => void
}

const EmailDomainInput: React.FC<props> = ({ disabled, emailDomains, setEmailDomains }) => {
  const { enterEmailDomainsText } = useAccessPlanTranslation()

  const handleChange = (value: string[]) => {
    if (value.length > 0) {
      const lastItem = value[value.length - 1]
      const formattedItem = `@${lastItem.replace(/^@/, '')}`

      // Prevent duplication and reformat the last item
      if (!emailDomains.includes(formattedItem)) {
        setEmailDomains([...value.slice(0, -1), formattedItem])
      } else {
        setEmailDomains(value.slice(0, -1)) // If duplicate, remove the last item
      }
    } else {
      // If there are no items left, clear the emailDomains state
      setEmailDomains([])
    }
  }

  return (
    <EmailDomainSelectorWrapper>
      <Select
        disabled={disabled}
        mode="tags"
        style={{ width: '100%' }}
        placeholder={enterEmailDomainsText}
        value={emailDomains}
        onChange={handleChange}
        tokenSeparators={[',']}
        open={false}
      />
    </EmailDomainSelectorWrapper>
  )
}

export default EmailDomainInput
