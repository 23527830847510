import { Badge } from 'antd'
import { theme } from 'theme'

interface CoreBadgeProps {
  title: string
  href?: string
}

const CoreBadge: React.FC<CoreBadgeProps> = ({ title, href }) => {
  return (
    <a className={!href ? 'non-clickable' : 'clickable-badge'} href={href} target="_blank" rel="noopener noreferrer">
      <Badge
        key={title}
        count={title}
        overflowCount={Infinity} // Ensures it shows the full count without capping at 99+
        style={{
          backgroundColor: theme.colors.black05,
          color: theme.colors.black,
          boxShadow: `${theme.colors.black05} 0px 0px 0px 0.5px`,
        }}
      />
    </a>
  )
}

export default CoreBadge
