import { notification } from 'antd'
import { errorCheckbox, successCheckbox, warningCheckbox } from '../assets/svg/checkbox'

export const useNotifications = () => {
  const openErrorNotification = (message: React.ReactElement | string) => {
    notification.open({
      message,
      className: 'custom-message-banner error',
      placement: 'bottomRight',
      icon: errorCheckbox,
    })
  }

  const openWarningNotification = (message: React.ReactElement) => {
    notification.open({
      message,
      className: 'custom-message-banner warning',
      placement: 'bottomRight',
      icon: warningCheckbox,
    })
  }

  const openSuccessNotification = (message: React.ReactElement | string) => {
    notification.open({
      message,
      className: 'custom-message-banner success',
      placement: 'bottomRight',
      icon: successCheckbox,
    })
  }

  return { openErrorNotification, openWarningNotification, openSuccessNotification }
}
