import { mockAllTaggingResp, mockListingTaggingResp, mockSuccess, mockTenantTaggingResp } from 'mock/tagging-mock'
import { ApplyTableName, SuccessTaggingResponse, Tagging } from 'models/tagging'
import configProvider from '../../config'
import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import { TaggingFilter } from '../../models/filter'
import { TaggingResponse } from '../../models/http'
import CaseConverter from './case-converter'
import { apiClient } from './client'
import { returnMock } from './mock'

export async function GetAllTagging(filter?: TaggingFilter): Promise<TaggingResponse> {
  let url = '/taggings'

  if (filter) {
    const queryParams = CaseConverter.camelToSnakeCase(filter)

    url = addQueryString(url, queryParams)
  }

  log('get all tagging', { url })
  if (configProvider.config.mockEnabled) {
    if (filter?.tableName === 'Listing') {
      return returnMock({ ...mockAllTaggingResp, data: mockListingTaggingResp })
    } else if (filter?.tableName === 'Tenant') {
      return returnMock({ ...mockAllTaggingResp, data: mockTenantTaggingResp })
    }
    return returnMock(mockAllTaggingResp)
  }

  return apiClient(url, {})
}

export async function GetAllTaggingWithoutPagination(
  isMockUpEnabled: boolean,
  filter?: Omit<TaggingFilter, 'page' | 'perPage'>,
): Promise<Tagging[]> {
  let url = '/taggings'

  let queryParams = CaseConverter.camelToSnakeCase({ allTaggings: true })
  if (filter) {
    queryParams = { ...queryParams, ...CaseConverter.camelToSnakeCase(filter) }
  }

  url = addQueryString(url, queryParams)

  log('get all tagging without pagination', { url })
  if (isMockUpEnabled) {
    if (filter?.tableName === 'Listing') {
      return returnMock(mockListingTaggingResp)
    } else if (filter?.tableName === 'Tenant') {
      return returnMock(mockTenantTaggingResp)
    }
    return returnMock(mockAllTaggingResp.data)
  }

  return apiClient(url, {})
}

export async function CreateTagging(
  tag: Omit<Tagging, 'id' | 'createdAt' | 'createdBy' | 'taggedObjects'>,
): Promise<Tagging> {
  const url = '/taggings'

  log('Create New Tagging', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockAllTaggingResp.data[0])
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      tagging: {
        ...CaseConverter.camelToSnakeCase(tag),
      },
    }),
  })
}

export async function UpdateTagging(tag: Tagging): Promise<Tagging> {
  const url = `/taggings/${tag.id}`

  log('update tagging', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockAllTaggingResp.data[0])
  }

  const { id, ...tagUpdateParams } = tag

  return apiClient(url, {
    method: 'PATCH',
    body: JSON.stringify({
      tagging: {
        ...CaseConverter.camelToSnakeCase(tagUpdateParams),
      },
    }),
  })
}

export async function ApplyTags(
  taggings: { id: number }[],
  objects: { id: number }[],
  tableName: ApplyTableName,
): Promise<SuccessTaggingResponse> {
  const url = '/taggings/apply'

  log('apply tags', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockSuccess)
  }

  const body = {
    taggings,
    [tableName]: objects,
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}
