import { NewListing } from './listing'

export const priceType = ['Hour', 'kWh'] as const
export type PriceType = typeof priceType[number]

export interface Price extends TimeOfDayPrice {
  preferedPricePerUnitCents?: number
  pricePerUnitCents: number
  priceType: PriceType
  preferedPricingPriceType: PriceType
  flatFee: number | string
  enableTimeOfDayPricing: boolean
}

export interface TimeOfDayPrice {
  timeOfDayPriceWeekday: number[]
  timeOfDayPriceWeekend: number[]
}

export interface UsersDiscount {
  email: string
}

export interface LocalDiscount {
  id: number
  name: string
}
export interface PricingSchema {
  id: number
  tenantId: number
  name: string
  stripeAccountId?: number
  state?: string
  priceType: string
  flatFeeCents?: number
  demandResponseFeeCents?: number
  guestFeeCents?: number
  commissionPercent?: string | number
  paymentLimitCents?: number
  enableAddtionalServiceFee?: boolean
  enablePricingCurtailment?: boolean
  loiteringType?: string
  loiteringTimeLimitMinutes: number
  loiteringGracePeriodMinutes: number
  loiteringMaximumCents?: number
  timeOfDayPriceWeekday: number[]
  timeOfDayPriceWeekend: number[]
  timeOfDayLoiteringWeekday: number[]
  timeOfDayLoiteringWeekend: number[]
  appliedListings?: ListingInfo[]
}

export interface ListingInfo {
  listingId: number
  listingTitle: string
}

export interface PricingSchemaPayload {
  tenant_id: number
  id?: number
  name: string
  stripe_account_id?: number
  state?: string
  price_type?: string
  flat_fee_cents?: number
  flat_fee?: number
  demand_response_fee_cents?: number
  guest_fee_cents?: number
  guest_fee?: number
  commission_percent?: string
  payment_limit_cents?: number
  enable_addtional_service_fee?: boolean
  enable_pricing_curtailment?: boolean
  enable_time_of_day?: boolean
  loitering_type?: string
  loitering_time_limit_minutes?: number
  loitering_grace_period_minutes?: number
  loitering_maximum_cents?: number
  loitering_maximum?: number
  enable_time_of_day_loitering?: boolean
  time_of_day_price_weekday: number[]
  time_of_day_price_weekend: number[]
  time_of_day_loitering_weekday: number[]
  time_of_day_loitering_weekend: number[]
  weekdayRateExtras?: PricingSchemaEventSchedule[]
  weekendRateExtras?: PricingSchemaEventSchedule[]
  weekdayLoiteringRateExtras?: PricingSchemaEventSchedule[]
  weekendLoiteringRateExtras?: PricingSchemaEventSchedule[]
  timeOfDayPriceWeekday?: number[]
  timeOfDayPriceWeekend?: number[]
  timeOfDayLoiteringWeekday?: number[]
  timeOfDayLoiteringWeekend?: number[]
  listing?: number
  dollar_rate?: number
  loitering_dollar_rate?: number
  applied_listings?: NewListing[] | BasicListingInfo[]
  payment_limit?: number
}

export interface PricingSchemaEventSchedule {
  from: number
  to: number
  dollar_rate?: number | string | undefined
}

export interface BasicListingInfo {
  id: number
  title: string
}

export interface BasicListingInfo {
  id: number
  title: string
}
