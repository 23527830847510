import CoreTabs from 'components/tabs/core-tabs'
import { useRef } from 'react'
import { Card, Col, Row } from 'antd'
import { Section } from '../../../atom/box'
import { styled, theme } from '../../../theme'
import { useFormatMessage } from '../../../helpers/intl'
import { HomePageOperationOverviewChart } from '../charts/active-users/operation-overview'
import { calculateTransactionInterval } from '../../../services/data-provider/transactionv2'
import { TrxnOverview } from '../../../models/transaction'
import { CardWrapper } from '../../../atom/card'
import { useMediaQuery } from 'react-responsive'

interface styledProps {
  isResponsiveStyling?: boolean
}

const OperationOverviewTabsContainer = styled.div<styledProps>`
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: ${(props) => (props.isResponsiveStyling ? 'center' : 'end')};
  }

  .ant-tabs-tab {
    padding: ${(props) => (props.isResponsiveStyling ? '0px' : '3px')};
  }

  .ant-tabs-tab-btn {
    padding: ${(props) => (props.isResponsiveStyling ? '8.5px 13px' : '7.5px 20px')};
  }

  .ant-tabs-tab-btn > span {
    font-size: ${(props) => (props.isResponsiveStyling ? '10px' : '12px')};
  }

  .ant-picker.ant-picker-range {
    width: ${(props) => (props.isResponsiveStyling ? '100%' : '12px')};
  }
`

interface props {
  loading: boolean
  startDate: moment.Moment
  endDate: moment.Moment
  trnxOverview?: TrxnOverview[]
  operationOverviewTabKey: string
  reveneChartVisible: boolean
  energyChartVisible: boolean
  transactionsChartVisible: boolean
  onHandleTabChangeOperationOverviewTab: (activeKey: string) => void
}

const OperationOverview: React.FC<props> = ({
  loading,
  startDate,
  endDate,
  trnxOverview,
  operationOverviewTabKey,
  reveneChartVisible,
  energyChartVisible,
  transactionsChartVisible,
  onHandleTabChangeOperationOverviewTab,
}) => {
  const isResponsiveStyling = useMediaQuery({ minWidth: 0, maxWidth: 649 })
  const overviewChart = useRef(null)
  const tabsInfo = [
    {
      title: useFormatMessage('dashboard.homePage.transaction', 'Transactions'),
      key: 'Transactions',
      content: trnxOverview && transactionsChartVisible && (
        <HomePageOperationOverviewChart
          trnxOverview={trnxOverview}
          tabKey={operationOverviewTabKey}
          chartRef={overviewChart}
          transactionInterval={calculateTransactionInterval(startDate, endDate)}
        />
      ),
    },
    {
      title: useFormatMessage('dashboard.homePage.revenue', 'Revenue'),
      key: 'Revenue',
      content: trnxOverview && reveneChartVisible && (
        <HomePageOperationOverviewChart
          trnxOverview={trnxOverview}
          tabKey={operationOverviewTabKey}
          chartRef={overviewChart}
          transactionInterval={calculateTransactionInterval(startDate, endDate)}
        />
      ),
    },
    {
      title: useFormatMessage('dashboard.homePage.energy', 'Energy'),
      key: 'Energy',
      content: trnxOverview && energyChartVisible && (
        <HomePageOperationOverviewChart
          trnxOverview={trnxOverview}
          tabKey={operationOverviewTabKey}
          chartRef={overviewChart}
          transactionInterval={calculateTransactionInterval(startDate, endDate)}
        />
      ),
    },
  ]

  return (
    <Section style={{ margin: '0px auto 30px' }}>
      <Row align="middle" justify="start" gutter={[15, 15]} className="content">
        <Col span={24} style={{ background: theme.colors.white }}>
          <CardWrapper>
            <Card loading={loading}>
              <OperationOverviewTabsContainer isResponsiveStyling={isResponsiveStyling}>
                <CoreTabs
                  onChange={onHandleTabChangeOperationOverviewTab}
                  activeKey={operationOverviewTabKey}
                  tabs={tabsInfo}
                  downloadable={{ chart: overviewChart }}
                />
              </OperationOverviewTabsContainer>
            </Card>
          </CardWrapper>
        </Col>
      </Row>
    </Section>
  )
}

export default OperationOverview
