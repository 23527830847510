import { Alert, Button, Col, Modal, Row, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { ResponsiveTable } from '../../atom/responsive-table'
import { useFormatMessage } from '../../helpers/intl'
import { Charger } from '../../models/charger'
import { RemoteGetConfigurationResponse, RemoteMessageNotConnectedResp } from '../../models/charger-remote'
import { SwtchError } from '../../models/error'
import { RemoteGetConfiguration } from '../../services/data-provider/charger-remote'
import { useAppState } from 'state'

interface props {
  charger: Charger
}

export const RemoteGetConfigurationController: React.FC<props> = ({ charger }) => {
  const { isMockUpEnabled } = useAppState()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [message, setMessage] = useState<RemoteGetConfigurationResponse['message']>([])
  const [connectionMessage, setConnectionMessage] = useState<RemoteMessageNotConnectedResp['error']>('')
  const [error, setError] = useState<SwtchError>()

  const getchargerconfigurationtext = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.getConfiguration.getchargerconfiguration',
    'Get 1234 Configuration',
  )

  const sendchargerconfigurationtext = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.getConfiguration.sendchargerconfiguration',
    'Sending get configuration request to',
  )

  const callResultTxt = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.getConfiguration.table.callresult',
    'Call Result',
  )

  const callIDTxt = useFormatMessage('dashboard.chargerPage.remoteControlTab.getConfiguration.table.callid', 'Call ID')

  const modalHeading = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.getConfiguration.modal.heading',
    "1234's Configuration",
  )

  const chargerConfigurationRetrieved = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.getConfiguration.modal.configurationRetrieved',
    'Charger 1234 configuration retrieved',
  )

  const chargerConfigurationDidNotRetrieve = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.getConfiguration.modal.configurationDidNotRetrieve',
    'Failed to retrieve Charger 1234 configuration',
  )

  const columns = [
    {
      title: useFormatMessage('dashboard.chargerPage.remoteControlTab.getConfiguration.key', 'Key'),
      dataIndex: 'key',
      key: 'key',
      render: (text: string) => text,
    },
    {
      title: useFormatMessage('dashboard.chargerPage.remoteControlTab.getConfiguration.readonly', 'Read Only'),
      dataIndex: 'readonly',
      key: 'readonly',
      render: (text: boolean) => '' + text,
    },
    {
      title: useFormatMessage('dashboard.chargerPage.remoteControlTab.getConfiguration.value', 'Value'),
      dataIndex: 'value',
      key: 'value',

      render: (text: string) => text,
    },
  ]

  const resetView = (loading: boolean) => {
    setLoading(loading)
    setSuccess(false)
    setMessage([])
    setConnectionMessage('')
    setIsModalVisible(false)
    setError(undefined)
  }

  useEffect(() => {
    resetView(false)
  }, [])

  const showModal = () => setIsModalVisible(true)

  const handleOk = () => setIsModalVisible(false)

  const handleCancel = () => resetView(false)

  const handleGetConfiguration = () => {
    resetView(true)
    RemoteGetConfiguration(isMockUpEnabled, charger)
      .then((resp) => {
        setSuccess(resp.success)
        if (resp.message && Array.isArray(resp.message)) {
          setMessage(resp.message)
          showModal()
        } else {
          setConnectionMessage(resp.message.error)
        }
      })
      .catch((err: SwtchError) => {
        setError(err)
        setSuccess(false)
        setMessage([])
        setConnectionMessage('')
      })
      .finally(() => setLoading(false))
  }
  const renderMessages = () => {
    return (
      <>
        {error && <Alert message={error.description} type={'error'} />}
        {Array.isArray(message) && message.length !== 0 && (
          <>
            {success && (
              <Alert
                message={chargerConfigurationRetrieved.replace('1234', charger.chargePointSerialNumber)}
                type={'info'}
              />
            )}
            {!success && (
              <Alert
                message={chargerConfigurationDidNotRetrieve.replace('1234', charger.chargePointSerialNumber)}
                type={'error'}
              />
            )}
          </>
        )}
        {!success && connectionMessage && <Alert message={connectionMessage} type={'error'} />}
      </>
    )
  }

  const renderModal = () => {
    return (
      <Modal
        title={modalHeading.replace('1234', charger.chargePointSerialNumber)}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={980}
      >
        <>
          {Array.isArray(message) && message.length !== 0 && (
            <>
              <ResponsiveTable
                columns={columns}
                dataSource={message[2].configurationKey}
                pagination={false}
                title={() => {
                  return (
                    <>
                      <p>{`${callResultTxt}: ${message[0]}`}</p>
                      <p>{`${callIDTxt}: ${message[1]}`}</p>
                    </>
                  )
                }}
              />
            </>
          )}
        </>
      </Modal>
    )
  }

  return (
    <Row>
      <Col span={24}>
        <p>
          {useFormatMessage(
            'dashboard.chargerPage.remoteControlTab.getConfiguration.text',
            'Click below to get the charger configuration',
          )}
        </p>
        <Button type="primary" onClick={handleGetConfiguration} loading={loading}>
          {getchargerconfigurationtext.replace('1234', charger.chargePointSerialNumber)}
        </Button>
        {isModalVisible && renderModal()}
        <Col span={12} style={{ paddingTop: '10px' }}>
          {loading && (
            <Alert
              message={
                <div>
                  <Spin style={{ marginRight: '10px' }} />
                  {sendchargerconfigurationtext} {charger.chargePointSerialNumber}!
                </div>
              }
            />
          )}
          {!loading && renderMessages()}
        </Col>
      </Col>
    </Row>
  )
}
