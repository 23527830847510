import configProvider from 'config'
import { User } from 'models/user'
import { theme } from 'theme'

interface Props {
  currentUser?: User
}

const MockStamp: React.FC<Props> = ({ currentUser }) => {
  return (
    <div
      style={{
        color: theme.colors.dangerPrimary,
        border: `1px solid ${theme.colors.dangerPrimary}`,
        fontSize: theme.fontSizes[2],
        position: 'absolute',
        backgroundColor: theme.colors.white,
        transform: 'rotate(-10deg) scale(2) translateX(30%) translateY(-50%)',
        zIndex: 1,
      }}
    >
      {configProvider.config.demoEmails.map((email) => email.includes(currentUser?.email || '')) ? 'DEMO' : 'MOCK'}
    </div>
  )
}

export default MockStamp
