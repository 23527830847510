import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'

export const useGeneralTranslation = () => {
  const accessText = useFormatMessage('dashboard.text.access', 'Access')
  const activeText = useFormatMessage('dashboard.text.active', 'Active')
  const cancelText = useFormatMessage('dashboard.reports.form.sendReport.cancelText', 'Cancel')
  const createText = useFormatMessage('dashboard.button.create', 'Create')
  const countryText = useFormatMessage('dashboard.text.country', 'Country')
  const connectivityText = useFormatMessage('dashboard.text.connectivity', 'Connectivity')
  const currencyText = useFormatMessage('dashboard.text.currency', 'Currency')
  const descriptionText = useFormatMessage('dashboard.text.description', 'Description')
  const filterText = useFormatMessage('dashboard.text.filter', 'Filter')
  const inactiveText = useFormatMessage('dashboard.text.inactive', 'Inactive')
  const intervalText = useFormatMessage('dashboard.text.interval', 'Interval')
  const okText = useFormatMessage('dashboard.button.create', 'Create')
  const titleText = useFormatMessage('dashboard.text.name', 'Name')
  const priceText = useFormatMessage('dashboard.text.price', 'Price')
  const refreshText = renderFormatMessage('dashboard.text.refresh', 'Refresh')
  const statusText = useFormatMessage('dashboard.text.status', 'Status')
  const listingsText = useFormatMessage('dashboard.listing.ListingsText', 'Listings')
  const searchText = useFormatMessage('dashboard.text.search', 'Search')
  const startDateText = useFormatMessage('dashboard.mobileCalendar.startDate', 'Start Date')
  const endDateText = useFormatMessage('dashboard.mobileCalendar.endDate', 'End Date')
  const unavailableText = useFormatMessage('dashboard.homePage.unavailable', 'Unavailable')
  const displayNameText = useFormatMessage('dashboard.tenantPage.detailTab.displayName.heading', 'Display Name')

  const omOptinYesText = renderFormatMessage('dashboard.tenantPage.overviewTab.o_m_opt_in.yes', 'Yes')

  const omOptinNoText = renderFormatMessage('dashboard.tenantPage.overviewTab.o_m_opt_in.no', 'No')

  const noteText = useFormatMessage('dashboard.tenantPage.notes', 'Notes')

  const modifyText = renderFormatMessage('dashboard.tenantPage.overviewTab.modifybtn', 'Modify')

  const saveText = useFormatMessage('dashboard.tenantPage.overviewTab.saveBtn', 'Save')
  const tenantText = useFormatMessage('dashboard.text.tenant', 'Site')
  const connectedText = useFormatMessage('dashboard.text.connected', 'Connected')
  const disconnectedText = useFormatMessage('dashboard.text.disconnected', 'Disconnected')
  const chargingStartText = useFormatMessage('dashboard.transactionsPage.chargingStart', 'Charging Start')
  const chargingEndText = useFormatMessage('dashboard.transactionsPage.chargingEnd', 'Charging End')
  const noDataForText = useFormatMessage('dashboard.text.noDataFor', 'noDataFor')
  const noDataToDisplayText = useFormatMessage('dashboard.text.nodataavailable', 'Sorry, no data to display.')
  const transactionsText = useFormatMessage('dashboard.text.transactions', 'Transactions')
  const transactionText = useFormatMessage('dashboard.text.transaction', 'Transaction')
  const numberOfTransactionsText = useFormatMessage('dashboard.text.numberOfTransactions', 'Number of Transactions')
  const resetText = useFormatMessage('dashboard.tenantPage.detailTab.resetbtn', 'Reset')
  const revenueText = useFormatMessage('dashboard.homePage.revenue', 'Revenue')
  const revenuesText = useFormatMessage('dashboard.homePage.revenues', 'Revenues')
  const energyText = useFormatMessage('dashboard.homePage.energy', 'Energy')
  const applyText = useFormatMessage('dashboard.text.apply', 'Apply')
  const chargingDurationText = useFormatMessage('dashboard.transactionsPage.chargingDuration', 'Charging Duration')
  const selectText = useFormatMessage('dashboard.text.select', 'Select')
  const updateText = useFormatMessage('dashboard.text.update', 'Update')
  const confirmText = useFormatMessage('dashboard.mobileCalendar.confirm', 'Confirm')
  const chargeDurationText = useFormatMessage('dashboard.text.chargeDuration', 'Charge Duration')
  const nextText = useFormatMessage('dashboard.pagination.next', 'Next')
  const discountPlanText = useFormatMessage('dashboard.tenantPage.pricingSchemaTab.discountPlan', 'Discount Plan')
  const pricingPlanText = useFormatMessage('dashboard.tenantPage.pricingSchemaTab.pricingPlan', 'Pricing Plan')

  const yesText = useFormatMessage('dashboard.text.yes', 'Yes')
  const noText = useFormatMessage('dashboard.text.no', 'No')
  const editText = useFormatMessage('dashboard.text.edit', 'Edit')
  const continueText = useFormatMessage('dashboard.text.continue', 'CONTINUE')

  return {
    accessText,
    activeText,
    applyText,
    cancelText,
    chargingStartText,
    chargingEndText,
    chargingDurationText,
    chargeDurationText,
    connectivityText,
    countryText,
    connectedText,
    createText,
    currencyText,
    descriptionText,
    disconnectedText,
    energyText,
    filterText,
    inactiveText,
    intervalText,
    listingsText,
    okText,
    noDataForText,
    noDataToDisplayText,
    numberOfTransactionsText,
    priceText,
    refreshText,
    titleText,
    statusText,
    searchText,
    startDateText,
    endDateText,
    tenantText,
    unavailableText,
    displayNameText,
    omOptinYesText,
    omOptinNoText,
    noteText,
    modifyText,
    saveText,
    selectText,
    revenueText,
    revenuesText,
    transactionsText,
    transactionText,
    resetText,
    updateText,
    confirmText,
    nextText,
    discountPlanText,
    pricingPlanText,
    yesText,
    noText,
    editText,
    continueText,
  }
}
