import configProvider from '../../config'
import { DiscountResponse, PricingResponse } from '../../models/http'
import { log } from '../../logger'
import { apiClient } from './client'
import { PricingSchema, PricingSchemaPayload } from '../../models/price'
import { returnMock } from './mock'
import { mockPricingSchema, mockPricingSchemaNamesResp, mockPricingSchemaResp } from '../../mock/pricing-schema-mock'

export async function GetPricings(
  page: number,
  tenantId: number,
  pricingSchemaName?: string,
): Promise<PricingResponse> {
  let url = `/tenants/${tenantId}/pricing_schemas?page=${page}`

  if (pricingSchemaName) {
    url = `/tenants/${tenantId}/pricing_schemas?page=${page}&name=${pricingSchemaName}`
  }

  log('getting pricing schemas', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPricingSchemaResp(page))
  }
  return apiClient(url, {})
}

export async function CreatePricings(pricingSchema: PricingSchemaPayload, tenantId: number): Promise<PricingSchema> {
  const url = `/tenants/${tenantId}/pricing_schemas`

  log('pricing schema here', pricingSchema)
  log('Creating pricing schema here', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPricingSchema[0])
  }
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      pricing_schema: { ...pricingSchema },
    }),
  })
}

export async function GetPricingNames(tenantId: number): Promise<string[]> {
  const url = `/tenants/${tenantId}/pricing_schemas/names`

  log('Getting pricing schema names', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPricingSchemaNamesResp())
  }
  return apiClient(url, {})
}

export async function UpdatePricings(
  pricingSchema: PricingSchemaPayload,
  tenantId: number,
  pricingSchemaId: number,
): Promise<PricingSchema> {
  const url = `/tenants/${tenantId}/pricing_schemas/${pricingSchemaId}`

  log('Updating pricing schema', { url })

  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      pricing_schema: { ...pricingSchema },
    }),
  })
}

export async function GetDiscountsFromPricings(tenantId: number, pricingSchemaId: number): Promise<DiscountResponse> {
  const url = `/tenants/${tenantId}/pricing_schemas/${pricingSchemaId}/discounts`

  log('getting discount schemas', { url: url })
  if (configProvider.config.mockEnabled) {
    // return returnMock(mockPricingSchemaResp(page))
  }
  return apiClient(url, {})
}
