import { Financial } from 'models/transaction'

export const financialMock = [
  {
    subtotal: 0,
    total: 330,
    tax: 0,
    serviceFee: 0,
    tenantAmount: 0,
    propertySubtotal: 0,
    propertyTax: 0,
    currency: 'USD',
  },
  {
    subtotal: 435,
    total: 1492,
    tax: 57,
    serviceFee: 50,
    tenantAmount: 442,
    propertySubtotal: 0,
    propertyTax: 3,
    currency: 'USD',
  },
  {
    subtotal: 14,
    total: 330,
    tax: 2,
    serviceFee: 0,
    tenantAmount: 0,
    propertySubtotal: 0,
    propertyTax: 0,
    currency: 'USD',
  },
  {
    subtotal: 14,
    total: 225,
    tax: 2,
    serviceFee: 0,
    tenantAmount: 0,
    propertySubtotal: 0,
    propertyTax: 0,
    currency: 'USD',
  },
  {
    subtotal: 14,
    total: 465,
    tax: 2,
    serviceFee: 0,
    tenantAmount: 0,
    propertySubtotal: 0,
    propertyTax: 0,
    currency: 'USD',
  },
] as Financial[]

export const generateFinancialMock = (totalPriceInCents: number) => {
  return {
    subtotal: 0,
    total: totalPriceInCents,
    tax: 0,
    serviceFee: 0,
    tenantAmount: 0,
    propertySubtotal: 0,
    propertyTax: 0,
    currency: 'USD',
  }
}
