import moment from 'moment'

import configProvider from '../../config'
import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import { calculateTransactionMetrics, mockTransactionResp, mockTransactions } from '../../mock/transaction-mock'
import { TransactionMetric } from '../../models/analytics'
import { ChargerRef } from '../../models/charger'
import { Connector } from '../../models/connector'
import { TransactionResponse } from '../../models/http'
import { ListingRef } from '../../models/listing'
import { TenantRef } from '../../models/tenant'
import { Transaction, TransactionStartMethod, TransactionStateV1ToV2 } from '../../models/transaction'
import { apiClient } from './client'
import { returnMock } from './mock'
import { MasterAccountRef } from 'models/master-account'
import { transactionQuery } from 'helpers/query/TransactionQuery'

export interface TransactionFilter {
  page?: number
  defaultTenant?: TenantRef
  tenants?: TenantRef[]
  charger?: ChargerRef
  chargers?: string[]
  serialNumber?: string[]
  connector?: Connector
  startMethod?: TransactionStartMethod
  state?: TransactionStateV1ToV2
  transactionId?: string | number
  listing?: ListingRef
  userName?: string
  userEmail?: string
  email?: string
  ocppTransactionId?: string
  country?: string
  province?: string
  listingTitle?: string[]
  masterAccount?: MasterAccountRef
}

export async function GetTransactions(
  props: TransactionFilter,
  startDate: moment.Moment,
  endDate: moment.Moment,
  isMockUpEnabled: boolean,
  masterAccount?: MasterAccountRef,
): Promise<TransactionResponse> {
  let url = '/transactions'

  const queryParams = transactionQuery({ ...props, masterAccount: masterAccount }, startDate, endDate)
  url = addQueryString(url, queryParams)

  log('fetching transactions', { url })

  if (isMockUpEnabled) {
    return returnMock(mockTransactionResp(props, masterAccount))
  }
  return apiClient(url, {})
}

export async function GetTransaction(transactionId: number): Promise<Transaction> {
  const url = `/transactions/${transactionId}`
  log('fetching a transaction', { url: url })
  if (configProvider.config.mockEnabled) {
    const index = mockTransactions.findIndex((mockTransaction) => {
      return mockTransaction.id === transactionId
    })
    return returnMock({ ...mockTransactions[index] })
  }
  return apiClient(url, {})
}

export async function GetTransactionsStats(
  props: TransactionFilter,
  startDate: moment.Moment,
  endDate: moment.Moment,
  isMockUpEnabled: boolean,
  masterAccount?: MasterAccountRef,
): Promise<TransactionMetric> {
  let url = '/transactions/total'

  const queryParams = transactionQuery({ ...props, masterAccount: masterAccount }, startDate, endDate)
  url = addQueryString(url, queryParams)
  log('fetching transactions', { url: url })
  if (isMockUpEnabled) {
    return returnMock(calculateTransactionMetrics(mockTransactionResp(props, masterAccount)))
  }
  return apiClient(url, {})
}

export async function StartTransaction(transaction: Transaction): Promise<Transaction> {
  const url = `/transactions/${transaction.id}/start`
  log('remote start transaction', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock({ ...mockTransactions[0], id: transaction.id, currentState: 'no_reservation' })
  }
  return apiClient(url, { method: 'POST' })
}

export async function ErrorTransaction(transaction: Transaction): Promise<Transaction> {
  const url = `/transactions/${transaction.id}/error`
  log('remote start transaction', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock({ ...mockTransactions[0], id: transaction.id, currentState: 'errored' })
  }
  return apiClient(url, { method: 'POST' })
}

export async function StopTransaction(transaction: Transaction): Promise<Transaction> {
  const url = `/transactions/${transaction.id}/stop`
  log('remote stop transaction', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock({ ...mockTransactions[0], id: transaction.id, currentState: 'confirmed' })
  }
  return apiClient(url, { method: 'POST' })
}
