import { useEffect, useState } from 'react'

import { SwtchError } from 'models/error'
import { ParticipantsFilter } from 'models/filter'
import { PaginationMeta } from 'models/pagination'

import { Col, Dropdown, Menu, Row } from 'antd'
import { DotsSVG } from 'assets/svg/dots'
import { CustomSuccessMessage } from 'components/peakShaving/message'
import { Participants, PeakShavingProgram } from 'models/peak-shaving'
import { TenantRef } from 'models/tenant'
import { GetAllParticipants, RemoveParticipant } from 'services/data-provider/peak-shaving'
import { usePeakShavingTranslation } from './translation/usePeakShavingTranslation'
import { useSelector } from './useSelector'

export const useDemandManagementParticipants = () => {
  const [participants, setParticipants] = useState<Participants[]>([])
  const [newParticipantsModalVisible, setNewParticipantsModalVisible] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [pagination, setPagination] = useState<PaginationMeta>()

  const [selectedParticipant, setSelectedParticipant] = useState<Participants>()

  const { siteLabelCleaner } = useSelector()

  const [filter, setFilter] = useState<ParticipantsFilter>({
    page: 1,
    perPage: 20,
  })

  const removemMessageKey = 'removeParticipantMessageKey'

  const { tenantText, listingsText, programText, participantsTabDeleteText } = usePeakShavingTranslation()

  // Future implementation when BE is ready
  // const selectParticipantToEdit = (participant: Participants) => () => {
  //   setSelectedParticipant(participant)
  //   setNewParticipantsModalVisible(true)
  // }

  const selectParticipantToRemove = (participant: Participants) => () => {
    setSelectedParticipant(participant)
    setShowWarning(true)
  }

  const cancelRemoval = () => {
    setSelectedParticipant(undefined)
    setShowWarning(false)
  }

  const columns = [
    {
      title: tenantText,
      dataIndex: 'tenant',
      width: '30%',
      render: (tenant: TenantRef) =>
        siteLabelCleaner(`${tenant.displayName ? `${tenant.displayName} ` : ''}(${tenant.name || ''})`),
    },
    {
      title: listingsText,
      width: '30%',
      render: (participant: Participants) => (participant.allListing ? 'All' : participant.listing?.title),
    },
    {
      title: programText,
      dataIndex: 'programs',

      render: (programs: PeakShavingProgram[]) => (
        <Col>
          {programs.map((program) => (
            <Row key={program.id}>{`${program.aggregatorName ? `${program.aggregatorName}-` : ''}${program.name}`}</Row>
          ))}
        </Col>
      ),
    },
    {
      title: '',
      render: (participant: Participants) => {
        return (
          <Dropdown
            overlay={
              <Menu className="table-dot-dropdown">
                {/*
                Future implementation for Edit participant
                <Menu.Item onClick={selectParticipantToEdit(participant)}>
                  <p className="paragraph-02-regular">{participantsTabEditText}</p>
                </Menu.Item> */}
                <Menu.Item onClick={selectParticipantToRemove(participant)}>
                  <p className="paragraph-02-regular">{participantsTabDeleteText}</p>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <span onClick={(e) => e.preventDefault()}>{<DotsSVG className="edit-event-icon" />}</span>
          </Dropdown>
        )
      },
      width: '80px',
    },
  ]

  useEffect(() => {
    fetchParticipants()
  }, [])

  const handleParticipantModal = () => {
    const changedState = !newParticipantsModalVisible
    if (!changedState) {
      setSelectedParticipant(undefined)
    }
    setNewParticipantsModalVisible(changedState)
  }

  const removeParticipant = () => {
    if (!selectedParticipant) return
    setLoading(true)
    setError(undefined)
    RemoveParticipant(selectedParticipant.id)
      .then(({ response }) => {
        cancelRemoval()
        CustomSuccessMessage({ key: removemMessageKey, response })
        fetchParticipants()
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  const fetchParticipants = (page = 1) => {
    setLoading(true)
    setError(undefined)

    GetAllParticipants({ ...filter, page })
      .then((data) => {
        setParticipants(data)
        setPagination(pagination)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  const fetchDataWithQuery = () => {
    fetchParticipants()
  }

  const handlePaginationChange = (page: number, pageSize?: number) => {
    fetchParticipants(page)
    return
  }

  const onSubmit = () => {
    handleParticipantModal()
    fetchParticipants()
  }

  return {
    error,
    loading,
    pagination,
    filter,
    participants,
    columns,
    newParticipantsModalVisible,
    fetchParticipants,
    fetchDataWithQuery,
    setFilter,
    handlePaginationChange,
    handleParticipantModal,
    onSubmit,
    removeParticipant,
    cancelRemoval,
    showWarning,
    selectedParticipant,
  }
}
