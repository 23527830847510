import { addQueryString } from 'helpers/url'
import { log } from '../../logger'
import { apiClient } from './client'
import { mockPartnersRef } from './mock'
import { TenantPartnersResponse } from 'models/http'

export async function FindPartners(isMockUpEnabled: boolean, name?: string): Promise<TenantPartnersResponse> {
  let url = '/partners'

  let queryParams: { [key: string]: string | boolean } = {}

  if (name) {
    queryParams['name'] = `${name}`
  }
  url = addQueryString(url, queryParams)

  log('fetching partners', { url: url })
  if (isMockUpEnabled) {
    return Promise.resolve(mockPartnersRef)
  }
  return apiClient(url, {})
}
