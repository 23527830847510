import React, { ReactElement, useEffect, useState } from 'react'

import { Form, Input, Modal, Select, Space } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { DropdownWrapper } from 'atom/user-edit'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { ColumnTypes, EditableTable } from 'components/table/editable-table'
import { useSelector } from 'hooks/useSelector'
import { ListingRef } from 'models/listing'
import { PeakShavingProgram } from 'models/peak-shaving'
import styled from 'styled-components'
import { closeSVG } from '../../../assets/svg/close'
import { PeakShavingSelectArrowDown } from '../../../assets/svg/select'
import { ButtonModal } from '../../../atom/button'
import { layout } from '../../../atom/form/page-layout'
import { AlertError } from '../../../components/error'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { SwtchApiError } from '../../../models/error'
import { AddParticipantsToProgram } from 'services/data-provider/peak-shaving'
import { CustomSuccessMessage } from 'components/peakShaving/message'
import { useAppState } from 'state'

interface props {
  programId: number
  visible: boolean
  onCancel(): void
  onSubmit: (updatedProgram?: PeakShavingProgram) => void
  initialValues?: Store //This indicates Edit modal
}

interface DataType {
  key: React.Key
  title: string
  id: number
  participantId?: string
}

const AddParticipantModalContainer = styled.div`
  .ant-modal-footer {
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-footer .ant-btn {
    min-width: 240px;
  }
`

export const AddParticipantModal: React.FC<props> = ({ programId, visible, onCancel, onSubmit, initialValues }) => {
  const { isMockUpEnabled } = useAppState()
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const [error, setError] = useState<SwtchApiError>()
  const messageKey = initialValues ? 'updateParticipantMessageKey' : 'addParticipantMessageKey'

  const [allListingStatus, setAllListingStatus] = useState<string | undefined>(undefined)

  const [selectedListings, setSelectedListings] = useState<(ListingRef & { participantId?: string })[]>([])

  const [dataSource, setDataSource] = useState<DataType[]>([])

  const {
    cancelText,
    submitText,
    isRquiredText,

    addParticipantsText,
    editParticipantsText,
    tenantText,
    addParticipantSuccessMessage,
    updateParticipantSuccessMessage,
    listingText,
    participantIDText,
    allListingsText,
    selectListingsText,
    listingsText,
    listingsHelperText,
  } = usePeakShavingTranslation()

  const {
    multiTenantDebounceFetcher,

    multiListingTitleDebounceFetcher,
    multiListingTitleHandleChange,
    multiListingTitleHandlePlaceholder,
    renderMultiListingTitleOption,
    siteLabelCleaner,
  } = useSelector()

  const handleFormSubmit = () => {
    form.validateFields().then((values) => {
      setLoading(true)
      AddParticipantsToProgram(programId, {
        ...values,
        allListings: values.allListings === 'allListings',
        listings: dataSource,
      })
        .then((resp) => {
          const responseMessage = initialValues ? updateParticipantSuccessMessage : addParticipantSuccessMessage
          CustomSuccessMessage({ key: messageKey, response: responseMessage })
          onSubmit(resp)
          form.resetFields()
        })
        .catch((err: SwtchApiError) => {
          setError(err)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  }

  const handleMultiListingsValue = (values: ListingRef[]) => {
    let currSelectedListings = values.map((v) => v.title)
    setSelectedListings(values)
    return currSelectedListings
  }

  useEffect(() => {
    if (selectedListings.length) {
      form.setFieldsValue({ listings: selectedListings })
    } else {
      form.setFieldsValue({ listings: undefined })
    }
  }, [selectedListings])

  const renderTenantOption = ({ id, combineName }: any, selectedValue: any): ReactElement => {
    const label = siteLabelCleaner(combineName)
    return (
      <Select.Option key={id} value={label} label={label}>
        <DropdownWrapper selected={label === selectedValue}>{label}</DropdownWrapper>
      </Select.Option>
    )
  }

  const editableColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string; placeholder?: string })[] = [
    {
      title: listingText,
      dataIndex: 'title',
      width: '30%',
    },
    {
      title: participantIDText,
      dataIndex: 'participantId',
      editable: true,
      placeholder: participantIDText,
    },
  ]

  useEffect(() => {
    if (selectedListings.length !== dataSource.length) {
      let updatedDataSource = [...dataSource]
      selectedListings.forEach((listing, index) => {
        updatedDataSource[index] = {
          key: `${index}`,
          title: listing.title,
          id: listing.id,
          participantId: updatedDataSource[index]?.participantId ?? listing?.participantId ?? '',
        }
      })
      setDataSource(updatedDataSource.slice(0, selectedListings.length))
    }
  }, [selectedListings])

  useEffect(() => {
    if (initialValues) {
      let listingStatus = initialValues ? initialValues?.allListings : undefined
      setAllListingStatus(listingStatus)
      form.setFieldsValue({
        tenant: initialValues?.tenant,
        allListings: listingStatus,
        participantId: listingStatus === 'allListings' ? initialValues?.listings?.[0]?.participantId : undefined,
        listings: initialValues?.listings,
      })
      setSelectedListings(initialValues ? initialValues?.listings : [])
    }
  }, [initialValues])

  return (
    <AddParticipantModalContainer>
      <Modal
        title={<h5 className="heading-05-regular">{initialValues ? editParticipantsText : addParticipantsText}</h5>}
        visible={visible}
        confirmLoading={loading}
        footer={
          <Space>
            <ButtonModal type="ghost" icon={<></>} click={onCancel} text={cancelText} />
            <ButtonModal type="primary" icon={<></>} click={handleFormSubmit} text={submitText} />
          </Space>
        }
        onCancel={onCancel}
        width={540}
        className={`createNewProgramModal ${error ? 'ant-modal-error' : ''}`}
        closeIcon={closeSVG}
      >
        <AlertError error={error} />
        <Form {...layout} form={form} style={{ marginTop: '2rem' }} initialValues={initialValues} layout="vertical">
          <Form.Item
            label={<p className="paragraph-02-regular">{tenantText}</p>}
            name="tenant"
            rules={[{ required: true, message: isRquiredText.replace('-1', tenantText) }]}
            {...formItemLayout}
          >
            <ReusableSelector
              showSearch
              isSingle
              defaultValues={initialValues ? [siteLabelCleaner(initialValues.tenant?.combineName)] : undefined}
              showMagnifySVG={false}
              dropDownList={[]}
              maxTagCount={0}
              isDebounceFetcher={true}
              placeholder={tenantText}
              handleOptionChange={(value, setDirty, setSelectedOptions, onOptionsChange, options) => {
                const selectedTenant = options.find((option) => {
                  const optionLabel = siteLabelCleaner(option?.combineName)
                  return optionLabel === value
                })
                form.setFieldsValue({ tenant: selectedTenant ? selectedTenant : undefined })
              }}
              debounceFetcher={multiTenantDebounceFetcher}
              renderOption={renderTenantOption}
              isMockUpEnabled={isMockUpEnabled}
            />
          </Form.Item>
          <Form.Item
            label={<p className="paragraph-02-regular">{allListingsText}</p>}
            name="allListings"
            help={listingsHelperText}
            rules={[{ required: true, message: isRquiredText.replace('-1', allListingsText) }]}
            {...formItemLayout}
          >
            <Select
              options={[
                { value: 'allListings', label: allListingsText },
                { value: 'selectListings', label: selectListingsText },
              ]}
              onSelect={setAllListingStatus}
              suffixIcon={<PeakShavingSelectArrowDown />}
            />
          </Form.Item>
          {allListingStatus === 'allListings' && (
            <Form.Item
              label={<p className="paragraph-02-regular">{participantIDText}</p>}
              name="participantId"
              {...formItemLayout}
            >
              <Input />
            </Form.Item>
          )}
          {allListingStatus === 'selectListings' && (
            <>
              <Form.Item
                label={<p className="paragraph-02-regular">{listingsText}</p>}
                name="listings"
                rules={[{ required: true, message: isRquiredText.replace('-1', listingsText) }]}
                {...formItemLayout}
              >
                <ReusableSelector
                  showSearch
                  placeholder={listingsText}
                  showMagnifySVG={false}
                  maxTagCount={0}
                  isDebounceFetcher={true}
                  defaultValues={form.getFieldValue('listings')}
                  handlePlaceholder={multiListingTitleHandlePlaceholder}
                  handleOptionChange={multiListingTitleHandleChange}
                  debounceFetcher={multiListingTitleDebounceFetcher}
                  handleValue={handleMultiListingsValue}
                  renderOption={renderMultiListingTitleOption}
                  isMockUpEnabled={isMockUpEnabled}
                />
              </Form.Item>
              {!!dataSource.length && (
                <EditableTable
                  form={form}
                  columns={editableColumns}
                  dataSource={dataSource}
                  setDataSource={setDataSource}
                />
              )}
            </>
          )}
        </Form>
      </Modal>
    </AddParticipantModalContainer>
  )
}
