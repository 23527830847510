import { Row, Space } from 'antd'
import { filledCircleErrorSVG } from 'assets/svg/error'

interface props {
  text: React.ReactNode
}

export const ErrorTitleSectionModal: React.FC<props> = ({ text }) => (
  <Row align="middle" gutter={12}>
    <Space>
      <div>{filledCircleErrorSVG}</div>
      <div style={{ fontSize: '18px', fontWeight: 700 }}>{text}</div>
    </Space>
  </Row>
)
