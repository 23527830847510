import { Alert, Button, Col, Row, Spin } from 'antd'
import pluralize from 'pluralize'
import { useEffect, useState } from 'react'

import { useFormatMessage } from '../../helpers/intl'
import { ChargerRef } from '../../models/charger'
import { RemotePayloadResponse } from '../../models/charger-remote'
import { SwtchError } from '../../models/error'
import { RemoteClearCache } from '../../services/data-provider/charger-remote'
import { useAppState } from 'state'

interface props {
  chargers: ChargerRef[]
}

export const RemoteClearCacheController: React.FC<props> = ({ chargers }) => {
  const filterChargers = chargers.filter((v, i) => {
    return chargers.map((val) => val.id).indexOf(v.id) === i
  })

  const { isMockUpEnabled } = useAppState()
  const [loading, setLoading] = useState(false)
  const [passed, setPassed] = useState(0)
  const [failed, setFailed] = useState(0)

  const [message, setMessage] = useState<RemotePayloadResponse[]>([])

  const [error, setError] = useState<SwtchError[]>([])
  const [errorMessage, setErrorMessasge] = useState<RemotePayloadResponse[]>([])

  const clearCachetitle = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.clearCache.title',
    "Click below to clear the charger's cache",
  )

  const clearCacheBtntext = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.clearCache.btn.heading',
    'Clear 1234 Cache',
  )

  const sendClearCacheRequest = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.clearCache.request',
    'Sending clear cache request to',
  )

  const clearCacheText = useFormatMessage('dashboard.chargerPage.remoteControlTab.clearCache.heading', 'Clear Cache')

  useEffect(() => {
    setLoading(false)
    setMessage([])
    setError([])
  }, [])

  const handleRemoteClearCache = () => {
    setLoading(true)

    setError([])
    setMessage([])
    setPassed(0)
    setFailed(0)
    RemoteClearCache(
      isMockUpEnabled,
      filterChargers.map((f) => f.id),
    )
      .then((resp) => {
        setPassed(resp.passed)
        setFailed(resp.failed)
        resp.payload.map((r: RemotePayloadResponse) => {
          return r.success ? setMessage((message) => [...message, r]) : setErrorMessasge((error) => [...error, r])
        })
      })
      .catch((err: SwtchError) => setError((error) => [...error, err]))
      .finally(() => setLoading(false))
  }

  const renderMessages = () => {
    return (
      <>
        {passed >= 1 &&
          message.map((res, index) => (
            <Alert key={index} message={res.serialNumber} description={res.message} type="info" />
          ))}
        {failed >= 1 &&
          errorMessage.map((err, index) => (
            <Alert key={index} message={err.serialNumber} description={err.message} type="error" />
          ))}
        {failed >= 1 &&
          error.map((err, index) => <Alert key={index} message={err.name} description={err.message} type="error" />)}
      </>
    )
  }

  return (
    <Row>
      <Col span={24}>
        <p>{clearCachetitle}</p>
        <Button onClick={handleRemoteClearCache} loading={loading}>
          {filterChargers.length === 1 && clearCacheBtntext.replace('1234', filterChargers[0].chargePointSerialNumber)}
          {filterChargers.length > 1 && clearCacheText}
        </Button>
      </Col>
      <Col span={24} style={{ paddingTop: '10px' }}>
        {loading && (
          <Alert
            message={
              <div>
                <Spin style={{ marginRight: '10px' }} />
                {`${sendClearCacheRequest} ${filterChargers.length > 1 ? filterChargers.length : ''} ${pluralize(
                  `charger${
                    filterChargers.length === 1 ? filterChargers.map((c) => ` ${c.chargePointSerialNumber}`) : ''
                  }`,
                  filterChargers.length || 1,
                )}!`}
              </div>
            }
          />
        )}
        {!loading && renderMessages()}
      </Col>
    </Row>
  )
}
