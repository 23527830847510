import { Button, Modal } from 'antd'
import { useAccessPlanTranslation } from 'hooks/translation/useAccessPlanTranslation'
import { ConflictingPlanCustomFooterLayout } from 'atom/chargers'
import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { renderFormatMessage } from 'helpers/intl'
import { ConflictDetails } from 'models/access-plan'
import { describeSchedule } from 'helpers/date-format'
import { theme } from 'theme'
import { ErrorTitleSectionModal } from './error-title-section'

interface props {
  visible: boolean
  conflictingData?: ConflictDetails
  onCancel: () => void
  setVisible: (visible: boolean) => void
  updateAndSave: (setVisible: (visible: boolean) => void) => void
}

export const ConflictingAccessPlanModal: React.FC<props> = ({
  visible,
  conflictingData,
  onCancel,
  setVisible,
  updateAndSave,
}) => {
  const { accessPlanNameText, conflictingAccessPlanText, moveChargerText } = useAccessPlanTranslation()
  const { cancel } = useListingBasicTrans()

  return (
    <Modal visible={visible} onCancel={onCancel} width={450} footer={null} closable={false} centered={true}>
      <ErrorTitleSectionModal text={conflictingAccessPlanText} />
      <br />
      <div className="paragraph-01-regular">
        {renderFormatMessage(
          'dashboard.accessPlan.chargerInUse',
          'Charger {id} is already in another access plan with a conflicting schedule',
          { id: conflictingData?.listings.map((listing) => listing).join(', ') },
        )}
        :
      </div>
      <div
        style={{
          background: theme.colors.cultered,
          padding: '12px',
          marginTop: '8px',
          marginBottom: '16px',
          borderRadius: '8px',
        }}
      >
        <p style={{ marginBottom: '16px' }} className="paragraph-03-regular">
          {accessPlanNameText}: {conflictingData?.name}
        </p>
        <div className="paragraph-03-regular">
          {conflictingData && describeSchedule(conflictingData.existing_schedule)}
        </div>
      </div>
      <div className="paragraph-01-regular">
        {renderFormatMessage(
          'dashboard.accessPlan.moveChargerSched',
          'Move {id} from {name}, or go back and adjust the schedule.',
          { id: conflictingData?.listings.map((listing) => listing).join(', '), name: conflictingData?.name },
        )}
      </div>
      <ConflictingPlanCustomFooterLayout>
        <Button type="ghost" className="half-width-button" onClick={onCancel}>
          {cancel}
        </Button>
        <Button
          className="ant-btn-default ant-btn-dangerous half-width-button"
          type="primary"
          onClick={() => updateAndSave(setVisible)}
          style={{ marginLeft: '12px' }}
        >
          {moveChargerText}
        </Button>
      </ConflictingPlanCustomFooterLayout>
    </Modal>
  )
}
