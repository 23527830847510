import moment from 'moment'

export const generatedMoment = moment()

export const modifiedMoment = (hour: number, minute: number, second: number, operator: string) => {
  if (operator === 'add') {
    return generatedMoment.add({ hours: hour, minutes: minute, seconds: second })
  } else if (operator === 'subtract') {
    return generatedMoment.subtract({ hours: hour, minutes: minute, seconds: second })
  } else {
    return generatedMoment
  }
}
