import { TrxSummary } from 'models/transaction'

export const operationRevenuesForDCFC = [
  801600,
  889200,
  836800,
  979200,
  1049600,
  1113600,
  1089600,
  939200,
  891200,
  822400,
  1057600,
  1102400,
]

export const operationTxnCountsForDCFC = [501, 487, 523, 612, 656, 696, 681, 587, 557, 514, 661, 689]
export const operationEnergiesForDCFC = [
  20040000,
  19480000,
  20920000,
  24480000,
  26240000,
  27840000,
  27240000,
  23480000,
  22280000,
  20560000,
  26440000,
  27560000,
]

export const chargerUtilizationInUseAllWeekForDCFC = [
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  2,
  1,
  1,
  2,
  3,
  4,
  4,
  3,
  3,
  4,
  4,
  3,
  3,
  2,
  1,
  0,
  0,
]
export const chargerUtilizationInUseWeekdayForDCFC = [
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  2,
  1,
  1,
  2,
  3,
  4,
  4,
  4,
  3,
  3,
  4,
  4,
  3,
  2,
  1,
  0,
  0,
]
export const chargerUtilizationInUseWeekendForDCFC = [
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  1,
  1,
  2,
  3,
  4,
  3,
  2,
  3,
  4,
  3,
  2,
  2,
  2,
  1,
  0,
  0,
]

export const consumptionWeekForDCFC = [
  0,
  0,
  0,
  0,
  0,
  0,
  4000,
  11000,
  29000,
  43000,
  50000,
  43000,
  32000,
  43000,
  47000,
  47000,
  58000,
  58000,
  40000,
  36000,
  36000,
  32000,
  0,
  0,
]

export const consumptionWeekdayForDCFC = [
  0,
  0,
  0,
  0,
  0,
  0,
  4000,
  11000,
  29000,
  43000,
  50000,
  43000,
  32000,
  43000,
  47000,
  47000,
  58000,
  58000,
  40000,
  36000,
  36000,
  32000,
  0,
  0,
]

export const consumptionWeekendForDCFC = [
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  14000,
  29000,
  29000,
  29000,
  14000,
  0,
  22000,
  22000,
  22000,
  43000,
  43000,
  43000,
  14000,
  14000,
  7000,
  0,
  0,
]

export const summaryForDCFC = {
  txnCount: 3641,
  txnAfterHr: 801.02,
  txnOnDays: 3204.08,
  revenueTotal: 1146900.15,
  revenueDemandResponse: 0,
  revenueCharging: 1146900.15,
  revenueLoitering: 0,
  energyConsumeTotal: 80102,
  energyConsumeInPeakHrs: 33650000,
  ghgSavingTotal: 220181,
  chargerAverageDayDuration: 5460,
  chargerAverageDayLevel2Duration: 0,
  chargerAverageDayDcfcDuration: 5460,
  chargerAverageDayPlugin: 5460,
  chargerAverageDayLevel2Plugin: 0,
  chargerAverageDayDcfcPlugin: 5460,
  usersTotal: 50,
  avgTxnDay: 23,
  avgEnergyConsumePerTxn: 40000,
  avgUserSpend: 1600,
  stationActivation: {
    mobile: 84,
    web: 0,
    rfid: 513,
    roaming: 0,
  },
} as TrxSummary
