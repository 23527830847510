export const mockAccessPlan = {
  data: [
    {
      id: 1,
      name: 'another access plan name 14',
      tenantId: 4,
      listingIds: [10],
      listings: [{ id: 10, title: 'hello' }],
      accessType: 'unrestricted',
      hasOperationHours: true,
      enableAccessCode: true,
      enableEmailDomains: true,
      driverEmails: ['aaa@bbb.com'],
      emailDomains: ['swtchenergy.com', 'amazon.com'],
      accessCode: 'qwerty',
      schedules: {
        '0': [
          [540, 720],
          [780, 1020],
        ],
        '1': [
          [540, 720],
          [780, 1020],
        ],
        '2': [
          [540, 720],
          [780, 1020],
        ],
        '3': [
          [540, 720],
          [780, 1020],
        ],
        '4': [
          [540, 720],
          [780, 1020],
        ],
        '5': [[780, 1020]],
        '6': [],
      },
    },
  ],
  pagination: {
    currentPage: 1,
    totalEntries: 2,
    perPage: 10,
  },
}

export const mockAccessPlanNames = ['Plan1', 'Plan2', 'Plan3', 'Plan4', 'Plan5', 'Plan6', 'Plan7', 'Plan8', 'Plan9']
