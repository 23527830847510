import { Checkbox, Col, DatePicker, Form, FormInstance, Input, Row, Select, Switch, Tabs } from 'antd'
import moment from 'moment'
import { datePickerCalendarSVG } from '../../../assets/svg/calendar'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import {
  PeakShavingEvent,
  PeakShavingEventFormType,
  PeakShavingEventSchedule,
  PeakShavingParticipants,
  PeakShavingProgram,
  PeakShavingScheduleFrequency,
} from '../../../models/peak-shaving'
import { isPeakShavingFormEnabled } from '../../../services/data-provider/peak-shaving'
import { ScheduleComponent } from './schedule-component'
import styled from 'styled-components'
import { TenantTimezone } from '../../../components/date-time'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { useSelector } from 'hooks/useSelector'
import { ReactElement } from 'react'
import { useAppState } from 'state'

interface props {
  form: FormInstance<any>
  program: PeakShavingProgram
  schedules: Omit<PeakShavingEventSchedule, 'id'>[]
  weekEndSchedules?: Omit<PeakShavingEventSchedule, 'id'>[]
  disabled: boolean
  formMode: PeakShavingEventFormType
  formListName: string
  event?: PeakShavingEvent
  scheduleFrequency: PeakShavingScheduleFrequency
  onSelect(): void
}

const ProgramDetailsContainer = styled.div`
  label > p {
    margin-bottom: 0;
  }
  .ant-picker-input > input {
    font-weight: 400;
    line-height: 19px;
  }
`

const formItemLayout = {
  labelCol: {
    md: {
      span: 6,
    },
    lg: {
      span: 4,
    },
    xl: {
      span: 7,
    },
    xxl: {
      span: 7,
    },
  },
  wrapperCol: {
    md: {
      span: 12,
      offset: 2,
    },
    lg: {
      span: 10,
    },
    xl: {
      span: 12,
    },
    xxl: {
      span: 12,
    },
  },
}

export const EventDetailsBaseForm: React.FC<props> = ({
  form,
  disabled,
  program,
  schedules,
  formMode,
  event,
  formListName,
  scheduleFrequency,
  onSelect,
  weekEndSchedules,
}) => {
  const { isMockUpEnabled } = useAppState()
  const formInitialValues: any = {
    program: program.name,
    frequency: scheduleFrequency,
    allowOptOut: event ? event.allowOptOut : false,
    date: event && formMode === 'view' ? event.date : moment().format('YYYY-MM-DD'),
    notes: event ? event.notes : '',
    source: scheduleFrequency === 'daily' ? 'auto' : 'manual',
    program_id: program.id,
    participants: event?.participant ? [event?.participant].map((val) => ({ ...val, id: val.participantId })) : [],
  }
  if (scheduleFrequency === 'daily' && weekEndSchedules) {
    formInitialValues[`${formListName}Weekday`] = schedules
    formInitialValues[`${formListName}Weekend`] = weekEndSchedules
  } else {
    formInitialValues[`${formListName}`] = schedules
  }

  const {
    programText,
    frequencyText,
    allowOptOutText,
    notestText,
    eventDateText,
    eventScheduleText,
    startTimeText,
    endTimeText,
    weekdayText,
    weekendText,
    startDateText,
    participantsText,
    isRquiredText,
  } = usePeakShavingTranslation()

  const {
    multiParticipantsDebounceFetcher,
    multiParticipantsHandleChange,
    multiParticipantsPlaceholder,
  } = useSelector()

  const renderEventScheduleHeader = () => {
    return (
      <Row style={{ marginTop: '30px', marginBottom: '20px' }}>
        <Col span={8}>
          <p className="paragraph-02-regular" style={{ fontWeight: 700, fontSize: '12px' }}>
            Zone
          </p>
        </Col>
        <Col span={6} offset={2}>
          <p className="paragraph-02-regular" style={{ fontWeight: 700, fontSize: '12px' }}>
            {startTimeText} <TenantTimezone timezone={program.timezone} />
          </p>
        </Col>
        <Col span={6} offset={2}>
          <p className="paragraph-02-regular" style={{ fontWeight: 700, fontSize: '12px' }}>
            {endTimeText} <TenantTimezone timezone={program.timezone} />
          </p>
        </Col>
      </Row>
    )
  }

  const isDatePickerDisabled = (): boolean => {
    if (formMode === 'view') return true
    if (formMode === 'create' || formMode === 'duplicate') return false
    return false
  }

  const renderParticipantsOption = (
    { id, participantRef }: PeakShavingParticipants,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    return (
      <Select.Option key={id} value={participantRef} label={participantRef}>
        <Checkbox
          onClick={handleCheckboxClick}
          checked={selectedOptions.findIndex((selectedTenant) => selectedTenant.participantRef === participantRef) > -1}
        >
          {participantRef}
        </Checkbox>
      </Select.Option>
    )
  }

  const onSelectParticipants = (selectedOptions: PeakShavingParticipants[]) => {
    form.setFieldsValue({ participants: selectedOptions.map((option) => option.id) })
    onSelect()
  }

  return (
    <Form {...formItemLayout} form={form} style={{ marginTop: '2rem' }} initialValues={{ ...formInitialValues }}>
      <Row>
        <Col span={8}>
          <ProgramDetailsContainer>
            <Form.Item label={<p className="paragraph-02-regular">{programText}</p>} name="program" className="program">
              <p className="paragraph-04-regular">{program.name}</p>
            </Form.Item>
            <Form.Item label={<p className="paragraph-02-regular">{frequencyText}</p>} name="frequency">
              <p className="paragraph-04-regular">{scheduleFrequency === 'daily' ? 'Daily' : 'One Time'}</p>
            </Form.Item>
            <Form.Item
              label={<p className="paragraph-02-regular">{allowOptOutText}</p>}
              name="allowOptOut"
              valuePropName="checked"
            >
              <Switch
                disabled={event && formMode !== 'duplicate' ? !isPeakShavingFormEnabled(event) || disabled : disabled}
                onChange={onSelect}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: isRquiredText.replace('-1', participantsText) }]}
              label={<p className="paragraph-02-regular">{participantsText}</p>}
              name="participants"
            >
              <ReusableSelector
                showSearch
                onOptionsChange={onSelectParticipants}
                customWidth="250px"
                fieldForValue="participantRef"
                defaultValues={
                  event?.participant ? [event?.participant].map((val) => ({ ...val, id: val.participantId })) : []
                }
                dropDownList={
                  event?.participant ? [event?.participant].map((val) => ({ ...val, id: val.participantId })) : []
                }
                onClear={onSelect}
                isSingle={false}
                disabled={event && formMode === 'view'}
                placeholder={participantsText}
                showMagnifySVG={false}
                maxTagCount={0}
                isDebounceFetcher={true}
                handlePlaceholder={multiParticipantsPlaceholder}
                debounceFetcher={multiParticipantsDebounceFetcher(program.id)}
                handleOptionChange={multiParticipantsHandleChange}
                renderOption={renderParticipantsOption}
                isMockUpEnabled={isMockUpEnabled}
              />
            </Form.Item>
            <Form.Item
              label={
                <p className="paragraph-02-regular">{scheduleFrequency === 'manual' ? eventDateText : startDateText}</p>
              }
              name="date"
              className="eventDate"
              rules={[{ required: true, message: 'Event Date is required!' }]}
              valuePropName="date"
            >
              <>
                <DatePicker
                  onChange={(date: any) => {
                    date && form.setFieldsValue({ date: date.format('YYYY-MM-DD') })
                    onSelect()
                  }}
                  placement="bottomLeft"
                  placeholder="Select a Date"
                  defaultValue={event && formMode === 'view' ? moment(event.date) : moment()}
                  disabled={isDatePickerDisabled() || (event && !isPeakShavingFormEnabled(event))}
                  suffixIcon={datePickerCalendarSVG}
                  disabledDate={(current) => current.isBefore(moment().subtract(1, 'day'))}
                  showToday={false}
                />
              </>
            </Form.Item>
            <Form.Item
              label={<p className="paragraph-02-regular">{notestText}</p>}
              name="notes"
              className="notes"
              rules={[{ required: false, max: 255, message: 'Notes cannot exceed 255 characters!' }]}
            >
              <Input.TextArea
                placeholder={notestText}
                disabled={event && formMode !== 'duplicate' ? !isPeakShavingFormEnabled(event) || disabled : disabled}
                onChange={onSelect}
              />
            </Form.Item>
            <Form.Item name="source" label="Source" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="program_id" hidden>
              <Input />
            </Form.Item>
          </ProgramDetailsContainer>
        </Col>
        <Col span={14} offset={2}>
          <Row>
            <p
              className="paragraph-02-regular"
              style={{ marginTop: '7px', fontWeight: 700, fontSize: '14px', lineHeight: '16.8px' }}
            >
              {eventScheduleText} <TenantTimezone timezone={program.timezone} />
            </p>
          </Row>
          {scheduleFrequency === 'daily' ? (
            <Row>
              <Tabs defaultActiveKey="weekday">
                <Tabs.TabPane tab={<span className="regular-cap">{weekdayText}</span>} key="weekday">
                  {renderEventScheduleHeader()}
                  <ScheduleComponent
                    form={form}
                    formListName={`${formListName}Weekday`}
                    disabled={disabled}
                    formMode={formMode}
                    onSelect={onSelect}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span className="regular-cap">{weekendText}</span>} key="weekend">
                  {renderEventScheduleHeader()}
                  <ScheduleComponent
                    form={form}
                    formListName={`${formListName}Weekend`}
                    disabled={disabled}
                    formMode={formMode}
                    onSelect={onSelect}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Row>
          ) : (
            <>
              {renderEventScheduleHeader()}
              <ScheduleComponent
                form={form}
                formListName={formListName}
                disabled={disabled}
                formMode={formMode}
                onSelect={onSelect}
              />
            </>
          )}
        </Col>
      </Row>
    </Form>
  )
}
