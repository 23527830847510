import moment from 'moment'
import { FormattedDate } from 'react-intl'

import { Transaction } from '../models/transaction'
import { DateTimeV2 } from '../components/date-time'
import NoDataBadge from '../atom/Badge'
import { AccessPlanSchedule } from 'models/access-plan'
import { renderFormatMessage } from './intl'

export const formatDate = (date: string) => <FormattedDate value={date} year="numeric" month="short" day="2-digit" />

export const formatMomentDate = (date: string | moment.Moment | undefined, format: string) =>
  moment(date).format(format)

export const renderChargingStop = (transaction: Transaction) => {
  const { chargeStartAt, chargeEndAt, timezone, duration, currentState } = transaction
  if (!chargeEndAt && (currentState === 'errored' || currentState === 'canceled')) {
    return '-'
  }

  if (chargeEndAt && chargeStartAt) {
    return <DateTimeV2 date={moment(chargeEndAt)} timezone={timezone} isSidePanel />
  } else if (chargeEndAt && !chargeStartAt) {
    return <DateTimeV2 date={moment(chargeEndAt)} timezone={timezone} isSidePanel />
  } else if (!chargeEndAt && chargeStartAt && duration) {
    return <DateTimeV2 date={moment(chargeStartAt).add(duration, 'seconds')} timezone={timezone} isSidePanel />
  } else {
    return <NoDataBadge noBackground />
  }
}

export const convertMinutesToTime = (minutes: number): string => {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  const period = hours >= 12 ? 'pm' : 'am'
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12
  const formattedMinutes = mins.toString().padStart(2, '0')
  return `${formattedHours}:${formattedMinutes}${period}`
}

// export type Schedule = Record<number, [number, number][]>
export const describeSchedule = (schedule: AccessPlanSchedule) => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  const weekdays = [0, 1, 2, 3, 4]
  const weekend = [5, 6]

  const isSameSchedule = (daysArray: number[]): boolean =>
    daysArray.every(
      (day) =>
        JSON.stringify(schedule[day as keyof AccessPlanSchedule]) ===
        JSON.stringify(schedule[daysArray[0] as keyof AccessPlanSchedule]),
    )

  // Check if it's 24/7
  if (Object.values(schedule).every((ranges) => ranges.length === 0)) {
    return <div>24/7</div>
  }

  // Check if the schedule is the same every day
  if (isSameSchedule([0, 1, 2, 3, 4, 5, 6])) {
    const [start, end] = schedule[0][0]
    return (
      <div>
        Everyday {convertMinutesToTime(start)} - {convertMinutesToTime(end)}
      </div>
    )
  }

  // Check if the schedule is the same for weekdays and weekends
  if (isSameSchedule(weekdays) && isSameSchedule(weekend)) {
    const weekdayRanges = schedule[0] // Get the ranges for weekdays
    const weekendRanges = schedule[5] // Get the ranges for weekends

    return (
      <div>
        {weekdayRanges.length > 0 && (
          <>
            {renderFormatMessage('dashboard.text.weekday', 'Weekday')}{' '}
            {weekdayRanges
              .map((range) => `${convertMinutesToTime(range[0])} - ${convertMinutesToTime(range[1])}`)
              .join(', ')}
          </>
        )}
        <div />
        {weekendRanges.length > 0 && (
          <>
            {renderFormatMessage('dashboard.text.weekend', 'Weekend')}{' '}
            {weekendRanges
              .map((range) => `${convertMinutesToTime(range[0])} - ${convertMinutesToTime(range[1])}`)
              .join(', ')}
          </>
        )}
      </div>
    )
  }

  // If no pattern, return detailed schedule per day (day-specific schedules)
  return (
    <div>
      {Object.entries(schedule).map(([day, ranges]) => (
        <div key={day}>
          {ranges.length !== 0 && (
            <div>
              {days[+day]}{' '}
              {ranges
                .map(
                  (range: [number, number]) => `${convertMinutesToTime(range[0])} - ${convertMinutesToTime(range[1])}`,
                )
                .join(', ')}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export const getScheduleDescription = (schedule: AccessPlanSchedule): string => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  // Check if the object is empty (no keys)
  if (Object.keys(schedule).length === 0) {
    return '24/7'
  }

  // Extract values for weekdays (0-4) and weekend (5-6)
  const weekdays = [0, 1, 2, 3, 4].map((key) => JSON.stringify(schedule[key as keyof AccessPlanSchedule]))
  const weekend = [5, 6].map((key) => JSON.stringify(schedule[key as keyof AccessPlanSchedule]))

  // Check if all weekdays have the same value and all weekend days have the same value
  const allWeekdaysSame = weekdays.every((val) => val === weekdays[0])
  const allWeekendSame = weekend.every((val) => val === weekend[0])
  if (allWeekdaysSame && allWeekendSame) {
    return 'weekdayWeekend'
  }

  // If not, return specific day descriptions
  const descriptions: string[] = []

  for (let i = 0; i < 7; i++) {
    if (schedule[i as keyof AccessPlanSchedule].length > 0) descriptions.push(`${days[i]}`)
  }

  return descriptions.length > 0 ? 'customDays' : '24/7'
  // return descriptions.length > 0 ? descriptions.join(', ') : '24/7'
}
