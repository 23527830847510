import moment from 'moment'
import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import { mockTransactionRespv2 } from '../../mock/transaction-mock'
import { ChargerRef } from '../../models/charger'
import { ChargerInfo } from '../../models/chargerv2'
import { Connector } from '../../models/connector'
import { TransactionResponsev2, TransactionSummary } from '../../models/http'
import { ListingRef } from '../../models/listing'
import { TenantRef } from '../../models/tenant'
import { TransactionStartMethod, TransactionState } from '../../models/transaction'
import { apiClient2 } from './client'
import { returnMock } from './mock'
import { mockChargerInfo, allMockTransactionSummary, sumTransactionSummary } from 'mock/insight-mock'

export interface TransactionFilter {
  page?: number
  perPage: number
  defaultTenant?: TenantRef
  tenants?: TenantRef[]
  charger?: ChargerRef
  connector?: Connector
  startMethod?: TransactionStartMethod
  state?: TransactionState
  transactionId?: string
  listing?: ListingRef
  userName?: string
  userEmail?: string
  ocppTransactionId?: string
  country?: string
  province?: string
  masterAccountId?: number
}

export const calculateTransactionInterval = (startDate: moment.Moment, endDate: moment.Moment): string => {
  const diff = endDate.diff(startDate, 'days')
  if (diff <= 3) {
    return 'HOUR'
  } else if (diff > 3 && diff <= 60) {
    return 'DAY'
  } else if (diff > 60 && diff <= 120) {
    return 'WEEK'
  } else if (diff > 120) {
    return 'MONTH'
  }
  return 'DAY'
}

const filterToQueryParams = (
  f: TransactionFilter,
  startDate: moment.Moment,
  endDate: moment.Moment,
): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {
    start_date: moment(startDate).format('YYYY-MM-DD'),
    end_date: moment(endDate).format('YYYY-MM-DD'),
  }

  queryParams['transaction_interval'] = calculateTransactionInterval(startDate, endDate)

  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.perPage) {
    queryParams['per_page'] = `${f.perPage}`
  }

  if (f.defaultTenant) {
    queryParams['tenants_id'] = `${f.defaultTenant.id}`
  }

  if (f.tenants && f.tenants.length > 0) {
    queryParams['tenants_id'] = `${[...f.tenants.map((t) => t.id)].join('%')}`
  }
  if (f.charger) {
    queryParams['charger_id'] = `${f.charger.id}`
  }
  if (f.connector) {
    queryParams['connector_id'] = `${f.connector.id}`
  }
  if (f.startMethod) {
    queryParams['start_method'] = `${f.startMethod}`
  }
  if (f.state) {
    queryParams['state'] = `${f.state}`
  }
  if (f.transactionId) {
    queryParams['transaction_id'] = `${f.transactionId}`
  }
  if (f.listing) {
    queryParams['listing_id'] = `${f.listing.id}`
  }
  if (f.userName) {
    queryParams['user_name'] = `${f.userName}`
  }
  if (f.userEmail) {
    queryParams['user_email'] = `${f.userEmail}`
  }
  if (f.ocppTransactionId) {
    queryParams['ocpp_transaction_id'] = `${f.ocppTransactionId}`
  }
  if (f.country) {
    queryParams['country'] = `${f.country}`
  }
  if (f.province) {
    queryParams['province'] = `${f.province}`
  }

  if (f.masterAccountId) {
    queryParams['master_account_ids'] = `${f.masterAccountId}`
  }

  return queryParams
}

export async function FetchTransactions(
  props: TransactionFilter,
  startDate: moment.Moment,
  endDate: moment.Moment,
  controller: AbortController,
  isMockUpEnabled: boolean,
): Promise<TransactionResponsev2> {
  let url = '/v1/transactions'

  const { signal } = controller

  const queryParams = filterToQueryParams(props, startDate, endDate)
  url = addQueryString(url, queryParams)
  log('fetching transactions new api', { url })
  if (
    isMockUpEnabled &&
    startDate.isSame(moment().subtract(1, 'years').startOf('year')) &&
    endDate.isSame(moment().subtract(1, 'years').endOf('year'))
  ) {
    return returnMock(mockTransactionRespv2(props))
  }
  return apiClient2(url, { signal: signal })
}

export async function FetchTransactionsSummary(
  props: TransactionFilter,
  startDate: moment.Moment,
  endDate: moment.Moment,
  controller: AbortController,
  isMockUpEnabled: boolean,
): Promise<TransactionSummary> {
  let url = '/v1/dashboard/transaction-summary'

  const { signal } = controller

  const queryParams = filterToQueryParams(props, startDate, endDate)
  url = addQueryString(url, queryParams)
  log('fetching transactions', { url })
  if (
    isMockUpEnabled &&
    startDate.isSame(moment().subtract(1, 'years').startOf('year')) &&
    endDate.isSame(moment().subtract(1, 'years').endOf('year'))
  ) {
    return returnMock(sumTransactionSummary(allMockTransactionSummary, props))
  }
  return apiClient2(url, { signal })
}

export async function FetchChargerInfo(
  props: TransactionFilter,
  startDate: moment.Moment,
  endDate: moment.Moment,
): Promise<ChargerInfo> {
  let url = '/v1/dashboard/charger-info'

  const queryParams = filterToQueryParams(props, startDate, endDate)
  url = addQueryString(url, queryParams)
  log('fetching charger info', { url: url })
  //if (mockEnabled) {
  return returnMock(mockChargerInfo)
  //}
  //return apiClient(url, {})
}
