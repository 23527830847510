import { Form } from 'antd'
import { Tenant } from 'models/tenant'
import { PricingSchemaEventSchedule, PricingSchemaPayload } from 'models/price'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { DiscountNameSectionModal } from './discount-name-section'
import { DiscountChargingSectionModal } from './discount-charging-section'
import { DiscountLoiteringSectionModal } from './discount-loitering-section'
import { DiscountConstantsSectionModal } from './discount-constants-section'
import { DiscountFooterSectionModal } from './discount-footer-section'
import { DiscountPlanHeadingWrapper, DiscountPlanSectionWrapper, GrayBackground } from 'atom/discount'
import { FormInstance } from 'antd/es/form/Form'
import { DiscountFormValues } from 'models/discount'

interface props {
  tenant: Tenant
  form: FormInstance
  discountNameExists: {}
  promoCodeExists: {}
  pricingSchemaValues: PricingSchemaPayload | undefined
  initialValues: DiscountFormValues
  timeOfDay: boolean
  eventSchedules: PricingSchemaEventSchedule[]
  timeOfDayLoitering: boolean
  loiteringType: string | undefined
  modalType: string
  basePricingPlanRate: number
  basePricingPlanLoiteringRate: number
  weekdayRates: string[]
  weekendRates: string[]
  weekdayLoiteringRates: string[]
  weekendLoiteringRates: string[]
  nameCheck: (value: string, type: string, index: number) => void
  getDiscountNames: (tenantId: number) => void
  onCancel: () => void
  setTimeOfDay: (timeOfDay: boolean) => void
  setLoiteringType: (loiteringType: string) => void
  setPricePerUnitLoitering: (pricePerUnitLoitering: number) => void
  setTimeOfDayLoitering: (timeOfDayLoitering: boolean) => void
  handleSelect: (
    listName: string,
    form: FormInstance,
    index: number,
    type: string,
    pricingSchema: PricingSchemaPayload | undefined,
    modalType: string,
    key: number,
  ) => void
  convertRate: (pricingSchemaValues: PricingSchemaPayload, weekday: boolean, loitering: boolean) => string[]
  validateInputChange: (
    form: FormInstance,
    index: number,
    value: number,
    key: number,
    typeOfDay: string,
    loitering: boolean,
  ) => void
  back?: () => void
  calculateDollarRateLimit: (
    pricingSchema: PricingSchemaPayload | undefined,
    values: any,
    type: string,
    modalType: string,
    loitering?: boolean,
  ) => any
}

export const NewDiscountSchemaSectionModal: React.FC<props> = ({
  tenant,
  form,
  discountNameExists,
  promoCodeExists,
  pricingSchemaValues,
  initialValues,
  timeOfDay,
  eventSchedules,
  timeOfDayLoitering,
  loiteringType,
  basePricingPlanRate,
  basePricingPlanLoiteringRate,
  modalType,
  weekdayRates,
  weekendRates,
  weekdayLoiteringRates,
  weekendLoiteringRates,
  nameCheck,
  getDiscountNames,
  onCancel,
  setTimeOfDay,
  setLoiteringType,
  setPricePerUnitLoitering,
  setTimeOfDayLoitering,
  handleSelect,
  convertRate,
  validateInputChange,
  back,
  calculateDollarRateLimit,
}) => {
  const { discountPlanText } = useGeneralTranslation()

  return (
    <DiscountPlanSectionWrapper>
      <Form.List name="discount_schema_form" initialValue={initialValues.discount_schema_form}>
        {(fields, { add }) => {
          return (
            <>
              {fields.map(({ key, name }) => (
                <div key={key} style={{ marginTop: key === 0 ? '0px' : '30px' }}>
                  <DiscountPlanHeadingWrapper className="heading-06-regular">
                    {discountPlanText} {key + 1}
                  </DiscountPlanHeadingWrapper>
                  <GrayBackground>
                    <DiscountNameSectionModal
                      index={key}
                      tenant={tenant}
                      discountNameExists={discountNameExists}
                      promoCodeExists={promoCodeExists}
                      nameCheck={nameCheck}
                      getDiscountNames={getDiscountNames}
                    />
                    <DiscountChargingSectionModal
                      index={key}
                      form={form}
                      timeOfDay={timeOfDay}
                      eventSchedules={eventSchedules}
                      initialValues={initialValues}
                      basePricingPlanRate={basePricingPlanRate}
                      modalType={modalType}
                      weekdayRates={weekdayRates}
                      weekendRates={weekendRates}
                      setTimeOfDay={setTimeOfDay}
                      handleSelect={handleSelect}
                      pricingSchemaValues={pricingSchemaValues}
                      convertRate={convertRate}
                      validateInputChange={validateInputChange}
                      calculateDollarRateLimit={calculateDollarRateLimit}
                    />
                    <DiscountLoiteringSectionModal
                      index={key}
                      form={form}
                      loiteringType={loiteringType}
                      timeOfDayLoitering={timeOfDayLoitering}
                      eventSchedules={eventSchedules}
                      pricingSchemaValues={pricingSchemaValues}
                      basePricingPlanLoiteringRate={basePricingPlanLoiteringRate}
                      modalType={modalType}
                      weekdayLoiteringRates={weekdayLoiteringRates}
                      weekendLoiteringRates={weekendLoiteringRates}
                      setPricePerUnitLoitering={setPricePerUnitLoitering}
                      setTimeOfDayLoitering={setTimeOfDayLoitering}
                      handleSelect={handleSelect}
                      setLoiteringType={setLoiteringType}
                      convertRate={convertRate}
                      validateInputChange={validateInputChange}
                      calculateDollarRateLimit={calculateDollarRateLimit}
                    />
                    <DiscountConstantsSectionModal index={key} pricingSchemaValues={pricingSchemaValues} />
                  </GrayBackground>
                </div>
              ))}
            </>
          )
        }}
      </Form.List>
      <DiscountFooterSectionModal
        loading={false}
        form={form}
        discountNameExists={discountNameExists}
        promoCodeExists={promoCodeExists}
        current={1}
        modalType={modalType}
        onCancel={onCancel}
        back={back ? back : () => {}}
      />
    </DiscountPlanSectionWrapper>
  )
}
