import { useEffect } from 'react'
import { Form, Radio, Spin } from 'antd'

import { Tenant } from '../../../models/tenant'
import { CreatePricingModalWrapper } from '../../../atom/price'
import { usePricingSchemas } from '../../../hooks/usePricingSchemas'
import { PricingSchema, PricingSchemaPayload } from 'models/price'
import { DiscountFooterSectionModal } from './discount-schema/discount-footer-section'
import { useDiscountSchemaTranslation } from 'hooks/translation/useDiscountSchemaTranslation'
import { GetPricings } from 'services/data-provider/pricing'

interface props {
  tenant: Tenant
  current: number
  nextInModal: boolean
  pricingSchemaName: string
  onCancel: () => void
  onOk: () => void
  next: () => void
  setPricingSchemaValues: (pricingSchemaValues: PricingSchemaPayload) => void
  setNextInModal: (nextInModal: boolean) => void
  setPricingSchemaName: (pricingSchemaName: string) => void
  convertPricingSchemaToPayload: (pricingSchema: PricingSchema, type: string) => any
}

const SelectPricingSchemaModal: React.FC<props> = ({
  tenant,
  current,
  nextInModal,
  pricingSchemaName,
  onCancel,
  onOk,
  next,
  setPricingSchemaValues,
  setNextInModal,
  setPricingSchemaName,
  convertPricingSchemaToPayload,
}) => {
  const [form] = Form.useForm()
  const { initialValues, names, loading, getPricingNames } = usePricingSchemas(tenant, onOk, onCancel)
  const { selectPricingPlanText, discountPlanWorksWithPricingPlanText } = useDiscountSchemaTranslation()

  useEffect(() => {
    if (nextInModal) {
      GetPricings(1, Number(tenant.id), pricingSchemaName)
        .then((pricing) => {
          setPricingSchemaValues(convertPricingSchemaToPayload(pricing.data[0], 'discount-only'))
          const namesArr: string[] = []
          pricing.data.map((pricing) => {
            namesArr.push(pricing.name)
            return namesArr
          })
        })
        .catch((error) => console.log(error))
      next()
    }
    setNextInModal(false)
  }, [nextInModal])

  useEffect(() => {
    getPricingNames(Number(tenant.id))
  }, [])

  return (
    <CreatePricingModalWrapper>
      <Form
        colon={false}
        form={form}
        layout="horizontal"
        requiredMark={true}
        labelAlign="left"
        initialValues={initialValues}
        labelWrap={true}
      >
        <div className="heading-06-regular" style={{ marginBottom: '8px' }}>
          {selectPricingPlanText}
        </div>
        <div className="paragraph-01-regular" style={{ marginBottom: '24px' }}>
          {discountPlanWorksWithPricingPlanText}
        </div>
        <Spin spinning={loading}>
          <Radio.Group
            buttonStyle="solid"
            style={{ display: 'flex', flexDirection: 'column' }}
            onChange={(e) => setPricingSchemaName(e.target.value)}
          >
            {names?.map((name) => (
              <Radio value={name} style={{ marginBottom: '16px' }}>
                {name}
              </Radio>
            ))}
          </Radio.Group>
        </Spin>
      </Form>
      <DiscountFooterSectionModal
        loading={false}
        form={form}
        current={current}
        onCancel={onCancel}
        setNextInModal={setNextInModal}
      />
    </CreatePricingModalWrapper>
  )
}

export default SelectPricingSchemaModal
