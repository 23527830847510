import { useEffect } from 'react'
import { Form } from 'antd'
import { Tenant } from '../../../models/tenant'
import { CreatePricingModalWrapper } from '../../../atom/price'
import { InfoSectionModal } from './discount-schema/info-section'
import { NewListing } from 'models/listing'
import { NewDiscountSchemaSectionModal } from './discount-schema/new-discount-schema-section'
import { BasicListingInfo, PricingSchemaPayload } from 'models/price'
import { useDiscountSchemas } from 'hooks/useDiscountSchemas'
import { DiscountSchema } from 'models/discount'

interface props {
  tenant: Tenant
  listings: NewListing[] | BasicListingInfo[]
  pricingSchemaValues: PricingSchemaPayload | undefined
  discounts: DiscountSchema[]
  modalType: string
  onCancel: () => void
  onOk: () => void
  back?: () => void
}

const CreateDiscountSchemaModal: React.FC<props> = ({
  tenant,
  listings,
  pricingSchemaValues,
  discounts,
  modalType,
  onCancel,
  onOk,
  back,
}) => {
  const [form] = Form.useForm()
  const {
    initialValues,
    discountNameExists,
    promoCodeExists,
    loiteringType,
    timeOfDay,
    eventSchedules,
    timeOfDayLoitering,
    basePricingPlanRate,
    basePricingPlanLoiteringRate,
    weekdayRates,
    weekendRates,
    weekdayLoiteringRates,
    weekendLoiteringRates,
    setLoiteringType,
    setTimeOfDay,
    handleSelect,
    setPricePerUnitLoitering,
    setTimeOfDayLoitering,
    convertRate,
    validateInputChange,
    getDiscountNames,
    nameCheck,
    onFinish,
    calculateDollarRateLimit,
  } = useDiscountSchemas(tenant, onOk, onCancel, pricingSchemaValues, discounts, listings, form, modalType)

  useEffect(() => {
    getDiscountNames(Number(tenant.id))
  }, [])

  return (
    <CreatePricingModalWrapper>
      <Form
        colon={false}
        form={form}
        layout="horizontal"
        requiredMark={true}
        labelAlign="left"
        initialValues={initialValues}
        onFinish={(values) => onFinish(values, 'createDiscountSchema')}
        labelWrap={true}
      >
        <InfoSectionModal tenant={tenant} listings={listings} pricingSchemaValues={pricingSchemaValues} />
        <NewDiscountSchemaSectionModal
          initialValues={initialValues}
          tenant={tenant}
          form={form}
          discountNameExists={discountNameExists}
          promoCodeExists={promoCodeExists}
          pricingSchemaValues={pricingSchemaValues}
          timeOfDay={timeOfDay}
          eventSchedules={eventSchedules}
          timeOfDayLoitering={timeOfDayLoitering}
          loiteringType={loiteringType}
          basePricingPlanRate={basePricingPlanRate}
          basePricingPlanLoiteringRate={basePricingPlanLoiteringRate}
          modalType={modalType}
          weekdayRates={weekdayRates}
          weekendRates={weekendRates}
          weekdayLoiteringRates={weekdayLoiteringRates}
          weekendLoiteringRates={weekendLoiteringRates}
          nameCheck={nameCheck}
          getDiscountNames={getDiscountNames}
          onCancel={onCancel}
          setTimeOfDay={setTimeOfDay}
          setLoiteringType={setLoiteringType}
          setPricePerUnitLoitering={setPricePerUnitLoitering}
          setTimeOfDayLoitering={setTimeOfDayLoitering}
          handleSelect={handleSelect}
          convertRate={convertRate}
          validateInputChange={validateInputChange}
          back={modalType === 'discount-only' ? back : () => {}}
          calculateDollarRateLimit={calculateDollarRateLimit}
        />
      </Form>
    </CreatePricingModalWrapper>
  )
}

export default CreateDiscountSchemaModal
