import { useEffect, useRef, useState } from 'react'
import { Tenant } from 'models/tenant'
import { ListingRef } from 'models/listing'
import { GetUnassignedChargers } from 'services/data-provider/access-plan'

export const useResizeObserver = (tenant: Tenant) => {
  const [chargersNotAssigned, setChargersNotAssigned] = useState<ListingRef[]>([])
  const [chargerIdsExpanded, setChargerIdsExpanded] = useState(false)
  const [isMultiLine, setIsMultiLine] = useState(false)
  const tagsRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    getChargers()
  }, [])

  useEffect(() => {
    if (tagsRef.current) {
      const container = tagsRef.current
      const singleLineHeight = container.children[0]?.clientHeight || 0

      // Check if container has multiple lines (scrollHeight > clientHeight)
      const isMultiLine = container.scrollHeight > container.clientHeight
      setIsMultiLine(isMultiLine)

      // Set the height based on expanded state
      if (chargerIdsExpanded || !isMultiLine) {
        container.style.maxHeight = '1000px' // Expanded state
      } else {
        container.style.maxHeight = `${singleLineHeight + 10}px` // Collapsed state
      }
    }
  }, [chargersNotAssigned])

  useEffect(() => {
    if (tagsRef.current) {
      const container = tagsRef.current
      const singleLineHeight = container.children[0]?.clientHeight || 0

      // Set the height based on expanded state
      if (chargerIdsExpanded) {
        container.style.maxHeight = '1000px' // Expanded state
      } else {
        container.style.maxHeight = `${singleLineHeight + 10}px` // Collapsed state
      }
    }
  }, [chargerIdsExpanded])

  const getChargers = () => {
    GetUnassignedChargers(Number(tenant.id))
      .then((resp) => setChargersNotAssigned(resp))
      .then((err) => console.log('Error:', err))
  }

  return {
    chargersNotAssigned,
    chargerIdsExpanded,
    tagsRef,
    isMultiLine,
    setChargerIdsExpanded,
    getChargers,
  }
}
