import configProvider from '../../config'
import {
  participantsQuery,
  peakShavingEventLogQuery,
  peakShavingEventQuery,
  peakShavingEventQueryV1,
  peakShavingProgramQuery,
} from '../../helpers/filter/peakShavingEvent'
import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import {
  mockGetAllPeakShavingCurtailmentSchedule,
  mockGetAllPeakShavingEventSchedule,
  mockListAllListingsForAProgram,
  mockListingAddedToProgram,
  mockListingRemovedFromProgram,
  mockParticipantRemovedFromProgram,
  mockParticipants,
  mockPeakShavingAllProgramsResp,
  mockPeakShavingEventCancelled,
  mockPeakShavingEventDeleted,
  mockPeakShavingEventPublished,
  mockPeakShavingEventSchedule,
  mockPeakShavingEventScheduleRemoved,
  mockPeakShavingEventUnPublished,
  mockPeakShavingEvents,
  mockPeakShavingParticipantRemoved,
  mockPeakShavingParticipants,
  mockPeakShavingProgramArchived,
  mockPeakShavingProgramByProgramId,
  mockPeakShavingProgramDelete,
  mockProgramPublished,
  mockProgramUnPublished,
} from '../../mock/peak-shaving'
import {
  ParticipantsFilter,
  PeakShavingEventFilter,
  PeakShavingEventLogsFilter,
  PeakShavingParticipantsFilter,
  PeakShavingProgramFilter,
} from '../../models/filter'
import {
  PeakShavingAggregatorResponse,
  PeakShavingEventsResponse,
  PeakShavingProgramListingsResponse,
  PeakShavingProgramParticipantsResponse,
  PeakShavingProgramsResponse,
} from '../../models/http'
import {
  AddParticipantType,
  Participants,
  PeakShavingCurtailment,
  PeakShavingEvent,
  PeakShavingEventParams,
  PeakShavingEventSchedule,
  PeakShavingParticipants,
  PeakShavingProgram,
} from '../../models/peak-shaving'
import CaseConverter from './case-converter'
import { apiClient } from './client'
import { returnMock } from './mock'

export async function GetAllPeakShavingPrograms(
  isMockUpEnabled: boolean,
  filter?: PeakShavingProgramFilter,
): Promise<PeakShavingProgramsResponse> {
  let url = '/programs'

  if (filter) {
    const queryParams = peakShavingProgramQuery(filter)

    url = addQueryString(url, queryParams)
  }

  log('get all peak shaving programs', { url })
  if (isMockUpEnabled) {
    return returnMock(mockPeakShavingAllProgramsResp(filter))
  }

  return apiClient(url, {})
}

export async function GetPeakShavingProgram(programId: number): Promise<PeakShavingProgram> {
  const url = `/programs/${programId}`

  log('get peak shaving program', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingProgramByProgramId[programId])
  }

  return apiClient(url, {})
}

export async function CreatePeakShavingProgram(program: Omit<PeakShavingProgram, 'id'>): Promise<PeakShavingProgram> {
  const url = `/programs`

  log('Create New Peak Shaving Program', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingAllProgramsResp().data[0])
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      program: {
        ...CaseConverter.camelToSnakeCase(program),
      },
    }),
  })
}

export async function UpdatePeakShavingProgram(program: PeakShavingProgram): Promise<PeakShavingProgram> {
  const url = `/programs/${program.id}`

  log('update peak shaving program', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingAllProgramsResp().data[0])
  }

  const { id, state, timezone, hasShavingEvents, ...programUpdateParams } = program

  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      program: {
        ...CaseConverter.camelToSnakeCase(programUpdateParams),
      },
    }),
  })
}

export async function AddParticipant(participant: AddParticipantType): Promise<Participants> {
  let url = `/participants`

  log('add participant', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockParticipants[0])
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({ participant: { ...CaseConverter.camelToSnakeCase(participant) } }),
  })
}

export async function AddParticipantToProgramV2(programId: number, participant_id: number): Promise<any> {
  const url = `/programs/${programId}/program_participants`

  log('add participants to program', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock({})
  }
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      program_participant: {
        participant_id,
      },
    }),
  })
}

export async function AddParticipantsToProgram(
  programId: number,
  participantInfo: PeakShavingParticipants,
): Promise<PeakShavingProgram> {
  const url = `/programs/${programId}/apply_participants`

  log('add participants to program', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingAllProgramsResp().data[0])
  }
  const { tenant, allListings, listings, participantId } = participantInfo
  let body: {
    tenant_id: string
    all_listings: boolean
    participant_ref?: string
    participant?: {
      listing_ids: number[]
      participant_ref: string
    }[]
  } = {
    tenant_id: `${tenant.id}`,
    all_listings: allListings,
  }

  if (allListings) {
    body.participant_ref = participantId ?? ''
  } else {
    let participantIdToListingIDs: {
      [key: string]: number[]
    } = {}
    listings.forEach((listing) => {
      let currKey = listing.participantId ?? ''
      if (participantIdToListingIDs[currKey]) {
        participantIdToListingIDs[currKey].push(listing.id)
      } else {
        participantIdToListingIDs[currKey] = [listing.id]
      }
    })
    body.participant = Object.keys(participantIdToListingIDs).map((participantId) => ({
      listing_ids: participantIdToListingIDs[participantId],
      participant_ref: participantId,
    }))
  }
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export async function DeletePeakShavingProgram(programId: number): Promise<{ response: string }> {
  const url = `/programs/${programId}`

  log('delete peak shaving program', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingProgramDelete)
  }

  return apiClient(url, {
    method: 'DELETE',
  })
}

export async function FindParticipants(
  isMockUpEnabled: boolean,
  term: string,
  programId: number,
): Promise<PeakShavingParticipants[]> {
  let url = `/programs/${programId}/participants`

  let queryParams = {
    all_participants: true,
    term,
  }

  url = addQueryString(url, queryParams)

  log('get all peak shaving program listings', { url })
  if (isMockUpEnabled) {
    return returnMock(mockPeakShavingParticipants.data)
  }

  return apiClient(url, {})
}

export async function GetAllParticipantsForProgram(
  filter: PeakShavingParticipantsFilter,
  programId: number,
): Promise<PeakShavingProgramParticipantsResponse> {
  let url = `/programs/${programId}/participants`

  const queryParams = peakShavingEventQueryV1(filter)

  url = addQueryString(url, queryParams)

  log('get all peak shaving program listings', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingParticipants)
  }

  return apiClient(url, {})
}

export async function GetAllParticipants(filter: ParticipantsFilter): Promise<Participants[]> {
  let url = `/participants`

  const queryParams = participantsQuery(filter)
  url = addQueryString(url, queryParams)

  log('get all participants', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockParticipants)
  }

  return apiClient(url, {})
}

export async function RemoveParticipant(participantId: number): Promise<{ response: string }> {
  const url = `/participants/${participantId}`

  log('remove participant', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingParticipantRemoved)
  }

  return apiClient(url, {
    method: 'DELETE',
  })
}

export async function GetAllListingsForProgram(
  filter: PeakShavingProgramFilter,
  programId: number,
): Promise<PeakShavingProgramListingsResponse> {
  let url = `/programs/${programId}/program_listings`

  // const queryParams = peakShavingEventQuery(filter)

  // url = addQueryString(url, queryParams)

  log('get all peak shaving program listings', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockListAllListingsForAProgram)
  }

  return apiClient(url, {})
}

export async function AddListingForProgram(programId: number, listingId: number): Promise<{ response: string }> {
  const url = `/programs/${programId}/program_listings`

  log('add Listing to Program', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockListingAddedToProgram)
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      listing_id: listingId,
      document: false,
    }),
  })
}

export async function publishProgram(programId: number): Promise<{ response: string }> {
  const url = `/programs/${programId}/publish`

  log('Publishing program', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockProgramPublished)
  }

  return apiClient(url, {
    method: 'PUT',
  })
}

export async function unPublishProgram(programId: number): Promise<{ response: string }> {
  const url = `/programs/${programId}/unpublish`

  log('Un Publishing program', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockProgramUnPublished)
  }

  return apiClient(url, {
    method: 'PUT',
  })
}

export async function ArchiveProgram(programId: number): Promise<{ response: string }> {
  const url = `/programs/${programId}/archive`

  log('Archive program', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingProgramArchived)
  }

  return apiClient(url, {
    method: 'PUT',
  })
}

export async function RemoveTenantFromProgram(programId: number, tenantId: string): Promise<{ response: string }> {
  const url = `/programs/${programId}/tenants/remove`

  log('removing listing from program', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockParticipantRemovedFromProgram)
  }

  return apiClient(url, {
    method: 'DELETE',
    body: JSON.stringify({
      tenantId: tenantId,
    }),
  })
}

export async function RemoveParticipantFromProgram(
  programId: number,
  participantId: string,
): Promise<{ response: string }> {
  const url = `/programs/${programId}/participants/remove`

  log('removing listing from program', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockParticipantRemovedFromProgram)
  }

  return apiClient(url, {
    method: 'DELETE',
    body: JSON.stringify({
      participantId: participantId,
    }),
  })
}

export async function RemoveListingFromProgram(programId: number, listingId: number): Promise<{ response: string }> {
  const url = `/programs/${programId}/program_listings/remove`

  log('removing listing from program', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockListingRemovedFromProgram)
  }

  return apiClient(url, {
    method: 'DELETE',
    body: JSON.stringify({
      listing_id: listingId,
    }),
  })
}

export async function GetAllPeakShavingEvents(
  filter: PeakShavingEventFilter,
  startDate: moment.Moment,
  endDate: moment.Moment,
): Promise<PeakShavingEventsResponse> {
  let url = `/shaving_events`

  const queryParams = peakShavingEventQuery(filter, startDate, endDate)

  url = addQueryString(url, queryParams)

  log('Getting all Shaving Events', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEvents)
  }

  return apiClient(url, {})
}

export async function GetAllPeakShavingEventLogs(
  programId: number,
  filter: PeakShavingEventLogsFilter,
): Promise<PeakShavingEventsResponse> {
  let url = `/programs/${programId}/shaving_event_logs`

  const queryParams = peakShavingEventLogQuery(filter)

  url = addQueryString(url, queryParams)

  log('Getting all Shaving Events', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEvents)
  }

  return apiClient(url, {})
}

export async function GetPeakShavingEventDetails(id: number): Promise<PeakShavingEvent> {
  const url = `/shaving_events/${id}`

  log('Getting Peak Shaving Event Detail', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEvents.data[0])
  }

  return apiClient(url, {})
}

export async function CreateNewPeakShavingEvent(params: Omit<PeakShavingEventParams, 'id'>): Promise<PeakShavingEvent> {
  const url = `/shaving_events`

  const snakeCasedParams = CaseConverter.camelToSnakeCase(params)

  log('Creating new Peak Shaving Event', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEvents.data[0])
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      shaving_event: snakeCasedParams,
    }),
  })
}

export async function ShowPeakShavingEventDetails(id: number): Promise<PeakShavingEvent> {
  const url = `/shaving_events/${id}`

  log('Getting Peak Shaving Event Details', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEvents.data[0])
  }

  return apiClient(url, {})
}

export async function UpdatePeakShavingEvent(
  eventId: number,
  params: Omit<PeakShavingEvent, 'program' | 'adminPersonId' | 'systemGenerated' | 'adminPerson'>,
): Promise<PeakShavingEvent> {
  const { date, ...updateParans } = params
  const url = `/shaving_events/${eventId}`

  const snakeCasedParams = CaseConverter.camelToSnakeCase(updateParans)

  log('Updating Peak Shaving Event', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEvents.data[0])
  }

  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      date: date,
      shaving_event: snakeCasedParams,
    }),
  })
}

export async function DeletePeakShavingEvent(id: number): Promise<{ response: string }> {
  const url = `/shaving_events/${id}`

  log('Deleting Peak Shaving Event', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEventDeleted)
  }

  return apiClient(url, {
    method: 'DELETE',
  })
}

export async function PublishPeakShavingEvent(id: number): Promise<{ response: string }> {
  const url = `/shaving_events/${id}/publish`

  log('Publish Peak Shaving Event', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEventPublished)
  }

  return apiClient(url, {
    method: 'PUT',
  })
}

export async function UnPublishPeakShavingEvent(id: number): Promise<{ response: string }> {
  const url = `/shaving_events/${id}/unpublish`

  log('UnPublish Peak Shaving Event', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEventUnPublished)
  }

  return apiClient(url, {
    method: 'PUT',
  })
}

//New event schedule

export async function GetAllCurtailmentSchedulesForEvent(shavingEventId: number): Promise<PeakShavingCurtailment[]> {
  const url = `/shaving_events/${shavingEventId}/shaving_event_schedules`

  log('Get All Event Curtailment Schedules For Event', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockGetAllPeakShavingCurtailmentSchedule)
  }

  return apiClient(url, {})
}

export async function CreateCurtailmentScheduleForEvent(
  shavingEventId: number,
  params: Omit<PeakShavingCurtailment, 'shavingEventId'>,
): Promise<PeakShavingCurtailment> {
  const url = `/shaving_events/${shavingEventId}/shaving_event_schedules`

  const snakeCasedParams = CaseConverter.camelToSnakeCase(params)

  log('Creating Peak Shaving Curtailment', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockGetAllPeakShavingCurtailmentSchedule[0])
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      shaving_event_schedule: { shaving_event_id: shavingEventId, ...snakeCasedParams },
    }),
  })
}

export async function DeleteCurtailmentScheduleForEvent(
  shavingEventId: number,
  shavingEventScheduleId: number,
): Promise<{ response: string }> {
  const url = `/shaving_events/${shavingEventId}/shaving_event_schedules/${shavingEventScheduleId}`

  log('Deleting Peak Shaving Curtailment Schedule', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEventScheduleRemoved)
  }

  return apiClient(url, {
    method: 'DELETE',
  })
}

export async function UpdateCurtailmentScheduleForEvent(
  shavingEventId: number,
  shavingEventScheduleId: number,
  params: Omit<PeakShavingCurtailment, 'shavingEventId'>,
): Promise<PeakShavingCurtailment> {
  const url = `/shaving_events/${shavingEventId}/shaving_event_schedules/${shavingEventScheduleId}`

  const snakeCasedParams = CaseConverter.camelToSnakeCase(params)

  log('Update Peak Shaving Curtailment Schedule', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockGetAllPeakShavingCurtailmentSchedule[0])
  }

  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      shaving_event_schedule: { ...snakeCasedParams },
    }),
  })
}

// Need to be removed in the future
export async function GetAllEventSchedulesForEvent(shavingEventId: number): Promise<PeakShavingEventSchedule[]> {
  const url = `/shaving_events/${shavingEventId}/shaving_event_schedules`

  log('Get All Event Schedules For Event', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockGetAllPeakShavingEventSchedule)
  }

  return apiClient(url, {})
}

export async function CreateEventScheduleForEvent(
  shavingEventId: number,
  params: Omit<PeakShavingEventSchedule, 'id' | 'to'>,
): Promise<PeakShavingEventSchedule> {
  const url = `/shaving_events/${shavingEventId}/shaving_event_schedules`

  const snakeCasedParams = CaseConverter.camelToSnakeCase(params)

  log('Creating Peak Shaving Schedule', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockGetAllPeakShavingEventSchedule[0])
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      shaving_event_schedule: { shaving_event_id: shavingEventId, ...snakeCasedParams },
    }),
  })
}

export async function DeleteEventScheduleForEvent(
  shavingEventId: number,
  shavingEventScheduleId: number,
): Promise<{ response: string }> {
  const url = `/shaving_events/${shavingEventId}/shaving_event_schedules/${shavingEventScheduleId}`

  log('Deleting Peak Shaving Schedule', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEventScheduleRemoved)
  }

  return apiClient(url, {
    method: 'DELETE',
  })
}

export async function UpdateEventScheduleForEvent(
  shavingEventId: number,
  shavingEventScheduleId: number,
  params: Omit<PeakShavingEventSchedule, 'id'>,
): Promise<{ response: string }> {
  const url = `/shaving_events/${shavingEventId}/shaving_event_schedules/${shavingEventScheduleId}`

  log('Update Peak Shaving Schedule', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEventScheduleRemoved)
  }

  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      shaving_event_schedule: { ...params },
    }),
  })
}

export async function CancelShavingEvent(shavingEventId: number): Promise<{ response: string }> {
  const url = `/shaving_events/${shavingEventId}/cancel`

  log('Cancel Peak Shaving Event', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEventCancelled)
  }

  return apiClient(url, {
    method: 'PUT',
  })
}

export async function GetDailyShavingEvent(programId: number): Promise<PeakShavingEvent> {
  let url = `/programs/${programId}/daily_shaving_event`
  log('Get Daily Shaving Event', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEvents.data[0])
  }
  return apiClient(url, {})
}

export async function CreateDailyScheduleForEvent(
  shavingEventId: number,
  params: Omit<PeakShavingEventSchedule, 'id' | 'to'>,
): Promise<PeakShavingEventSchedule> {
  const url = `/shaving_events/${shavingEventId}/shaving_event_schedules`

  const snakeCasedParams = CaseConverter.camelToSnakeCase(params)

  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEventSchedule)
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      shaving_event_schedule: { shaving_event_id: shavingEventId, ...snakeCasedParams },
    }),
  })
}

export const isPeakShavingFormEnabled = (event: PeakShavingEvent): boolean => {
  return ['draft', 'published'].includes(event.state)
}

export async function FindAggregator(name?: string): Promise<PeakShavingAggregatorResponse> {
  let url = '/programs'

  let queryParams: { [key: string]: string | boolean } = {}

  if (name) {
    queryParams['aggregator_name'] = `${name}`
  }

  url = addQueryString(url, queryParams)

  // !! HORRENOUS CODE but it gets the job done.
  // !! This is a temporary solution until we have a better way to fetch the aggregator names
  log('fetching aggregator names', { url })
  if (configProvider.config.mockEnabled) {
    if (name) {
      return returnMock({
        ...mockPeakShavingAllProgramsResp,
        data: [
          mockPeakShavingAllProgramsResp().data.filter((program) => {
            return (
              program.aggregatorName !== null && program.aggregatorName.toLowerCase().indexOf(name.toLowerCase()) === 0
            )
          })[0],
        ],
        pagination: {
          currentPage: mockPeakShavingAllProgramsResp().data.length,
          totalEntries: 20,
          perPage: 10,
        },
      })
    }
    return returnMock(mockPeakShavingAllProgramsResp())
  }
  return apiClient<PeakShavingAggregatorResponse>(url, {}).then((programs) => {
    if (name) {
      return {
        data: [
          programs.data.filter((program) => {
            return (
              program.aggregatorName !== null && program.aggregatorName.toLowerCase().indexOf(name.toLowerCase()) === 0
            )
          })[0],
        ],
        pagination: {
          currentPage: programs.data.length,
          totalEntries: 20,
          perPage: 10,
        },
      }
    }
    return programs
  })
}
