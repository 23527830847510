import { Price } from '../models/price'

type PricingType =
  | 'todPricingEnabled'
  | 'kWhPricingWithFlatFee'
  | 'hourlyPricing'
  | 'hourlyPricing150'
  | 'hourlyPricingWithFlatFee'
  | 'flatFeeOnly'
  | 'freePricing'
  | 'kWhPricing'
  | 'kWh025Pricing'

// NOTE: flatFee is dollar
export const mockPrice: { [key in PricingType]: Price } = {
  todPricingEnabled: {
    // this is time of day pricing with two different price types for weekday and weekend
    preferedPricePerUnitCents: 300,
    pricePerUnitCents: 200,
    priceType: 'Hour',
    preferedPricingPriceType: 'Hour',
    flatFee: 0,
    enableTimeOfDayPricing: true,
    timeOfDayPriceWeekday: [...Array(8).fill(100), ...Array(7).fill(150), ...Array(9).fill(80), ...Array(24).fill(200)],
    timeOfDayPriceWeekend: [...Array(24).fill(200), ...Array(24).fill(300)],
  },
  kWhPricingWithFlatFee: {
    // this is kWh pricing with flat fee
    preferedPricePerUnitCents: 350,
    pricePerUnitCents: 250,
    priceType: 'kWh',
    preferedPricingPriceType: 'Hour',
    flatFee: 5,
    enableTimeOfDayPricing: true,
    timeOfDayPriceWeekday: [...Array(48).fill(100)],
    timeOfDayPriceWeekend: [...Array(48).fill(100)],
  },
  hourlyPricing: {
    // this is hourly pricing for $2/hour
    preferedPricePerUnitCents: 400,
    pricePerUnitCents: 200,
    priceType: 'Hour',
    preferedPricingPriceType: 'Hour',
    flatFee: 0,
    enableTimeOfDayPricing: false,
    timeOfDayPriceWeekday: [...Array(48).fill(100)],
    timeOfDayPriceWeekend: [...Array(48).fill(100)],
  },
  hourlyPricing150: {
    preferedPricePerUnitCents: 400,
    pricePerUnitCents: 150,
    priceType: 'Hour',
    preferedPricingPriceType: 'Hour',
    flatFee: 0,
    enableTimeOfDayPricing: false,
    timeOfDayPriceWeekday: [...Array(48).fill(100)],
    timeOfDayPriceWeekend: [...Array(48).fill(100)],
  },
  hourlyPricingWithFlatFee: {
    // this is hourly pricing with flat fee
    preferedPricePerUnitCents: 223,
    pricePerUnitCents: 223,
    priceType: 'Hour',
    preferedPricingPriceType: 'Hour',
    flatFee: 1.23,
    enableTimeOfDayPricing: true,
    timeOfDayPriceWeekday: [...Array(24).fill(223)],
    timeOfDayPriceWeekend: [...Array(24).fill(223)],
  },
  flatFeeOnly: {
    // this is flat fee pricing only
    preferedPricePerUnitCents: 0,
    pricePerUnitCents: 0,
    priceType: 'kWh',
    preferedPricingPriceType: 'Hour',
    flatFee: 5,
    enableTimeOfDayPricing: false,
    timeOfDayPriceWeekday: [...Array(48).fill(100)],
    timeOfDayPriceWeekend: [...Array(48).fill(100)],
  },
  freePricing: {
    // this is free pricing
    preferedPricePerUnitCents: 0,
    pricePerUnitCents: 0,
    priceType: 'Hour',
    preferedPricingPriceType: 'Hour',
    flatFee: '0.0',
    enableTimeOfDayPricing: false,
    timeOfDayPriceWeekday: [...Array(48).fill(100)],
    timeOfDayPriceWeekend: [...Array(48).fill(100)],
  },
  kWhPricing: {
    // this is kWh pricing
    preferedPricePerUnitCents: 300,
    pricePerUnitCents: 400,
    priceType: 'kWh',
    preferedPricingPriceType: 'kWh',
    flatFee: 0,
    enableTimeOfDayPricing: false,
    timeOfDayPriceWeekday: [...Array(48).fill(100)],
    timeOfDayPriceWeekend: [...Array(48).fill(100)],
  },
  kWh025Pricing: {
    // this is $0.25/kWh pricing
    preferedPricePerUnitCents: 300,
    pricePerUnitCents: 25,
    priceType: 'kWh',
    preferedPricingPriceType: 'kWh',
    flatFee: 0,
    enableTimeOfDayPricing: false,
    timeOfDayPriceWeekday: [...Array(48).fill(100)],
    timeOfDayPriceWeekend: [...Array(48).fill(100)],
  },
}
