export const mockVendors = [
  'ABB',
  'Alfen BV',
  'Atom Power',
  'CMI',
  'Delta Electronics',
  'EV-BOX',
  'EVVendorID',
  'Eaton',
  'EnelX',
  'H80',
  'IES',
  'Joint',
  'LGE',
  'LITE-ON',
  'OCA',
  'PHIHONG',
  'Phihong 48',
  'Phihong AX48',
  'Power Electronics',
  'SMPCT',
  'SWTCH',
  'Teltonika Energy',
  'Teltonika_Energy',
  'The New Motion BV',
  'TopStar',
  'Tritium',
  'UnitedChargers',
  'WWWW',
  'XCHARGE',
  'Zerova 120kW',
  'Zerova AX48',
  'com.cnchargepoint',
  'guanke',
  'telluspower',
]

export const mockVendorsObject = {
  deltaElectronics: 'Delta Electronics',
  liteOn: 'LITE-ON',
  alfenBV: 'Alfen BV',
  tritium: 'Tritium',
  evBox: 'EV-BOX',
  smpct: 'SMPCT',
  phihong: 'PHIHONG',
  theNewMotionBV: 'The New Motion BV',
  cmi: 'CMI',
  joint: 'Joint',
  atomPower: 'Atom Power',
  oca: 'OCA',
  abb: 'ABB',
  phihongAX48: 'Phihong AX48',
  topStar: 'TopStar',
  comCnchargepoint: 'com.cnchargepoint',
  ies: 'IES',
  evVendorID: 'EVVendorID',
  enelX: 'EnelX',
  telluspower: 'telluspower',
  unitedChargers: 'UnitedChargers',
  phihong48: 'Phihong 48',
  wwww: 'WWWW',
  teltonikaEnergy: 'Teltonika_Energy',
  xcharge: 'XCHARGE',
  eaton: 'Eaton',
  swtch: 'SWTCH',
  lge: 'LGE',
  guanke: 'guanke',
  powerElectronics: 'Power Electronics',
  zerovaAX48: 'Zerova AX48',
}

export type Vendor = typeof mockVendors[number]
