import { UserRef } from 'models/user'
import { ActivityTrackingFilter } from '../../models/filter'

export const activityTrackingQuery = (
  f: ActivityTrackingFilter,
  startDate: moment.Moment,
  endDate: moment.Moment,
): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {
    start: `${startDate.format('DD-MM-YYYY')}`,
    end: `${endDate.format('DD-MM-YYYY')}`,
  }
  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.systemAreas && f.systemAreas.length > 0) {
    queryParams['system_areas'] = `${f.systemAreas.map((t) => t)}`
  }

  if (f.listings && f.listings.length > 0) {
    queryParams['listing_ids'] = `${f.listings.map((t) => t.id)}`
  }

  if (f.term && f.term.tenantTerms && f.term.tenantTerms.length > 0 && f.term.tenantTerms[0] !== undefined) {
    queryParams['tenantTerms'] = f.term?.tenantTerms.map((t) => t).join(',')
  }

  if (f.term && f.term.listingTerms && f.term.listingTerms.length > 0 && f.term.listingTerms[0] !== undefined) {
    queryParams['listingTerms'] = f.term?.listingTerms.map((t) => t).join(',')
  }

  if (f.term && f.term.userTerms && f.term.userTerms.length > 0 && f.term.userTerms[0] !== undefined) {
    queryParams['userTerms'] = f.term?.userTerms.map((t) => t).join(',')
  }

  if (f.term && f.term.accountTerms && f.term.accountTerms.length > 0 && f.term.accountTerms[0] !== undefined) {
    queryParams['accountTerms'] = f.term?.accountTerms.map((t) => t).join(',')
  }

  if (
    f.term &&
    f.term.pricingSchemaTerms &&
    f.term.pricingSchemaTerms.length > 0 &&
    f.term.pricingSchemaTerms[0] !== undefined
  ) {
    queryParams['pricingSchemaTerms'] = f.term?.pricingSchemaTerms.map((t) => t).join(',')
  }

  if (f.users && f.users.length > 0) {
    queryParams['modified_by'] = f.users.map((user: UserRef) => user.id).join(',')
  }

  return queryParams
}
