import { useEffect, useState } from 'react'
import { GetAccessPlans } from 'services/data-provider/access-plan'
import { Tenant } from 'models/tenant'
import { AccessPlanResponse } from 'models/access-plan'
import { PaginationMeta } from 'models/pagination'

export const useAccessPlanView = (tenant: Tenant) => {
  const [openCreateAccessPlanModal, setOpenCreateAccessPlanModal] = useState(false)
  const [accessPlans, setAccessPlans] = useState<AccessPlanResponse[]>([])
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [currentPage, setCurrentPage] = useState(1)
  const [expanded, setExpanded] = useState<{ [dataKey: string]: boolean }>({})
  const [expandableRowKeys, setExpandableRowKeys] = useState<{ [dataKey: string]: boolean }>({})
  const [selectedAccessPlanDrivers, setSelectedAccessPlanDrivers] = useState<AccessPlanResponse | undefined>()
  const [selectedAccessPlan, setSelectedAccessPlan] = useState<AccessPlanResponse>()

  useEffect(() => {
    getAccessViewData()
  }, [currentPage])

  useEffect(() => {
    rowExpandable()
  }, [accessPlans])

  const getAccessViewData = () => {
    GetAccessPlans(currentPage, Number(tenant.id)).then((resp) => {
      setAccessPlans(resp.data)
      setPagination(resp.pagination)
    })
  }

  const handleRowExpand = (rowId: number) => () => {
    let updatedExpanded = { ...expanded }
    updatedExpanded[rowId] = !updatedExpanded[rowId]
    setExpanded(updatedExpanded)
  }

  const handleCreateAccessPlanModal = () => {
    setOpenCreateAccessPlanModal(true)
  }

  const onPaginationChange = (page: number, pageSize?: number) => setCurrentPage(page)

  const rowExpandable = () => {
    let updatedExpandableRowKeys = { ...expandableRowKeys }
    accessPlans?.forEach((record) => {
      if (Object.keys(expandableRowKeys).includes(`${record.id}`)) return
      const rowElement = document.querySelector(`[data-row-key="${record.id}"]`)
      const currentRowHeight = (rowElement as HTMLElement)?.offsetHeight + 8 - 16 * 2 //padding is 16 px for each cell
      const childElements = rowElement?.querySelectorAll('.custom-row-collapse-pricing-plan > td > *')

      let expandable = false

      childElements?.forEach((el: Element) => {
        expandable = expandable || el.scrollHeight > currentRowHeight
      })
      updatedExpandableRowKeys[record.id] = expandable
    })
    setExpandableRowKeys(updatedExpandableRowKeys)
  }

  const openEditModal = (accessPlan: AccessPlanResponse) => {
    setSelectedAccessPlan(accessPlan)
    setOpenCreateAccessPlanModal(true)
  }

  return {
    expandableRowKeys,
    expanded,
    accessPlans,
    pagination,
    openCreateAccessPlanModal,
    selectedAccessPlanDrivers,
    selectedAccessPlan,
    setSelectedAccessPlanDrivers,
    setOpenCreateAccessPlanModal,
    handleRowExpand,
    handleCreateAccessPlanModal,
    onPaginationChange,
    getAccessViewData,
    setSelectedAccessPlan,
    openEditModal,
  }
}
