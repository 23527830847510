import { ChargerProfile, DailyProfile, TrxnOverview } from 'models/transaction'
import moment from 'moment'

const currentYear = moment().year()

export const generateTxnOverviewTemplate = (
  revenues: number[],
  txnCounts: number[],
  energies: number[],
): { datetime: string; revenue: number; txnCount: number; energy: number }[] => {
  const txnOverview = []

  for (let i = 0; i <= 12; i++) {
    txnOverview.push({
      datetime: moment().year(currentYear).month(i).date(1).toISOString(),
      revenue: revenues[i],
      txnCount: txnCounts[i],
      energy: energies[i],
    })
  }

  return txnOverview
}

export const generateChargerUtilizationTemplate = (
  allWeek: number[],
  weekday: number[],
  weekend: number[],
): ChargerProfile[] => {
  const chargerUtilization = []

  for (let i = 0; i < 24; i++) {
    chargerUtilization.push({
      time: `${String(i).padStart(2, '0')}:00`,
      chargerInUseWeekday: weekday[i],
      chargerInUseWeekend: weekend[i],
      chargerInUseWeek: allWeek[i],
    })
  }

  return chargerUtilization
}

export const generateChargerConsumptionTemplate = (
  consumptionWeek: number[],
  consumptionWeekday: number[],
  consumptionWeekend: number[],
): DailyProfile[] => {
  const consumption = []

  for (let i = 0; i < 24; i++) {
    consumption.push({
      time: `${String(i).padStart(2, '0')}:00`,
      consumeWeekday: consumptionWeekday[i],
      consumeWeekend: consumptionWeekend[i],
      consumeWeek: consumptionWeek[i],
    })
  }

  return consumption
}

export const generateTxnOverview = (): TrxnOverview[] => {
  const currentYear = new Date().getFullYear() - 1
  const txnOverview: TrxnOverview[] = []
  for (let i = 1; i <= 12; i++) {
    txnOverview.push({
      datetime: `${currentYear}-${String(i).padStart(2, '0')}-01T00:00:00-0000`,
      revenue: 0,
      txnCount: 0,
      energy: 0,
    })
  }
  return txnOverview
}

export const generateChargerProfile = (): ChargerProfile[] => {
  const chargerProfile: ChargerProfile[] = []
  for (let i = 0; i < 24; i++) {
    chargerProfile.push({
      time: `${String(i).padStart(2, '0')}:00`,
      chargerInUseWeekday: 0,
      chargerInUseWeekend: 0,
      chargerInUseWeek: 0,
    })
  }
  return chargerProfile
}

export const generateDailyProfile = (): DailyProfile[] => {
  const dailyProfile: DailyProfile[] = []
  for (let i = 0; i < 24; i++) {
    dailyProfile.push({
      time: `${String(i).padStart(2, '0')}:00`,
      consumeWeekday: 0,
      consumeWeekend: 0,
      consumeWeek: 0,
    })
  }
  return dailyProfile
}
