import { debounce } from 'lodash'
import { Checkbox, Select } from 'antd'
import { Dispatch, ReactElement, SetStateAction, useMemo, useRef } from 'react'

import { useAppState } from 'state'
import { SearchUsers } from 'services/data-provider/users'
import { FindTenants } from 'services/data-provider/tenants'
import { FindListings, SearchListingIds } from 'services/data-provider/listing'
import { GetChargers } from 'services/data-provider/charger'
import { FindPartners } from 'services/data-provider/partners'

import { useSelectorTranslation } from './translation/useSelectorTranslation'

import { ActivityTrackingFilter, ChargersPerTenantFilter, TransactionFilter } from 'models/filter'
import { PartnerRef } from 'models/partner'
import { NewListing } from 'models/listing'
import { FindAggregator, FindParticipants } from 'services/data-provider/peak-shaving'
import { GetDiscounts } from 'services/data-provider/discount'
import { renderFormatMessage } from 'helpers/intl'
import { renderConnectionMessage } from 'helpers/status'
import { FindMasterAccount } from 'services/data-provider/master-account'
import { ChargerRef } from 'models/charger'
import { TenantRef } from 'models/tenant'
import { MasterAccountRef } from 'models/master-account'
import { log } from 'logger'
import { renderChargerSettingText } from 'helpers/tenant-tab-render-text'
import { useTenantTranslation } from './translation/useTenantTranslation'

export const useSelector = () => {
  const { selectedTenant } = useAppState()
  const fetchRef = useRef(0)
  const {
    accountNamesSelectedText,
    chargingStatusesSelectedText,
    serialNumberSelectedText,
    serviceStatusesSelectedText,
    tagsSelectedText,
    tenantSelectedText,
  } = useSelectorTranslation()
  const { chargerSettingsSelectedText } = useTenantTranslation()

  const aggregatorNameDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 1) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        FindAggregator(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues((prev: any) => [...prev, ...newOptions.data])
          setValueOptions((prev: any) => [...prev, ...newOptions.data])
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, 800)
  }, [FindTenants, 800])

  const multiPartnerDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      isMockUpEnabled: boolean,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 1) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        FindPartners(isMockUpEnabled, value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          //To match response from find partners with get tenant
          const modifiedOptions = newOptions.data.map((partner) => ({
            id: partner.partnerId,
            name: partner.partnerName,
          }))
          setValues((prev: any) => [...prev, ...modifiedOptions])
          setValueOptions((prev: any) => [...prev, ...modifiedOptions])
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, 800)
  }, [FindTenants, 800])

  const multiParticipantsDebounceFetcher = (programId: number) => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      isMockUpEnabled: boolean,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 0 && programId !== undefined) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        FindParticipants(isMockUpEnabled, value, programId).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          const filteredOptions = newOptions
          setValues(filteredOptions)
          setValueOptions((prev: any) => [...prev, ...filteredOptions])
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, 800)
  }

  const multiDiscountDebounceFetcher = (tenantId: number) => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      isMockUpEnabled: boolean,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 0 && tenantId) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        GetDiscounts(isMockUpEnabled, tenantId).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          const filteredOptions = newOptions.filter((val) => val.name.toLowerCase().includes(value))
          setValues(filteredOptions)
          setValueOptions((prev: any) => [...prev, ...filteredOptions])
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, 800)
  }

  const multiTenantDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      isMockUpEnabled: boolean,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
      defaultTenants?: any[],
    ) => {
      if (value.length >= 1) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        FindTenants(false, isMockUpEnabled, value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues(newOptions)
          setValueOptions((prev: any) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, 800)
  }, [FindTenants, 800])

  const multiMasterAccountDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 2) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        FindMasterAccount(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues(newOptions)
          setValueOptions((prev: any) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, 800)
  }, [FindMasterAccount, 800])

  const multiListingTitleDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      isMockUpEnabled: boolean,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 2) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        FindListings(isMockUpEnabled, value, selectedTenant).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues(newOptions)
          setValueOptions((prev: any) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, 800)
  }, [FindListings, 800])

  const multiListingIdDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      isMockUpEnabled: boolean,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 1) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        SearchListingIds(isMockUpEnabled, value, selectedTenant).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues(newOptions)
          setValueOptions((prev: any) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, 800)
  }, [SearchListingIds, 800])

  const multiSerialNumberSelectorDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      isMockUpEnabled: boolean,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 2) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        GetChargers(isMockUpEnabled, value, selectedTenant).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues(newOptions)
          setValueOptions((prev) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }

    return debounce(loadOptions, 800)
  }, [GetChargers, 800])

  const multiListingTitleAndIdDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      isMockUpEnabled: boolean,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
      defaultTenants?: any[],
    ) => {
      if (value.length >= 2) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        log('multiListingTitleAndIdDebounceFetcher', value, defaultTenants)

        FindListings(isMockUpEnabled, value, undefined, defaultTenants).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues(newOptions)
          setValueOptions((prev) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }

    return debounce(loadOptions, 800)
  }, [FindListings, 800])

  const multiUsersDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      isMockUpEnabled: boolean,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 2) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        SearchUsers(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues(newOptions)
          setValueOptions((prev) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }

    return debounce(loadOptions, 800)
  }, [SearchUsers, 800])

  const multiListingDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      isMockUpEnabled: boolean,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 2) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        FindListings(isMockUpEnabled, value, selectedTenant).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues((prev: any) => [...prev, ...newOptions])
          setValueOptions((prev) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }

    return debounce(loadOptions, 800)
  }, [FindListings, 800])

  const multiUsersHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectUsers: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedUsers = options.filter((t) => value.includes(t.name || t.email))
    const removedDuplicatedOfUsers = selectedUsers.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfUsers)
    onOptionsChange(selectedUsers)
  }

  const multiPartnerHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedPartner: any[]) => void,
    onOptionsChange: (selectedPartner: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedPartner = options.filter((t) => value.includes(t.id))
    const removedDuplicatedOfTenants = selectedPartner.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfTenants)
    onOptionsChange(removedDuplicatedOfTenants)
  }

  const multiTaggingHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedPartner: any[]) => void,
    onOptionsChange: (selectedPartner: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedPartner = options.filter((t) => value.includes(t.id))
    const removedDuplicatedOfTenants = selectedPartner.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfTenants)
    onOptionsChange(removedDuplicatedOfTenants)
  }

  const multiParticipantsHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedParticipants: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedParticipants = options.filter((t) => value.includes(t.participantRef))
    const removedDuplicatedOfTenants = selectedParticipants.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfTenants)
    onOptionsChange(removedDuplicatedOfTenants)
  }
  const multiDiscountHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedTenants = options.filter((t) => value.includes(t.name))
    const removedDuplicatedOfTenants = selectedTenants.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfTenants)
    onOptionsChange(removedDuplicatedOfTenants)
  }

  const multiTenantHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedTenants = options.filter((t) => value.includes(siteLabelCleaner(t.combineName)))
    const removedDuplicatedOfTenants = selectedTenants.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfTenants)
    onOptionsChange(selectedTenants)
  }

  const multiMasterAccountHandleChange = (
    value: MasterAccountRef[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: MasterAccountRef[]) => void,
    onOptionsChange: (selectedMasterAccounts: MasterAccountRef[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedMasterAccounts = options.filter((t) => value.includes(t.name))

    const removedDuplicatedOfMasterAccounts = selectedMasterAccounts.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )

    setSelectedOptions(removedDuplicatedOfMasterAccounts)
    onOptionsChange(selectedMasterAccounts)
  }

  const multiListingTitleHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedListingsArr = options.filter((listing) => value.includes(listing.title))
    const removedDuplicatedOfListings = selectedListingsArr.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfListings)
    onOptionsChange(value)
  }

  const multiListingIdHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    setSelectedOptions(value)
    onOptionsChange(value)
  }

  const multiListingHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedListings: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedListings = options.filter((t) => value.includes(t.id || t.listingTitle))
    const removedDuplicatedOfListings = selectedListings.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfListings)
    onOptionsChange(selectedListings)
  }

  const startMethodHandleChange = (
    value: any,
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setSelectedOptions(value)
    onOptionsChange(value)
  }

  const stateHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setSelectedOptions(value)
    onOptionsChange(value)
  }

  const multiSerialNumberHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    const selectedListingsArr = options.filter((option) => value.includes(option.chargePointSerialNumber))
    const removedDuplicatedOfListings = selectedListingsArr.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfListings)
    setDirty(true)
    onOptionsChange(selectedListingsArr)
  }
  const multiSerialNumberIdHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    const selectedListingsArr = options.filter((option) => value.includes(option.id))
    const removedDuplicatedOfListings = selectedListingsArr.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfListings)
    setDirty(true)
    onOptionsChange(selectedListingsArr)
  }

  const multiListingTitleAndIdHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedListingsArr = options.filter((listing) => value.includes(listing.id))
    const removedDuplicatedOfListings = selectedListingsArr.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfListings)
    onOptionsChange(selectedListingsArr)
  }

  const multiServiceStatusHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setSelectedOptions(value)
    setDirty(true)
    onOptionsChange(value)
  }

  const multiVendorsHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setSelectedOptions(value)
    setDirty(true)
    onOptionsChange(value)
  }

  const multiOCPPStatusHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setSelectedOptions(value)
    setDirty(true)
    onOptionsChange(value)
  }

  const multiChargerSettingsHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setSelectedOptions(value)
    setDirty(true)
    onOptionsChange(value)
  }

  const multiChargersHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedChargers = options.filter((t) => value.includes(t.id))
    const removedDuplicatedOfChargers = selectedChargers.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfChargers)
    onOptionsChange(selectedChargers)
  }

  const multiUsersHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].name : `${selected} Users Selected`
  }

  const multiPartnerHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].name : multiPartnerSelectedPlaceholder(selected)
  }

  const multiTaggingHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].tagName : multiTaggingSelectedPlaceholder(selected)
  }

  const multiPartnerSelectedPlaceholder = (selected: number) => `${selected} Partners Selected`

  const multiTaggingSelectedPlaceholder = (selected: number) => tagsSelectedText.replace('-1', `${selected}`)

  const multiListingTitleHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].title : multiListingTitleSelectedPlaceholder(selected)
  }
  const multiListingHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].title : multiListingTitleSelectedPlaceholder(selected)
  }
  const multiListingPreloadedHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].label : multiListingTitleSelectedPlaceholder(selected)
  }
  const multiListingIdHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0] : multiListingIdSelectedPlaceholder(selected)
  }
  const multiListingIdSelectedPlaceholder = (selected: any) => `${selected} Listing Ids Selected`
  const multiListingTitleSelectedPlaceholder = (selected: number) => `${selected} Listing Titles Selected`

  const multiParticipantsPlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    const resultArray = selectedOptions.map((participant) => {
      return participant.participantRef
    })
    return selected === 1 ? resultArray : `-1 Participants Selected`.replace('-1', `${selected}`)
  }

  const multiDiscountPlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    const resultArray = selectedOptions.map((discount) => {
      return discount.name
    })
    return selected === 1 ? resultArray : `-1 Discount Plans Selected`.replace('-1', `${selected}`)
  }

  const multiTenantHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    const resultArray = selectedOptions.map((tenant) => siteLabelCleaner(tenant.combineName))

    return selected === 1 ? resultArray : tenantSelectedText.replace('-1', `${selected}`)
  }

  const multiMasterAccountHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].name : accountNamesSelectedText.replace('-1', `${selected}`)
  }

  const multiSerialNumberHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].chargePointSerialNumber : `${selected} ${serialNumberSelectedText}`
  }

  const multiServiceStatusPlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1
      ? renderFormatMessage(renderConnectionMessage(selectedOptions[0]), selectedOptions[0])
      : serviceStatusesSelectedText.replace('-1', `${selected}`)
  }

  const multiVendorPlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0] : `${selectedOptions.length} Vendors Selected`
  }

  const multiOCPPStatusPlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1
      ? renderFormatMessage(renderConnectionMessage(selectedOptions[0]), selectedOptions[0])
      : chargingStatusesSelectedText.replace('-1', `${selected}`)
  }

  const multiChargerSettingsHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1
      ? renderChargerSettingText(selectedOptions[0])
      : `${selectedOptions.length} ${chargerSettingsSelectedText}`
  }

  const multiChargersPlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].chargePointSerialNumber : `${selectedOptions.length} Chargers Selected`
  }

  const multiUsersHandleValue = (filter: ActivityTrackingFilter | undefined) => {
    return filter?.users?.map((t: any) => t.name)
  }

  const multiPartnerHandleValue = (selectedValues: PartnerRef[]) => {
    return selectedValues.map((value: PartnerRef) => value.id)
  }
  const multiTenantHandleValue = (selectedValues: TenantRef[]) => {
    return selectedValues.map((t) => siteLabelCleaner(t.combineName))
  }
  const multiMasterAccountHandleValue = (filter: any | undefined) => {
    return filter?.masterAccounts?.map((t: any) => t.name)
  }

  const multiListingTitleHandleValue = (filter: TransactionFilter | undefined) => {
    return filter?.listingTitle
  }

  const multiListingHandleValue = (filter: ActivityTrackingFilter | undefined) => {
    return filter?.listings.map((listing) => listing.id)
  }

  const startMethodHandleValue = (filter: TransactionFilter | undefined) => {
    return filter?.startMethod
  }

  const stateHandleValue = (filter: TransactionFilter | undefined) => {
    return filter?.state
  }

  const multiSerialNumberHandleValue = (filter: TransactionFilter | ChargersPerTenantFilter | undefined) => {
    return filter?.chargers
  }

  const renderMultiUsersOption = (
    option: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    return (
      <Select.Option key={option.id} value={option.name} label={option.name}>
        <Checkbox
          onClick={handleCheckboxClick}
          checked={selectedOptions.findIndex((listing) => listing.name === option.name) > -1}
          className="ant-checkbox-position"
          style={{ top: 10 }}
        >
          <div className="flex-and-gap-below">
            <span>{option.name}</span>
            <span className="paragraph-04-light opacity-06">{option.email}</span>
          </div>
        </Checkbox>
      </Select.Option>
    )
  }

  const renderMultiListingTitleOption = (
    option: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    return (
      <Select.Option key={option.id} value={option.title} label={option.title}>
        <Checkbox
          onClick={handleCheckboxClick}
          checked={selectedOptions.findIndex((listing) => listing.title === option.title) > -1}
        >
          {option.title}
        </Checkbox>
      </Select.Option>
    )
  }

  const renderMultiListingOption = (
    option: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    const final = selectedOptions.findIndex((listing) => listing.id === option.id) > -1
    return (
      <Select.Option key={option.id} value={option.id} label={option.title}>
        <Checkbox onClick={handleCheckboxClick} checked={final}>
          {option.title}
        </Checkbox>
      </Select.Option>
    )
  }

  const renderMultiVendorOption = (
    option: string,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={option} value={option} label={option}>
      <Checkbox onClick={handleCheckboxClick} checked={selectedOptions.includes(option)}>
        {option}
      </Checkbox>
    </Select.Option>
  )

  const renderChargerSerialNumberOption = (
    charger: ChargerRef,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={charger.id} value={charger.id} label={charger.chargePointSerialNumber}>
      <Checkbox
        onClick={handleCheckboxClick}
        checked={selectedOptions.findIndex((option) => option.id === charger.id) > -1}
      >
        {charger.chargePointSerialNumber}
      </Checkbox>
    </Select.Option>
  )

  const renderMultiListingIdOption = (
    option: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={option.id} value={option.id} label={option.title}>
      <Checkbox
        onClick={handleCheckboxClick}
        checked={selectedOptions.findIndex((listing) => listing.title === option.title) > -1}
      >
        {option.title}
      </Checkbox>
    </Select.Option>
  )

  const renderMultiTenantOption = (
    { id, combineName }: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    return (
      <Select.Option key={id} value={combineName} label={siteLabelCleaner(combineName)}>
        <Checkbox
          onClick={handleCheckboxClick}
          checked={selectedOptions.findIndex((selectedTenant) => selectedTenant.combineName === combineName) > -1}
        >
          {siteLabelCleaner(combineName)}
        </Checkbox>
      </Select.Option>
    )
  }

  const renderOCPPStatusOption = (
    option: string,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    const translateOpt = renderFormatMessage(renderConnectionMessage(option), option)
    return (
      <Select.Option key={option} value={option} label={option}>
        <Checkbox onClick={handleCheckboxClick} checked={selectedOptions.includes(option)}>
          {translateOpt}
        </Checkbox>
      </Select.Option>
    )
  }

  const renderMultiMasterAccountOption = (
    { id, name }: MasterAccountRef,
    selectedOptions: MasterAccountRef[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    return (
      <Select.Option key={id} value={name} label={name}>
        <Checkbox
          onClick={handleCheckboxClick}
          checked={selectedOptions.findIndex((selectedMasterAccounts) => selectedMasterAccounts.name === name) > -1}
        >
          {name}
        </Checkbox>
      </Select.Option>
    )
  }

  const renderServiceStatusOption = (
    option: string,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    const translateOpt = renderFormatMessage(renderConnectionMessage(option), option)
    return (
      <Select.Option key={option} value={option} label={option}>
        <Checkbox onClick={handleCheckboxClick} checked={selectedOptions.includes(option)}>
          {translateOpt}
        </Checkbox>
      </Select.Option>
    )
  }

  const multiListingTitleAndIdHandleValue = (selectedValues: NewListing[]) => {
    return selectedValues.map((value: NewListing) => value.id)
  }

  //Specifal case - need to change it to be reusable with format in the future
  const renderMultiProgramsOptions = (
    value: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option
      key={value.id}
      value={value.id}
      label={`${value.aggregatorName ? `${value.aggregatorName}-` : ''}${value.name}`}
      disabled={value?.disabled}
    >
      <Checkbox
        onClick={handleCheckboxClick}
        checked={selectedOptions.findIndex((option) => option.id === value.id) > -1}
      >
        {`${value.aggregatorName ? `${value.aggregatorName}-` : ''}${value.name}`}
      </Checkbox>
    </Select.Option>
  )

  //General version

  //Debound fetcher
  const generalDebounceFetcher = (
    fetchFunction: (value: string) => Promise<any>,
    condition = true,
    newDataField?: string,
    valueLength = 0,
  ) => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      isMockUpEnabled: boolean,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= valueLength && condition) {
        //Removed this as places such as event modal need to load two api calls at once
        // fetchRef.current += 1
        // const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        fetchFunction(value).then((newOptions) => {
          //Removed this as places such as event modal need to load two api calls at once
          // if (fetchId !== fetchRef.current) {
          // for fetch callback order
          //   return
          // }
          const filteredOptions = newDataField ? newOptions[newDataField] : newOptions
          setValues((prev: any) => [...prev, ...filteredOptions])
          setValueOptions((prev: any) => [...prev, ...filteredOptions])
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, 800)
  }

  //Helper
  const fieldSeparator = (value: any, field: string, secondaryField?: string, tertiaryField?: string) => {
    if (!value) {
      return null
    }
    if (tertiaryField && secondaryField) {
      return (
        <div className="flex-and-gap-below">
          <span>{value[field]}</span>
          <span className="paragraph-04-light opacity-06">{`${value[tertiaryField]} - ${value[secondaryField]}`}</span>
        </div>
      )
    }
    if (secondaryField) {
      log('fieldSeparator', value[field], value[secondaryField])
      return (
        <div className="flex-and-gap-below">
          <span>{value[field]}</span>
          <span className="paragraph-04-light opacity-06">{value[secondaryField]}</span>
        </div>
      )
    }
    // Ensure that value[field] is not undefined
    return field.includes('|')
      ? field
          .split('|')
          .map((f) => value[f])
          .find((v) => v !== undefined && v !== null)
      : value[field]
  }

  const siteLabelCleaner = (label: string = '') =>
    (label || '').includes(' (') ? label : label.replace(/^\(|\)$/g, '')

  //Multi select
  const renderMultiOptions = (fields: { val: string; label: string }, labelFilter = (label: string) => label) => (
    value: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option
      key={fieldSeparator(value, fields.val)}
      value={fieldSeparator(value, fields.val)}
      label={labelFilter(fieldSeparator(value, fields.label))}
      disabled={value?.disabled}
    >
      <Checkbox
        onClick={handleCheckboxClick}
        checked={
          selectedOptions.findIndex(
            (option) => fieldSeparator(option, fields.val) === fieldSeparator(value, fields.val),
          ) > -1
        }
      >
        {labelFilter(fieldSeparator(value, fields.label))}
      </Checkbox>
    </Select.Option>
  )

  const multiHandlePlaceholder = (labelField: string, label: string) => (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0][labelField] : `${selected} ${label} Selected`
  }

  const multiHandleChange = (valField: string) => (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedPartner: any[]) => void,
    onOptionsChange: (selectedPartner: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedOption = options.filter((t) => value.includes(fieldSeparator(t, valField)))
    const removedDuplicatedOfTenants = selectedOption.filter(
      (obj, index, self) =>
        index === self.findIndex((t) => fieldSeparator(t, valField) === fieldSeparator(obj, valField)),
    )
    setSelectedOptions(removedDuplicatedOfTenants)
    onOptionsChange(removedDuplicatedOfTenants)
  }

  //Single Select
  const renderSingleOption = (
    fields: { val: string; label: string; secondaryLabel?: string; tertiaryLabel?: string },
    withState = true,
    labelFilter = (label: string) => label,
  ) => (option: any): ReactElement => {
    const value = withState ? option.state : option
    return (
      fieldSeparator(value, fields.val) &&
      fieldSeparator(value, fields.label) && (
        <Select.Option
          key={fieldSeparator(value, fields.val)}
          value={fieldSeparator(value, fields.val)}
          label={labelFilter(fieldSeparator(value, fields.label, fields.secondaryLabel, fields.tertiaryLabel))}
          disabled={value?.disabled}
        >
          {labelFilter(fieldSeparator(value, fields.label, fields.secondaryLabel, fields.tertiaryLabel))}
        </Select.Option>
      )
    )
  }

  const handleSingleSelectChange = (valField: string) => (
    value: any,
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    const selectedValue = options?.find((p) => fieldSeparator(p, valField) === value)
    setSelectedOptions(selectedValue ? [selectedValue] : [])
    onOptionsChange(selectedValue)
  }

  return {
    multiTenantDebounceFetcher,
    multiListingTitleDebounceFetcher,
    multiSerialNumberSelectorDebounceFetcher,
    multiListingTitleAndIdDebounceFetcher,
    multiPartnerDebounceFetcher,
    multiUsersDebounceFetcher,
    multiListingDebounceFetcher,
    multiListingIdDebounceFetcher,
    multiMasterAccountDebounceFetcher,
    multiTenantHandleChange,
    multiListingHandleChange,
    multiListingTitleHandleChange,
    multiListingHandlePlaceholder,
    multiListingTitleHandlePlaceholder,
    multiTenantHandlePlaceholder,
    multiUsersHandlePlaceholder,
    multiMasterAccountHandlePlaceholder,
    multiPartnerHandleValue,
    multiTenantHandleValue,
    multiMasterAccountHandleValue,
    multiListingHandleValue,
    multiListingTitleHandleValue,
    multiUsersHandleValue,
    multiSerialNumberHandleChange,
    multiSerialNumberIdHandleChange,
    multiMasterAccountHandleChange,
    startMethodHandleValue,
    stateHandleValue,
    multiSerialNumberHandleValue,
    multiSerialNumberHandlePlaceholder,
    multiListingPreloadedHandlePlaceholder,
    startMethodHandleChange,
    stateHandleChange,
    multiListingTitleAndIdHandleChange,
    multiPartnerHandlePlaceholder,
    multiPartnerHandleChange,
    multiUsersHandleChange,
    renderMultiUsersOption,
    renderMultiListingTitleOption,
    renderMultiListingOption,
    renderMultiVendorOption,
    renderChargerSerialNumberOption,
    renderMultiListingIdOption,
    renderMultiTenantOption,
    renderOCPPStatusOption,
    renderMultiMasterAccountOption,
    renderServiceStatusOption,
    multiListingTitleAndIdHandleValue,
    aggregatorNameDebounceFetcher,
    multiDiscountDebounceFetcher,
    multiDiscountHandleChange,
    multiDiscountPlaceholder,
    multiParticipantsDebounceFetcher,
    multiParticipantsHandleChange,
    multiParticipantsPlaceholder,
    multiListingIdHandlePlaceholder,
    multiListingIdHandleChange,
    multiServiceStatusPlaceholder,
    multiServiceStatusHandleChange,
    multiVendorPlaceholder,
    multiOCPPStatusPlaceholder,
    multiVendorsHandleChange,
    multiOCPPStatusHandleChange,
    multiTaggingHandlePlaceholder,
    multiTaggingHandleChange,
    generalDebounceFetcher,
    renderMultiOptions,
    multiHandlePlaceholder,
    multiHandleChange,
    renderSingleOption,
    handleSingleSelectChange,
    renderMultiProgramsOptions,
    multiChargerSettingsHandlePlaceholder,
    multiChargerSettingsHandleChange,
    multiChargersPlaceholder,
    multiChargersHandleChange,
    //Helper
    siteLabelCleaner,
  }
}
