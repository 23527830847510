import { Form, Input } from 'antd'
import { useAccessPlanTranslation } from 'hooks/translation/useAccessPlanTranslation'
import { useAppHeaderTranslation } from 'hooks/translation/useAppHeaderTranslation'
import { usePricingSchemasTranslation } from 'hooks/translation/usePricingSchemaTranslation'
import { useSelector } from 'hooks/useSelector'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { ErrorTextWrapper } from 'atom/chargers'
import { redInfoWithISVG } from 'assets/svg/info'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { FindListings } from 'services/data-provider/listing'
import { ListingRef } from 'models/listing'
import { AccessPlanResponse } from 'models/access-plan'
import { FormInstance } from 'antd/es/form/Form'
import { TenantRef } from 'models/tenant'
import { useAppState } from 'state'
import { theme } from 'theme'

interface props {
  form: FormInstance<any>
  tenant: TenantRef
  selectedAccessPlan?: AccessPlanResponse
  nameExists: boolean
  listings: ListingRef[]
  allListings: ListingRef[]
  nameCheck: (value: string) => void
  setListings: (listings: ListingRef[]) => void
}

export const AccessNameSectionModal: React.FC<props> = ({
  form,
  tenant,
  selectedAccessPlan,
  nameExists,
  listings,
  allListings,
  nameCheck,
  setListings,
}) => {
  const { isMockUpEnabled } = useAppState()
  const { tenantTabChargerText } = useTenantTranslation()
  const { accessPlanNameText, nameToKeepTrackText } = useAccessPlanTranslation()
  const { chargersText } = useAppHeaderTranslation()
  const { nameInUseText } = usePricingSchemasTranslation()
  const {
    generalDebounceFetcher,
    multiHandlePlaceholder,
    multiHandleChange,
    renderMultiOptions,
    multiListingTitleAndIdHandleValue,
  } = useSelector()

  return (
    <>
      <div className="heading-06-regular">
        {accessPlanNameText}
        <span style={{ color: theme.colors.red }}>*</span>
      </div>
      <Form.Item name="accessPlanName" validateStatus={nameExists ? 'error' : ''}>
        <Input placeholder={nameToKeepTrackText} onChange={(e) => nameCheck(e.target.value)} />
      </Form.Item>
      {nameExists && (
        <ErrorTextWrapper>
          <div style={{ marginBottom: '8px' }}>{redInfoWithISVG}</div>
          <span className="danger-text">{` ${nameInUseText}`}</span>
        </ErrorTextWrapper>
      )}
      <div className="heading-06-regular">
        {tenantTabChargerText}
        <span style={{ color: theme.colors.red }}>*</span>
      </div>
      <Form.Item name="chargers">
        <ReusableSelector
          showSearch={true}
          onClear={() => setListings([])}
          isSingle={false}
          showMagnifySVG={true}
          maxTagCount={0}
          isDebounceFetcher={true}
          handlePlaceholder={multiHandlePlaceholder('title', chargersText)}
          debounceFetcher={generalDebounceFetcher(
            (value) => FindListings(isMockUpEnabled, value, form.getFieldValue('tenant')),
            true,
            undefined,
            2,
          )}
          isMockUpEnabled={isMockUpEnabled}
          placeholder={chargersText}
          defaultTenants={[tenant]}
          dropDownList={allListings}
          selectAllOptions={true}
          defaultValues={selectedAccessPlan ? listings : allListings}
          onOptionsChange={(listing) => setListings(listing)}
          handleOptionChange={multiHandleChange('id')}
          renderOption={renderMultiOptions({
            val: 'id',
            label: 'title',
          })}
          handleValue={multiListingTitleAndIdHandleValue}
        />
      </Form.Item>
    </>
  )
}
